import { View, StyleSheet } from "react-native";
import MealCard from "../MealCard/MealCard";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";
import { ScrollView } from "react-native-gesture-handler";
import CustomText from "../../common/general/CustomText/CustomText";

function MealCardList({ items }) {
  return (
    <View style={styles.container}>
      {items && items.length > 0 ? (
        <ScrollView style={styles.scrollView} showsVerticalScrollIndicator={true}>
          {items.map(({ id, name, calories, protein, fat, carbs }) => (
            <View key={id}>
              <MealCard name={name} calories={calories} protein={protein} fat={fat} carbs={carbs} />
              <CustomSpacing type="vertical" size="xxl" />
            </View>
          ))}
        </ScrollView>
      ) : (
        <View style={styles.placeholderContainer}>
          <CustomText text="You have not logged any meals for today" style={styles.placeholderText} />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 10,
    backgroundColor: "#F5F7FF",
    borderRadius: 16,
  },
  scrollView: {
    height: 20,
  },
  placeholderContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  placeholderText: {
    color: "#6B7280",
  },
});

export default MealCardList;
