import React from "react";
import { View, StyleSheet } from "react-native";
import CustomText from "../../common/general/CustomText/CustomText";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import { Lightning } from "../../svgIcons";
import { formatDate } from "../../../utils/date";

function PlanCard({ isPro, companionName, onManagePress, onUpgradePress, trialEndDate }) {
  return (
    <View style={styles.container}>
      {isPro ? (
        <View>
          <CustomText textType="display" text={`${companionName} Pro`} size="xs" weight="bold" />
          {trialEndDate && (
            <CustomText textType="display" text={`Free trial ends on${formatDate(trialEndDate, false)}`} size="xs" />
          )}
        </View>
      ) : (
        <CustomText textType="display" text="Free Plan" size="xs" />
      )}

      {isPro ? (
        <CustomButton
          onPress={onManagePress}
          text="Manage"
          styleType="primary"
          size="s"
          style={[styles.manageButton, styles.noShadowButton]}
        />
      ) : (
        <CustomButton
          onPress={onUpgradePress}
          text=" Upgrade"
          styleType="primary"
          leftIcon={<Lightning />}
          size="s"
          style={styles.noShadowButton}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 20,
    borderRadius: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.06,
    shadowRadius: 3.84,
    elevation: 5,
  },
  noShadowButton: {
    shadowColor: "transparent",
    elevation: 0,
  },
  manageButton: {
    backgroundColor: "#B7BAC1",
  },
});

export default PlanCard;
