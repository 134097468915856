import { Svg, Path, Circle } from "react-native-svg";

const ToggleOff = () => (
  <Svg width="40" height="20" viewBox="0 0 40 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M0 10C0 4.47715 4.47715 0 10 0H30C35.5228 0 40 4.47715 40 10C40 15.5228 35.5228 20 30 20H10C4.47715 20 0 15.5228 0 10Z"
      fill="#EAECF0"
    />
    <Circle cx="10" cy="10" r="8" fill="#98A2B3" />
  </Svg>
);

export default ToggleOff;
