import React from "react";
import { StyleSheet, View, ImageBackground, Linking } from "react-native";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import CustomText from "../../common/general/CustomText/CustomText";
import VideoPlay from "../../svgs/common/VideoPlay";

const VideoCard = ({ imageUrl, url, title }) => {
  const handlePress = () => {
    Linking.openURL(url).catch((err) => {
      console.error("Couldn't load page", err);
    });
  };

  return (
    <>
      <View style={styles.container}>
        <View style={styles.imageContainer}>
          <ImageBackground style={styles.image} source={{ uri: imageUrl }}>
            <CustomButton onPress={handlePress} leftIcon={<VideoPlay />} styleType="light" style={styles.button} />
          </ImageBackground>
        </View>
        <View style={styles.textContainer}>
          <CustomText style={styles.titleText} text={title} numberOfLines={2} ellipsizeMode="tail" />
        </View>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 4,
    overflow: "hidden",
    backgroundColor: "white",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.08,
    shadowRadius: 4,
    elevation: 5,
    maxWidth: 128,
    minWidth: 128,
    margin: 5,
  },
  imageContainer: {
    width: "100%",
    height: 72,
  },
  image: {
    height: "100%",
    width: "100%",
    resizeMode: "contain",
  },
  button: {
    backgroundColor: "#000000",
    borderRadius: 80,
    width: 20,
    height: 20,
    position: "absolute",
    right: 5,
    bottom: 5,
    justifyContent: "center",
  },
  textContainer: {
    // justifyContent: "flex-start",
    margin: 10,
  },
  titleText: {
    fontSize: 8,
    lineHeight: 10, // Adjust this value to reduce the space between lines
    textAlign: "left",
  },
});

export default VideoCard;
