import React from "react";
import { StyleSheet, View, ImageBackground, Linking } from "react-native";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import VideoPlay from "../../svgs/common/VideoPlay";

const ImageCard = ({ imageUrl, url }) => {
  const handlePress = () => {
    Linking.openURL(url).catch((err) => {
      console.error("Couldn't load page", err);
    });
  };

  return (
    <>
      <View style={styles.container}>
        <View style={styles.imageContainer}>
          <ImageBackground style={styles.image} source={{ uri: imageUrl }}>
            <CustomButton
              onPress={handlePress}
              leftIcon={<VideoPlay />}
              size="s"
              text={"View"}
              styleType="dark"
              style={styles.button}
            />
          </ImageBackground>
        </View>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 15,
    overflow: "hidden",
    backgroundColor: "white",
    minWidth: 260,
    maxWidth: 260,
  },
  imageContainer: {
    width: "100%",
    height: 173,
  },
  image: {
    height: "100%",
    width: "100%",
    resizeMode: "cover",
  },
  button: {
    backgroundColor: "#000000",
    borderRadius: 100,
    width: 66,
    height: 36,
    position: "absolute",
    right: 5,
    bottom: 5,
    justifyContent: "space-around",
  },
});

export default ImageCard;
