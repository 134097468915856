import { useState } from "react";
import { Platform } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigation } from "@react-navigation/native";
import { useRoute } from "@react-navigation/native";
import { useAuth } from "../../../hooks/useAuth";
import { useAppState, actions } from "../../../contexts/AppStateContext";
import { useDailyLimitQuery, useMeQuery, useSchoolsQuery } from "../../../graphql/generated/graphql";
import { useAppConfig } from "../../../AppConfigProvider";
import useWebSocket from "../../../hooks/useWebSocket";
import MainMenu from "../../../components/navigation/MainMenu/MainMenu";
import { createCheckoutSession } from "../../../services";
import handleOpenLink from "../../../utils/handleOpenLink";
import {
  DashboardIcon,
  LearnIcon,
  HealthIcon,
  TodosIcon,
  JournalIcon,
  EducationIcon,
  CareerIcon,
  ShareFeedbackIcon,
  MeetingIcon,
} from "../../../components/svgs/menuIcons";
import FeedbackModal from "../../../components/navigation/FeedbackModal/FeedbackModal";

function MainMenuContainer() {
  const appConfig = useAppConfig();
  const { sendMessage } = useWebSocket();
  const navigation = useNavigation();
  const route = useRoute();
  const { logout } = useAuth();
  const { state, dispatch } = useAppState();

  const { data: dailyLimitData } = useDailyLimitQuery();
  const { data: meData } = useMeQuery();
  const { data: schoolsData, loading: schoolsLoading } = useSchoolsQuery();

  const [bugReportModalVisible, setBugReportModalVisible] = useState(false);

  const domainKey = appConfig.key;
  const showMiniLogo = appConfig.showMiniLogo;
  const isFreemium = appConfig.isFreemium;
  const showIframePage = appConfig.showIframePage;
  const iFramePageName = appConfig.iFramePageName;
  const iFramePageLink = appConfig.iFramePageLink;
  const isLMS = appConfig.isLMS;

  const showLearn = appConfig.showLearn;
  const showHealth = appConfig.showHealth;
  const showConnect = appConfig.showConnect;
  const showInviteFriends = appConfig.showInviteFriends;
  const showCareer = appConfig.showCareer;
  const showFeedback = appConfig.showFeedback;

  const menuItems = [
    {
      name: "My Day",
      icon: DashboardIcon,
    },
  ];

  if (showLearn) menuItems.push({ name: "Learn", icon: LearnIcon });
  if (showHealth) menuItems.push({ name: "Health", icon: HealthIcon });
  menuItems.push({ name: "Productivity", icon: TodosIcon });
  menuItems.push({ name: "Journal", icon: JournalIcon });
  if (!schoolsLoading && schoolsData?.getSchools.length > 0)
    menuItems.push({ name: "Coursework", icon: EducationIcon });
  if (showIframePage) menuItems.push({ name: iFramePageName, icon: MeetingIcon, externalURL: iFramePageLink });
  if (domainKey === "mike")
    menuItems.push({
      name: "NA Meeting Finder",
      icon: MeetingIcon,
      externalURL: "https://www.na-meetings.com/",
    });
  if (showCareer) menuItems.push({ name: "Career", icon: CareerIcon });
  if (showConnect) menuItems.push({ name: "Connect", icon: ShareFeedbackIcon });

  const handelUpgradePress = async () => {
    let newWindow;
    if (Platform.OS === "web") {
      newWindow = window.open("", "_blank");
    }

    const token = await AsyncStorage.getItem("token");
    const data = await createCheckoutSession(token);

    const { url } = data;

    await handleOpenLink(url, newWindow);
  };

  const handleItemPress = (item) => {
    if (item.externalURL) {
      let newWindow;
      if (Platform.OS === "web") {
        newWindow = window.open("", "_blank");
      }

      handleOpenLink(item.externalURL, newWindow);
    } else {
      navigation.navigate(item.name);
      handleNavigate();
    }
  };

  const handleInvitePress = () => {
    navigation.navigate("Invite");
    handleNavigate();
  };

  const handleFeedbackPress = async () => {
    dispatch({
      type: actions.SET_META,
      payload: { keypressIsListening: false },
    });

    setBugReportModalVisible(true);
  };

  const handleCloseBugReportModal = () => {
    dispatch({
      type: actions.SET_META,
      payload: { keypressIsListening: true },
    });
    setBugReportModalVisible(false);
  };

  const handleProfilePress = () => {
    navigation.navigate("Profile");
    handleNavigate();
  };

  const handleLogoutPress = async () => {
    logout();
  };

  const handleNavigate = async () => {
    const token = await AsyncStorage.getItem("token");
    sendMessage({
      meta: {
        token: token,
        abort: true,
      },
    });

    dispatch({
      type: actions.SET_META,
      payload: {
        isMainMenuExpanded: false,
        isLearnMenuOpen: false,
      },
    });
  };

  const showLearnSectionOrName = (name) => {
    if (name === "Tracks" || name === "Categories" || name === "Track Overview") return "Learn";
    return name;
  };

  return (
    <>
      <MainMenu
        menuItems={menuItems}
        selectedItem={showLearnSectionOrName(route.name)}
        currentDomain={domainKey}
        showMiniLogo={showMiniLogo}
        messageCount={state?.meta?.dailyRequestNumber || dailyLimitData?.getDailyLimit.dailyRequests}
        userInitial={meData?.me?.name?.charAt(0)}
        isPro={meData?.me?.isPro}
        isFreemium={isFreemium}
        isLMS={isLMS}
        showInviteFriends={showInviteFriends}
        showFeedback={showFeedback}
        onUpgradePress={handelUpgradePress}
        onItemPress={(item) => handleItemPress(item)}
        onInvitePress={handleInvitePress}
        onFeedbackPress={handleFeedbackPress}
        onProfilePress={handleProfilePress}
        onLogoutPress={handleLogoutPress}
      />
      <div className="js-rapidr-board-btn" style={{ display: "none" }}></div>
      <FeedbackModal visible={bugReportModalVisible} onClose={handleCloseBugReportModal} />
    </>
  );
}

export default MainMenuContainer;
