import { Svg, Path } from "react-native-svg";

function ShareFeedbackIcon({ fill = "#000000", ...props }) {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M16.125 10.125C16.125 10.4234 16.0065 10.7095 15.7955 10.9205C15.5845 11.1315 15.2984 11.25 15 11.25H9C8.70163 11.25 8.41548 11.1315 8.2045 10.9205C7.99353 10.7095 7.875 10.4234 7.875 10.125C7.875 9.82663 7.99353 9.54048 8.2045 9.3295C8.41548 9.11853 8.70163 9 9 9H15C15.2984 9 15.5845 9.11853 15.7955 9.3295C16.0065 9.54048 16.125 9.82663 16.125 10.125ZM15 12.75H9C8.70163 12.75 8.41548 12.8685 8.2045 13.0795C7.99353 13.2905 7.875 13.5766 7.875 13.875C7.875 14.1734 7.99353 14.4595 8.2045 14.6705C8.41548 14.8815 8.70163 15 9 15H15C15.2984 15 15.5845 14.8815 15.7955 14.6705C16.0065 14.4595 16.125 14.1734 16.125 13.875C16.125 13.5766 16.0065 13.2905 15.7955 13.0795C15.5845 12.8685 15.2984 12.75 15 12.75ZM22.125 11.625C22.1223 14.21 21.0942 16.6884 19.2663 18.5163C17.4384 20.3442 14.96 21.3723 12.375 21.375H4.46906C3.98014 21.3745 3.51138 21.1801 3.16566 20.8343C2.81994 20.4886 2.6255 20.0199 2.625 19.5309V11.625C2.625 9.03914 3.65223 6.55919 5.48071 4.73071C7.30919 2.90223 9.78914 1.875 12.375 1.875C14.9609 1.875 17.4408 2.90223 19.2693 4.73071C21.0978 6.55919 22.125 9.03914 22.125 11.625ZM19.875 11.625C19.875 9.63588 19.0848 7.72822 17.6783 6.3217C16.2718 4.91518 14.3641 4.125 12.375 4.125C10.3859 4.125 8.47822 4.91518 7.0717 6.3217C5.66518 7.72822 4.875 9.63588 4.875 11.625V19.125H12.375C14.3634 19.1228 16.2698 18.3319 17.6758 16.9258C19.0819 15.5198 19.8728 13.6134 19.875 11.625Z"
        fill={fill}
      />
    </Svg>
  );
}

export default ShareFeedbackIcon;
