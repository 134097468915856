import { useEffect, useState } from "react";
import { View, StyleSheet, Linking } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import CustomText from "../components/common/general/CustomText/CustomText";
import CustomSpacing from "../components/common/layout/CustomSpacing/CustomSpacing";
import AsyncStorage from "@react-native-async-storage/async-storage";
import BackButton from "../components/navigation/BackButton/BackButton";
import RoundedLabel from "../components/common/label/RoundedLabel/RoundedLabel";
import { useNavigation, useRoute } from "@react-navigation/native";
import { MotiView } from "moti";
import { getAnnouncement, getAssignment, getPage, getSubmissions, searchWeb } from "../services";
import FileCard from "../components/lms/FileCard/FileCard";
import CustomButton from "../components/common/general/CustomButton/CustomButton";
import useResponsiveScreen from "../hooks/useResponsiveScreen";
import { formatDate } from "../utils/date";
import CustomHtml from "../components/common/general/CustomHtml/CustomHtml";
import DefaultLayout from "../layouts/DefaultLayout.web";
import { useChatBar } from "../contexts/ChatBarContext";
import SourcesList from "../components/lms/SourcesList/SourcesList";
import ImageCardList from "../components/lms/ImageCardList/ImageCardList";
import VideoCardList from "../components/lms/VideoCardList/VideoCardList";
import Divider from "../components/common/layout/Divider/Divider";

function ResourceView() {
  const [resource, setResource] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigation = useNavigation();
  const route = useRoute();
  const params = route.params;
  const resourceType = params.resource_type;
  const resourceId = params.resource_id;
  const courseId = params.course_id;
  const integrationId = params.integration_id;
  const { setConversationType, setSchoolMessage } = useChatBar();
  const { isMedium } = useResponsiveScreen();
  const [grade, setGrade] = useState("ungraded");

  const [videoCardData, setVideoCardData] = useState([]);
  const [sources] = useState([]);
  const [imageCardData] = useState([]);

  useEffect(() => {
    setConversationType("SCHOOL");
    setSchoolMessage({
      contentType: resourceType,
      contentId: resourceId,
      classId: courseId,
      integrationId,
    });
  }, [resourceId, courseId, integrationId, resourceType]);

  const fetchResourceData = async () => {
    setIsLoading(true);
    const token = await AsyncStorage.getItem("token");

    let data;
    if (resourceType === "page") {
      data = await getPage(token, integrationId, courseId, resourceId);
    } else if (resourceType === "assignment") {
      data = await getAssignment(token, integrationId, courseId, resourceId);
      const points_possible = data.points_possible;
      const submission = await getSubmissions(token, integrationId, courseId, resourceId);
      const gradePoints = submission.$data[0]?.grade_points;
      if (!gradePoints) {
        setGrade("Ungraded");
      } else {
        const percentage = (gradePoints / points_possible) * 100;
        setGrade(`${percentage.toFixed(2)}%`);
      }
    } else if (resourceType === "announcement") {
      data = await getAnnouncement(token, integrationId, courseId, resourceId);
    }

    setResource(data);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchResourceData();
  }, [integrationId, courseId, resourceId, resourceType]);

  useEffect(() => {
    if (resource.title) {
      const fetchData = async () => {
        try {
          const token = await AsyncStorage.getItem("token");
          const data = await searchWeb(resource.title, token);

          const videos = data.map((item, index) => {
            const uniqueImageIndex = index % item.metadata.images.length;
            return {
              imageUrl: item.metadata.images[uniqueImageIndex], // Select a unique image for each video
              title: item.metadata.title,
              url: item.metadata.source,
            };
          });

          setVideoCardData(videos);
        } catch (error) {
          console.error("Error fetching video data: ", error);
        }
      };

      fetchData();
    }
  }, [resource.title]);

  const navigateToLMS = () => {
    Linking.openURL(resource.url);
  };

  const isAnnouncement = resourceType === "announcement";

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const token = await AsyncStorage.getItem("token");

      let data;
      if (resourceType === "page") {
        data = await getPage(token, integrationId, courseId, resourceId);
      } else if (resourceType === "assignment") {
        data = await getAssignment(token, integrationId, courseId, resourceId);
        const points_possible = data.points_possible;
        const submission = await getSubmissions(token, integrationId, courseId, resourceId);
        const gradePoints = submission.$data[0]?.grade_points;
        if (!gradePoints) {
          setGrade("Ungraded");
        } else {
          const percentage = (gradePoints / points_possible) * 100;
          setGrade(`${percentage.toFixed(2)}%`);
        }
      } else if (isAnnouncement) {
        data = await getAnnouncement(token, integrationId, courseId, resourceId);
      }

      setResource(data);
      setIsLoading(false);
    };

    fetchData();
  }, [integrationId, courseId, resourceId, resourceType]);

  const handleBackPress = () => {
    navigation.navigate("Course", {
      course_id: courseId,
      integration_id: integrationId,
    });
  };

  const labelText =
    resourceType === "announcement"
      ? "Announcement"
      : resourceType === "assignment"
        ? "Assignment"
        : resourceType === "page"
          ? "Material"
          : "Resource";

  const titleText = isAnnouncement ? "Description" : "Instructions";

  const renderSidebar = () => {
    // Check if there is any data available
    const hasData = sources.length > 0 || imageCardData.length > 0 || videoCardData.length > 0;

    // Only render the sidebar if there's data
    if (!hasData) {
      return null; // Return null to not render anything
    }

    return (
      <MotiView
        from={{
          opacity: 0,
          translateY: 20,
          scale: 1,
        }}
        animate={{
          opacity: 1,
          translateY: 0,
          scale: 1,
        }}
        delay={10}
        style={styles.sidebarContainer}
        transition={{ type: "timing", duration: 600 }}>
        {sources.length > 0 && (
          <>
            <CustomText text="Sources" style={styles.headerText} size="m" weight="bold" />
            <SourcesList sources={sources} />
            <CustomSpacing type="vertical" size="m" />
          </>
        )}

        {imageCardData.length > 0 && (
          <>
            <CustomText text="Images" style={styles.headerText} size="m" weight="bold" />
            <ImageCardList imageCardData={imageCardData} />
            <CustomSpacing type="vertical" size="m" />
          </>
        )}

        {videoCardData.length > 0 && (
          <>
            <CustomText text="Videos" style={styles.headerText} size="m" weight="bold" />
            <VideoCardList videoCardData={videoCardData} />
            <CustomSpacing type="vertical" size="m" />
          </>
        )}
      </MotiView>
    );
  };

  return (
    <DefaultLayout>
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={isMedium ? styles.mainContainer : styles.mainContainerMobile}>
        {isLoading ? (
          <View style={styles.loaderContainer}></View>
        ) : (
          <>
            <View style={isMedium ? styles.rowContainer : styles.columnContainer}>
              <View style={isMedium ? styles.contentContainer : styles.fullWidthContentContainer}>
                {isMedium ? (
                  <>
                    <MotiView
                      from={{
                        opacity: 0,
                        translateY: 20,
                        scale: 1,
                      }}
                      animate={{
                        opacity: 1,
                        translateY: 0,
                        scale: 1,
                      }}
                      delay={10}
                      transition={{ type: "timing", duration: 600 }}>
                      <View style={styles.headerContainer}>
                        <BackButton onPress={handleBackPress} />
                        <CustomText text={resource?.title} size="xs" textType="display" />
                      </View>

                      <Divider type="vertical" />

                      <View style={styles.headerContainer}>
                        <RoundedLabel text={labelText} styleType="highlight" />
                        <CustomSpacing type="horizontal" size="l" />

                        <View style={styles.detailRow}>
                          <CustomText text="Due:" weight="bold" size="xs" />

                          <CustomText
                            text={formatDate(
                              isAnnouncement ? resource?.created_date : resource?.due_date,
                              !isAnnouncement
                            )}
                            size="xs"
                          />
                        </View>

                        <CustomSpacing type="horizontal" size="l" />
                        {/* Grade Display */}
                        {resourceType === "assignment" && (
                          <>
                            <View style={styles.detailRow}>
                              <CustomText text="Grade: " weight="bold" size="xs" />

                              <CustomText text={grade} size="xs" />
                            </View>
                            <CustomSpacing type="horizontal" size="l" />
                          </>
                        )}

                        {!isAnnouncement && (
                          <CustomButton
                            text="Submit"
                            styleType="primaryLight"
                            bold={true}
                            size="s"
                            onPress={navigateToLMS}
                            style={styles.buttonShadow}
                          />
                        )}
                        <CustomSpacing type="horizontal" size="l" />
                      </View>

                      <Divider type="vertical" />
                    </MotiView>
                  </>
                ) : (
                  <>
                    <MotiView
                      from={{
                        opacity: 0,
                        translateY: 20,
                        scale: 1,
                      }}
                      animate={{
                        opacity: 1,
                        translateY: 0,
                        scale: 1,
                      }}
                      delay={10}
                      transition={{ type: "timing", duration: 600 }}>
                      <View style={styles.headerContainer}>
                        <BackButton onPress={handleBackPress} />
                        <CustomText text={resource?.title} size="m" weight="bold" />
                      </View>
                      <CustomSpacing type="vertical" size="s" />

                      <View style={styles.mobileRow}>
                        <RoundedLabel text={labelText} styleType="highlight" />
                        <CustomSpacing type="horizontal" size="l" />

                        <View style={styles.detailRow}>
                          <CustomText text="Due:" weight="bold" size="xs" />

                          <CustomText
                            text={formatDate(
                              isAnnouncement ? resource?.created_date : resource?.due_date,
                              !isAnnouncement
                            )}
                            size="xs"
                          />
                        </View>

                        <CustomSpacing type="horizontal" size="l" />
                        {/* Grade Display */}
                        {resourceType === "assignment" && (
                          <>
                            <View style={styles.detailRow}>
                              <CustomText text="Grade: " weight="bold" size="xs" />

                              <CustomText text={grade} size="xs" />
                            </View>
                            <CustomSpacing type="horizontal" size="l" />
                          </>
                        )}

                        {!isAnnouncement && (
                          <CustomButton
                            text="Submit"
                            styleType="primaryLight"
                            bold={true}
                            size="xs"
                            onPress={navigateToLMS}
                            style={[styles.buttonShadow, { marginTop: 10 }]}
                          />
                        )}
                        <CustomSpacing type="horizontal" size="l" />
                        {/* <CustomButton
                          text="Get Tutoring"
                          styleType="primaryLight"
                          bold={true}
                          size="xs"
                          onPress={null} //TODO Trigger chat here.
                          style={[styles.buttonShadow, { marginTop: 10 }]}
                        /> */}
                      </View>
                    </MotiView>
                  </>
                )}
                <CustomSpacing type="vertical" size="l" />

                <MotiView
                  from={{
                    opacity: 0,
                    translateY: 20,
                    scale: 1,
                  }}
                  animate={{
                    opacity: 1,
                    translateY: 0,
                    scale: 1,
                  }}
                  delay={600}
                  transition={{ type: "timing", duration: 600 }}
                  // style={{ flex: 1 }}
                >
                  <CustomText text={titleText} size="m" weight="bold" />
                  <CustomSpacing type="vertical" size="s" />
                  <View>
                    <CustomHtml html={(isAnnouncement ? resource?.body : resource?.description) || "No instructions"} />
                  </View>
                </MotiView>

                {resource?.attachments.length > 0 && (
                  <>
                    <CustomSpacing type="vertical" size="s" />
                    <CustomText text={"Attachments"} size="m" weight="bold" />

                    <CustomSpacing type="vertical" size="s" />
                    <View style={styles.scrollViewWrapper}>
                      <ScrollView
                        horizontal={true}
                        showsHorizontalScrollIndicator={false}
                        style={styles.fileCardsScrollView}
                        contentContainerStyle={{
                          gap: 15,
                        }}>
                        {resource?.attachments.map((attachment) => (
                          <FileCard key={attachment.url} title={attachment?.title} url={attachment?.url} />
                        ))}
                      </ScrollView>
                    </View>
                    <CustomSpacing type="vertical" size="l" />
                  </>
                )}
              </View>

              {isMedium ? (
                renderSidebar()
              ) : (
                <>
                  {(sources.length > 0 || imageCardData.length > 0 || videoCardData.length > 0) && (
                    <>
                      <Divider size="l" />
                      <CustomText textType="display" text="Axio Insights" size="xs" bold={true} weight="bold" />
                      <View style={styles.fullWidthContentContainer}>{renderSidebar()}</View>
                    </>
                  )}
                </>
              )}
            </View>
          </>
        )}
      </ScrollView>
    </DefaultLayout>
  );
}

const styles = StyleSheet.create({
  mainContainerMobile: {
    height: 20,
  },
  mainContainer: {
    // flex: 1,
    height: 20,
  },
  rowContainer: {
    flexDirection: "row",
    flex: 1,
  },
  columnContainer: {
    flexDirection: "column",
    flex: 1,
  },
  fullWidthContentContainer: {
    padding: 16,
    // height: 20,
  },
  contentContainer: {
    flex: 3, // Takes 3/4 of the width
    padding: 16,
  },
  headerText: {
    marginBottom: 6,
  },
  sidebarContainer: {
    flex: 1, // Takes 1/4 of the width
    justifyContent: "flex-start",
    paddingBottom: 16,
    paddingLeft: 16,
    paddingRight: 16,
  },
  headerContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    alignContent: "center",
  },
  loaderContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  mobileRow: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  fileCardsScrollView: {
    flex: 1,
  },
  scrollViewWrapper: {
    height: 85,
  },
  detailRow: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  buttonShadow: {
    borderWidth: 1,
    borderColor: "rgba(5, 6, 15, 0.1)",
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 8 },
    shadowOpacity: 0.05,
    shadowRadius: 16,
    elevation: 5,
  },
});

export default ResourceView;
