import { View, StyleSheet } from "react-native";
import { useState } from "react";
import { useAppConfig } from "../../../AppConfigProvider";
import { useNavigation, useRoute } from "@react-navigation/native";
import ResetPasswordForm from "../../../components/auth/ResetPasswordForm/ResetPasswordForm";
import SendResetPasswordForm from "../../../components/auth/SendResetPasswordForm/SendResetPasswordForm";
import { sendPasswordResetEmailOTP } from "../../../services";
import { useResetPasswordMutation } from "../../../graphql/generated/graphql";
import CustomText from "../../../components/common/general/CustomText/CustomText";
import CustomButton from "../../../components/common/general/CustomButton/CustomButton";
import CustomSpacing from "../../../components/common/layout/CustomSpacing/CustomSpacing";

function ResetPasswordContainer() {
  const appConfig = useAppConfig();
  const navigation = useNavigation();
  const route = useRoute();

  const params = route.params;
  const isReadyToReset = params?.token ? true : false;

  const [resetPassword] = useResetPasswordMutation();

  const [isEmailSent, setIsEmailSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const domainKey = appConfig.key;

  const handleEmailChange = (text) => {
    setEmail(text);
  };

  const handelSendEmail = async () => {
    if (email === "") {
      setErrorMessage("Please enter your email");
      return;
    }

    // Validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setErrorMessage("Invalid email format");
      return;
    }

    try {
      await sendPasswordResetEmailOTP(email);
      setIsEmailSent(true);
      setErrorMessage("");
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setErrorMessage(error.response.data.error);
      } else {
        setErrorMessage("Error sending code. Please try again.");
      }
    }
  };

  const handleNewPasswordChange = (text) => {
    setNewPassword(text);
  };

  const handleConfirmPasswordChange = (text) => {
    setConfirmPassword(text);
  };

  const handleBacktoLogin = () => {
    navigation.navigate("Login");
  };

  const handleResetPassword = async () => {
    if (newPassword !== confirmPassword) {
      setErrorMessage("Passwords do not match");
      return;
    }

    const isValidPassword = validatePassword(newPassword);
    if (!isValidPassword) {
      setErrorMessage(
        "Password must be at least 10 characters long, contain at least 1 uppercase letter, and 1 number or special character."
      );
      return;
    }

    if (!params?.token) {
      setErrorMessage("No token found. Please request a new password reset email.");
      return;
    }

    try {
      const result = await resetPassword({
        variables: {
          token: params?.token,
          newPassword,
        },
      });

      if (!result.data.resetPassword.success) {
        setErrorMessage(result.data.resetPassword.message);
        return;
      }

      setErrorMessage("");

      navigation.navigate("Login");
    } catch (error) {
      setErrorMessage("Password reset failed.");
    }
  };

  const validatePassword = (password) => {
    const hasUppercase = /[A-Z]/.test(password);
    const hasNumberOrSpecial = /[\d!@#$%^&*()_+{}":;'<>?,./\\-]/.test(password);

    return password.length >= 10 && hasUppercase && hasNumberOrSpecial;
  };

  return (
    <>
      {isReadyToReset ? (
        <ResetPasswordForm
          errorMessage={errorMessage}
          companionName={domainKey}
          newPassword={newPassword}
          confirmPassword={confirmPassword}
          onNewPasswordChange={handleNewPasswordChange}
          onConfirmPasswordChange={handleConfirmPasswordChange}
          onResetPassword={handleResetPassword}
        />
      ) : isEmailSent ? (
        <View style={styles.textContainer}>
          <CustomText text={`An email has been sent to ${email}.`} />
          <CustomText text="Please check your inbox." />

          <CustomSpacing type="vertical" size="m" />

          <CustomButton text="Log In" styleType="primary" style={styles.button} onPress={handleBacktoLogin} />
        </View>
      ) : (
        <SendResetPasswordForm
          errorMessage={errorMessage}
          companionName={domainKey}
          email={email}
          onEmailChange={handleEmailChange}
          onSendEmailPress={handelSendEmail}
          onBacktoLogin={handleBacktoLogin}
        />
      )}
    </>
  );
}

export default ResetPasswordContainer;

const styles = StyleSheet.create({
  textContainer: {
    justifyContent: "center",
    alignItems: "center",
  },

  button: {
    justifyContent: "center",
  },
});
