import React from "react";
import { View, Pressable, StyleSheet, Text } from "react-native";
import { MotiView } from "moti";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import { Checked, UnChecked, Repeat, JourneyPath, Calendar } from "../../svgs/common";
import RoundedLabel from "../../common/label/RoundedLabel/RoundedLabel";
import CustomText from "../../common/general/CustomText/CustomText";
import PriorityIndicator from "../PriorityIndicator/PriorityIndicator";

function Task({
  title,
  onCheckboxToggle,
  onJourneyPathPress,
  completed,
  priority,
  date,
  isRepeating,
  isPartOfJourney,
  onTaskPress,
  isJourneyView,
  description,
}) {
  const { isMedium } = useResponsiveScreen();

  const formattedTitle = capitalizeFirstLetterOfEachWord(title);
  const { formattedDate, color, bgColor: backgroundColor } = formatDateRelativeToToday(new Date(date));

  const getPriorityColor = (priority) => {
    switch (priority) {
      case "low":
        return "#F3FEE7"; // Green
      case "medium":
        return "#FFFAEB"; // Yellow
      case "high":
        return "#FFF1F3"; // Red
      default:
        return "#CCC"; // Default color
    }
  };

  const getPriorityText = (priority) => {
    switch (priority) {
      case "low":
        return "#4BA30D"; // Green
      case "medium":
        return "#F79009"; // Yellow
      case "high":
        return "#F63D68"; // Red
      default:
        return "#CCC"; // Default color
    }
  };

  return (
    <MotiView
      from={{
        opacity: 0,
        translateX: -400,
      }}
      animate={{
        opacity: 1,
        translateX: 0,
      }}
      delay={200}
      transition={{ type: "spring" }}>
      <Pressable onPress={onTaskPress}>
        {isMedium ? (
          <View style={styles.card}>
            <View style={styles.leftContainer}>
              <PriorityIndicator priority={priority} />

              {isRepeating && (
                <View>
                  <Repeat />
                </View>
              )}
              <View style={styles.textContainer}>
                <CustomText
                  text={formattedTitle}
                  numberOfLines={1}
                  ellipsizeMode="tail"
                  size={"s"}
                  style={{
                    flexShrink: 1,
                  }}
                />
                <CustomText
                  text={description}
                  numberOfLines={1}
                  ellipsizeMode="tail"
                  size={"xs"}
                  weight={"medium"}
                  style={{
                    flexShrink: 1,
                    color: "#bcbdc3",
                  }}
                />
              </View>
              {completed && (
                <RoundedLabel
                  text="Complete"
                  style={styles.completedContainer}
                  textStyle={styles.completedText}
                  padding={[6, 4]}
                />
              )}
            </View>

            <View style={[styles.rightContainer, { justifyContent: "flex-start" }]}>
              {!completed && (
                <>
                  <Calendar />
                  <RoundedLabel
                    text={formattedDate}
                    style={{ backgroundColor, borderRadius: 8 }}
                    textStyle={{ color }}
                    padding={[6, 4]}
                  />
                </>
              )}

              {isPartOfJourney && !isJourneyView && (
                <Pressable onPress={onJourneyPathPress} style={styles.additionalButton}>
                  <JourneyPath />
                </Pressable>
              )}
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                {!completed || !isRepeating ? (
                  <Pressable onPress={onCheckboxToggle} style={styles.checkbox}>
                    {completed ? <Checked /> : <UnChecked />}
                  </Pressable>
                ) : (
                  <></>
                )}
              </View>
            </View>
          </View>
        ) : (
          <View style={styles.cardMobile}>
            <View style={styles.textContainer}>
              <CustomText text={formattedTitle} numberOfLines={1} ellipsizeMode="tail" bold="bold" />

              <View style={{ flexDirection: "row", marginVertical: 3 }}>
                {!completed ? (
                  <RoundedLabel
                    text={
                      <Text>
                        <Text style={{ color: "grey" }}>Priority: </Text>
                        <Text style={{ color: getPriorityText(priority) }}>
                          {capitalizeFirstLetterOfEachWord(priority)}
                        </Text>
                      </Text>
                    }
                    style={{
                      backgroundColor: getPriorityColor(priority),
                      borderRadius: 8,
                    }}
                    textStyle={{
                      color: getPriorityText(priority),
                    }}
                    padding={[6, 4]}
                  />
                ) : (
                  <RoundedLabel
                    text="Complete"
                    style={styles.completedContainer}
                    textStyle={styles.completedText}
                    padding={[6, 4]}
                  />
                )}

                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    paddingLeft: 8,
                  }}>
                  <Calendar />
                  <RoundedLabel
                    text={formattedDate}
                    style={{ backgroundColor, borderRadius: 8 }}
                    textStyle={{ color }}
                    padding={[6, 4]}
                  />
                  {isRepeating && (
                    <View style={{ marginHorizontal: 10 }}>
                      <Repeat />
                    </View>
                  )}
                </View>
              </View>
            </View>

            <View
              style={{
                marginVertical: 8,
                borderTopWidth: 1,
                borderStyle: "solid",
                borderColor: "#D3D3D3",
              }}
            />

            <View
              style={[
                styles.rightContainer,
                {
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                },
              ]}>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}>
                {!isRepeating || !completed ? (
                  <Pressable onPress={onCheckboxToggle} style={styles.row}>
                    <View style={styles.checkbox}>{completed ? <Checked /> : <UnChecked />}</View>
                    <Text>Complete</Text>
                  </Pressable>
                ) : (
                  <></>
                )}
              </View>

              <View style={{ flexDirection: "row", alignItems: "center" }}>
                {isPartOfJourney && !isJourneyView && (
                  <Pressable onPress={onJourneyPathPress} style={[styles.additionalButton, styles.row]}>
                    <JourneyPath />
                    <Text>View Journey</Text>
                  </Pressable>
                )}
              </View>
            </View>
          </View>
        )}
      </Pressable>
    </MotiView>
  );
}

function capitalizeFirstLetterOfEachWord(str) {
  if (!str) return "";
  return str.replace(/\b\w/g, (match) => match.toUpperCase());
}

function formatDateRelativeToToday(targetDate) {
  const formattedDate = formatDateAsMMDDYYYY(targetDate);

  const color = "#000";
  const bgColor = "#FFF";

  return { formattedDate, color, bgColor };
}

function formatDateAsMMDDYYYY(date) {
  return date.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });
}

const styles = StyleSheet.create({
  card: {
    paddingVertical: 10,
    backgroundColor: "#fff",
    borderRadius: 10,
    marginVertical: 10,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.05,
    shadowRadius: 16,
    elevation: 5,
    paddingHorizontal: 20,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  cardMobile: {
    paddingVertical: 20,
    backgroundColor: "#fff",
    borderRadius: 10,
    marginVertical: 10,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.05,
    shadowRadius: 16,
    elevation: 5,
    paddingHorizontal: 12,
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  leftContainer: {
    flexDirection: "row",
    alignItems: "center",
    flexShrink: 1,
    gap: 10,
    paddingRight: 5,
  },
  textContainer: {
    alignItems: "flex-start",
    flexShrink: 1,
  },
  rightContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  completedContainer: {
    borderRadius: 100,
    backgroundColor: "#66C61C",
  },
  completedText: {
    color: "#fff",
    padding: 4,
  },
  // additionalButton: {
  //   marginRight: 10,
  //   width: 25,
  //   height: 25,
  // },
  checkbox: {
    width: 40,
    height: 40,
    borderRadius: 20,
    justifyContent: "center",
    alignItems: "center",
  },
});

export default Task;
