import apiClient from "./ApiService";

const sendUserIdEmail = async (token, message, subject) => {
  try {
    const response = await apiClient.post(
      "/email/bug-report/send",
      {
        message,
        subject,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error sending user id email:", error);
    throw error;
  }
};

export { sendUserIdEmail };
