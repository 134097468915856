import { View, StyleSheet } from "react-native";
import Logo from "../../general/logos/Logo/Logo";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";
import CustomText from "../../common/general/CustomText/CustomText";
import CustomTextInput from "../../common/general/CustomTextInput/CustomTextInput";
import CustomButton from "../../common/general/CustomButton/CustomButton";

function VerifyEmailForm({ errorMessage, companionName, otp, onOTPChange, onVerifyOTP, onResendOTP }) {
  return (
    <View style={styles.container}>
      <Logo name={companionName} height={55} width={190} />
      <CustomSpacing type="vertical" size="m" />

      <CustomText text="Please verify your email address" size="s" weight="bold" />
      <CustomSpacing type="vertical" size="s" />
      <CustomText text="A verification code has been sent to your email" size="s" weight="bold" />
      <CustomSpacing type="vertical" size="m" />

      <CustomText text="Verification code" size="s" />
      <CustomSpacing type="vertical" size="xs" />
      <CustomTextInput
        style={errorMessage && styles.textInputError}
        value={otp}
        placeholder="Enter 6 digit code"
        onChangeText={(text) => onOTPChange(text)}
        autoCompleteType="one-time-code"
      />
      <CustomSpacing type="vertical" size="m" />

      <CustomButton text="Verify Email" styleType="primary" style={styles.submitButton} onPress={onVerifyOTP} />
      <CustomSpacing type="vertical" size="s" />

      <CustomButton text="Resend Code" onPress={onResendOTP} styleType="transparent" />

      {errorMessage ? (
        <>
          <CustomSpacing type="vertical" size="s" />
          <CustomText text={errorMessage} size="s" style={styles.errorMessage} />
          <CustomSpacing type="vertical" size="s" />
        </>
      ) : (
        <CustomSpacing type="vertical" size="xl" />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginvertical: "auto",
    width: "100%",
    maxWidth: 600,
  },
  textInputError: {
    borderColor: "red",
  },
  submitButton: {
    justifyContent: "flex-start",
  },
  errorMessage: {
    color: "red",
    textAlign: "center",
  },
});

export default VerifyEmailForm;
