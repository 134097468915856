import { View, StyleSheet } from "react-native";
import CustomText from "../../common/general/CustomText/CustomText";
import Divider from "../../common/layout/Divider/Divider";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";

function HealthTable({ items }) {
  return (
    <View>
      {items.map((item, index) => (
        <View key={index}>
          <View style={styles.row}>
            <CustomText text={item.label} />
            <CustomSpacing type="horizontal" size="xxl" />
            <CustomText text={item.value} size="l" style={styles.valueText} />
          </View>
          {items.length - 1 !== index && <Divider type="vertical" size="m" />}
        </View>
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  valueText: {
    fontFamily: "DmMono",
    color: "#1E3A8A",
  },
});

export default HealthTable;
