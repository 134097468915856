import React, { useEffect } from "react";
import { View, StyleSheet, Text, Pressable } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import CustomText from "../components/common/general/CustomText/CustomText";
import CustomSpacing from "../components/common/layout/CustomSpacing/CustomSpacing";
import { useAppState, actions } from "../contexts/AppStateContext";

import DefaultLayout from "../layouts/DefaultLayout.web";
import ErrorLoader from "../components/common/status/ErrorLoader/ErrorLoader";
import BackButton from "../components/navigation/BackButton/BackButton";
import RoundedLabel from "../components/common/label/RoundedLabel/RoundedLabel";
import { useNavigation } from "@react-navigation/native";
import { MotiView } from "moti";

import useResponsiveScreen from "../hooks/useResponsiveScreen";
import TaskListContainer from "../containers/tasks/TaskListContainer/TaskListContainer";
import AddTaskOutline from "../components/tasks/AddTaskOutline/AddTaskOutline";
import {
  useGetJourneyByIdQuery,
  useDeleteJourneyMutation,
  useCompleteJourneyMutation,
  GetChatsDocument,
} from "../graphql/generated/graphql";
import Loader from "../components/common/status/Loader/Loader";
import TaskFormModalContainer from "../containers/tasks/TaskFormModalContainer/TaskFormModalContainer";
import CustomButton from "../components/common/general/CustomButton/CustomButton";
import DeleteJourneyModalContainer from "../containers/journey/DeleteJourneyModalContainer/DeleteJourneyModalContainer";
import CompleteJourneyModalContainer from "../containers/journey/CompleteJourneyModalContainer/CompleteJourneyModalContainer";
import CustomProgressBar from "../components/common/general/CustomProgressBar/CustomProgressBar";
import { ConversationType, useChatBar } from "../contexts/ChatBarContext";

function formatDateString(dateString) {
  const date = new Date(dateString);
  const options = { year: "numeric", month: "long", day: "numeric" };
  return date.toLocaleDateString("en-US", options);
}

function JourneyView({ route }) {
  const { dispatch } = useAppState();
  const { setConversationType, setJourneyMessage } = useChatBar();

  const id = route?.params?.id;

  const [modalVisible, setModalVisible] = React.useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = React.useState(false);
  const [completeModalVisible, setCompleteModalVisible] = React.useState(false);

  const { data, loading, error, refetch } = useGetJourneyByIdQuery({
    skip: !id,
    variables: { journeyId: id },
  });

  const [deleteJourney] = useDeleteJourneyMutation({
    refetchQueries: {
      query: GetChatsDocument,
    },
  });
  const [completeJourney] = useCompleteJourneyMutation();

  useEffect(() => {
    if (id) {
      refetch();
    }
  }, [id, refetch]);

  useEffect(() => {
    setConversationType(ConversationType.JOURNEY);
    setJourneyMessage({
      journeyId: id,
    });
  }, [id]);

  //Loader blanked out whole page and was in top left - its actually better with no main loader - there is a loader within.
  // if (loading) return <Loader />;
  if (error || !data || !data?.getJourneyById) {
    return (
      <DefaultLayout>
        <View style={styles.loaderContainer}>
          <ErrorLoader message="Something went wrong! No data found for this journey." />
        </View>
      </DefaultLayout>
    );
  }

  const { name, description, dueDate } = data.getJourneyById;

  const tasksLength = data?.getJourneyById.tasks.length;
  const completedTodos = data?.getJourneyById.tasks.filter((todo) => todo.completed).length;

  const isLoading = false;
  const navigation = useNavigation();

  const activeTab = "tasks";

  const { isMedium } = useResponsiveScreen();

  const handleBackPress = () => {
    navigation.navigate("Productivity");
  };

  const handleModalOpen = () => {
    dispatch({
      type: actions.SET_META,
      payload: { keypressIsListening: false },
    });
    setModalVisible(true);
  };

  const handleModalClose = () => {
    dispatch({
      type: actions.SET_META,
      payload: { keypressIsListening: true },
    });
    setModalVisible(false);
  };

  const handleDeleteModalOpen = () => {
    setDeleteModalVisible(true);
  };

  const handleDeleteModalClose = () => {
    setDeleteModalVisible(false);
  };

  const handleCompleteModalOpen = () => {
    setCompleteModalVisible(true);
  };

  const handleCompleteModalClose = () => {
    setCompleteModalVisible(false);
  };

  const handleDeleteJourney = async () => {
    try {
      const response = await deleteJourney({ variables: { journeyId: id } });
      if (response.data.deleteJourney.success) {
        setDeleteModalVisible(false);
        navigation.navigate("Productivity", { refresh: true });
      } else {
        throw new Error(response.data.deleteJourney.message);
      }
    } catch (error) {
      console.error("Error deleting journey: ", error);
    }
  };

  const handleCompleteJourney = async () => {
    try {
      const response = await completeJourney({ variables: { journeyId: id } });
      if (response.data.completeJourney.success) {
        setCompleteModalVisible(false);
        refetch();
      }
    } catch (error) {
      console.error("Error completing journey: ", error);
    }
  };

  return (
    <DefaultLayout>
      <View style={styles.mainContainer}>
        {isLoading ? (
          <View style={styles.loaderContainer}></View>
        ) : (
          <>
            <View style={styles.container}>
              {isMedium ? (
                <>
                  <MotiView
                    from={{
                      opacity: 0,
                      translateY: 20,
                      scale: 1,
                    }}
                    animate={{
                      opacity: 1,
                      translateY: 0,
                      scale: 1,
                    }}
                    delay={10}
                    transition={{ type: "timing", duration: 600 }}>
                    <View style={styles.headerContainer}>
                      <BackButton onPress={handleBackPress} />
                      <CustomText text={name} size="xs" textType="display" />
                      <CustomSpacing type="horizontal" size="s" />

                      <RoundedLabel
                        buttonSizes="s"
                        text={`Complete by ${formatDateString(dueDate)}`}
                        styleType="highlight"
                      />
                      <CustomSpacing type="horizontal" size="s" />
                    </View>
                  </MotiView>
                </>
              ) : (
                <>
                  <MotiView
                    from={{
                      opacity: 0,
                      translateY: 20,
                      scale: 1,
                    }}
                    animate={{
                      opacity: 1,
                      translateY: 0,
                      scale: 1,
                    }}
                    delay={10}
                    transition={{ type: "timing", duration: 600 }}>
                    <View style={styles.headerContainer}>
                      <BackButton onPress={handleBackPress} />
                      <CustomText text={name} size={isMedium ? "xl" : "m"} weight="bold" />
                    </View>
                    <CustomSpacing type="vertical" size="m" />
                  </MotiView>
                </>
              )}

              <MotiView
                from={{
                  opacity: 0,
                  translateY: 20,
                  scale: 1,
                }}
                animate={{
                  opacity: 1,
                  translateY: 0,
                  scale: 1,
                }}
                delay={600}
                transition={{ type: "timing", duration: 600 }}>
                <View style={isMedium ? styles.descriptionContainer : styles.mobileDescriptionContainer}>
                  <View style={styles.descriptionTextContainer}>
                    <CustomText text={"Description"} size="m" weight="bold" />
                    <CustomSpacing type="vertical" size="s" />
                    <Text>{description}</Text>
                  </View>
                  <View style={styles.buttonContainer}>
                    <CustomButton
                      text="Complete Journey"
                      size={isMedium ? "m" : "s"}
                      styleType="primaryLight"
                      onPress={handleCompleteModalOpen}
                    />
                    <CustomButton
                      text="Delete Journey"
                      size={isMedium ? "m" : "s"}
                      styleType="danger"
                      style={{ marginLeft: 10 }}
                      onPress={handleDeleteModalOpen}
                    />
                  </View>
                </View>

                <CustomSpacing type="vertical" size="s" />
              </MotiView>

              <MotiView
                from={{
                  opacity: 0,
                  translateY: 20,
                  scale: 1,
                }}
                animate={{
                  opacity: 1,
                  translateY: 0,
                  scale: 1,
                }}
                delay={1000}
                transition={{ type: "timing", duration: 600 }}
                style={{ flex: 1 }}>
                {activeTab === "tasks" && (
                  <>
                    <View
                      style={{
                        marginTop: 20,
                        marginBottom: 20,
                        flexDirection: "column",
                      }}>
                      <View>
                        <CustomText
                          weight="bold"
                          text={`${completedTodos} of ${tasksLength} Tasks Complete`}
                          size="s"
                        />
                      </View>
                      <View style={styles.progressBarContainer}>
                        <CustomProgressBar progress={Math.round((completedTodos / tasksLength) * 100)} />
                      </View>
                    </View>

                    {loading ? (
                      <Loader />
                    ) : (
                      <ScrollView style={{ height: 20 }} showsVerticalScrollIndicator={false}>
                        <Pressable onPress={handleModalOpen}>
                          <AddTaskOutline />
                        </Pressable>
                        <TaskListContainer isJourneyView={true} journeyId={id} tasks={data?.getJourneyById.tasks} />
                      </ScrollView>
                    )}
                  </>
                )}
              </MotiView>
            </View>
          </>
        )}
      </View>
      <TaskFormModalContainer journeyId={id} visible={modalVisible} onClose={handleModalClose} />
      <DeleteJourneyModalContainer
        visible={deleteModalVisible}
        onClose={handleDeleteModalClose}
        onDelete={handleDeleteJourney}
      />
      <CompleteJourneyModalContainer
        visible={completeModalVisible}
        onClose={handleCompleteModalClose}
        onComplete={handleCompleteJourney}
      />
    </DefaultLayout>
  );
}

const styles = StyleSheet.create({
  loaderContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  mainContainer: {
    flex: 1,
  },
  container: {
    flex: 1,
  },
  headerContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    alignContent: "center",
    marginVertical: 10,
  },
  progressBarContainer: {
    width: "100%",
    paddingVertical: 4,
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
  },
  descriptionTextContainer: {
    flex: 1,
  },
  descriptionContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  mobileDescriptionContainer: {
    flexDirection: "column",
    gap: 10,
  },
});

export default JourneyView;
