import React, { useState, useCallback, useEffect } from "react";
import { View, StyleSheet } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import useWebSocket from "../hooks/useWebSocket";
import { useNavigation, useRoute, useFocusEffect } from "@react-navigation/native";
import useResponsiveScreen from "../hooks/useResponsiveScreen";
import getLearningRoute from "../utils/getLearningRoute";
import LearnChatLayout from "../layouts/LearnChatLayout.web";
import CustomSpacing from "../components/common/layout/CustomSpacing/CustomSpacing";
import ChatContainer from "../containers/chat/ChatContainer/ChatContainer";
import LessonBreadcrumbs from "../components/learning/lesson/LessonBreadcrumbs/LessonBreadcrumbs";
import {
  useUpdateLearningObjectiveCompletionMutation,
  useGetMasteryQuery,
  useGetLearningObjectivesBySubchapterQuery,
  GetLearningObjectiveByIdDocument,
  GetMasteryDocument,
  TableOfContentsDocument,
  RecentTracksDocument,
  UserProgressDocument,
  useGetChatByObjectIdQuery,
} from "../graphql/generated/graphql";
import { Context } from "../contexts/ChatBarContext";
import { useAppState } from "../contexts/AppStateContext";

import CustomButton from "../components/common/general/CustomButton/CustomButton";
import { CheckedCircle } from "../components/svgs/common";
import { useAppConfig } from "../AppConfigProvider";
import CustomText from "../components/common/general/CustomText/CustomText";

function LessonChatView() {
  const { sendMessage } = useWebSocket();
  const navigation = useNavigation();
  const route = useRoute();
  const { state } = useAppState();
  const { isMedium } = useResponsiveScreen();
  const appConfig = useAppConfig();
  const primaryColor = appConfig.primaryColor;
  const param = route.params;
  const { loId } = param;

  const [updateLearningObjectiveCompletion] = useUpdateLearningObjectiveCompletionMutation({
    refetchQueries: [
      {
        query: GetLearningObjectiveByIdDocument,
        variables: { loId },
      },
      {
        query: GetMasteryDocument,
        variables: {
          learningObjectiveId: loId,
        },
      },
      {
        query: TableOfContentsDocument,
        variables: {
          learningNodeNames: [param.topic],
          hierarchyLabel: "Topic",
        },
      },
      {
        query: TableOfContentsDocument,
        variables: {
          learningNodeNames: [param.chapter],
          hierarchyLabel: "Chapter",
        },
      },
      {
        query: TableOfContentsDocument,
        variables: {
          learningNodeNames: [param.subchapter],
          hierarchyLabel: "Subchapter",
        },
      },
      {
        query: RecentTracksDocument,
      },
      {
        query: UserProgressDocument,
        variables: { nodeName: param.subfield, nodeLabel: "Subfield" },
      },
      {
        query: UserProgressDocument,
        variables: { nodeName: param.field, nodeLabel: "Field" },
      },
    ],
  });

  const [shouldRefetch, setShouldRefetch] = useState(false);

  const { data: masteryData, refetch: refetchMastery } = useGetMasteryQuery({
    variables: {
      learningObjectiveId: loId,
    },
    skip: !loId,
    onCompleted: () => setShouldRefetch(false),
  });

  const { data: chatData, refetch: refetchChatData } = useGetChatByObjectIdQuery({
    variables: {
      objectId: route.params.loId,
      context: Context.TRACK,
    },
  });

  useEffect(() => {
    refetchChatData();
  }, [state.meta.chatHistory]);

  const isUnitCompleted = masteryData?.getUserLearningObjectiveMastery?.totalMastery === 100;

  const { data: learningObjectivesData } = useGetLearningObjectivesBySubchapterQuery({
    variables: { learningObjectiveId: loId },
  });

  const learningObjectives = learningObjectivesData?.getLearningObjectivesBySubchapter;

  const handleNextUnit = () => {
    if (!learningObjectives) return;

    const currentObjectiveIndex = learningObjectives.findIndex((lo) => lo.id === loId);
    const nextLearningObjective = learningObjectives[currentObjectiveIndex + 1];

    if (!nextLearningObjective) return;

    const nextPage = nextLearningObjective.title === "Assessment Quiz" ? "Lesson Assessment" : "Lesson Activities";

    navigation.navigate(nextPage, {
      field: param.field,
      subfield: param.subfield,
      topic: param.topic,
      chapter: param.chapter,
      subchapter: param.subchapter,
      loTitle: nextLearningObjective.title,
      loId: nextLearningObjective.id,
    });
  };

  const markLearningObjectiveComplete = (loId, completed) => {
    updateLearningObjectiveCompletion({
      variables: {
        id: loId,
        completed: completed,
      },
    })
      .then(() => {
        setShouldRefetch(true);
      })
      .catch((err) => {
        console.error("Error updating learning objective:", err);
      });
  };

  if (shouldRefetch) {
    refetchMastery();
  }

  const { ancestors, name } = getLearningRoute(route.params);

  useFocusEffect(
    useCallback(() => {
      return () => {
        (async () => {
          const token = await AsyncStorage.getItem("token");
          sendMessage({
            meta: {
              token,
              timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              abort: true,
            },
            state: {
              general: "LEARNING",
              learning: {
                state: "TEXTBOOK",
                topic: name,
                topicAncestors: ancestors,
              },
            },
          });
        })();
      };
    }, [])
  );

  const handleBackToActivities = async () => {
    navigation.navigate(
      route.params.page === "Unit Assessment" ? "Lesson Assessment" : "Lesson Activities",
      route.params
    );
  };

  // this view is going to be replaced with the new full screen chat modal?
  return (
    <LearnChatLayout>
      <View style={isMedium ? styles.container : styles.mobileContainer}>
        <LessonBreadcrumbs
          onPress={handleBackToActivities}
          firstItem={route.params.page}
          secondItem={route.params.title}
        />
        <CustomText
          text={route.params.title}
          size={isMedium ? "s" : "xs"}
          textType="display"
          weight="ultraBold"
          numberOfLines={1}
          ellipsizeMode="tail"
          style={{ color: "#101828" }}
        />
        <CustomSpacing type="vertical" size="l" />
        <ChatContainer chatData={chatData?.getChatByObjectId} />
        <CustomSpacing type="vertical" size="l" />
        <View style={styles.bottomRow}>
          <CustomButton
            text={isUnitCompleted ? "Completed" : "Mark as completed"}
            styleType="primary"
            bold
            onPress={() => markLearningObjectiveComplete(id, !isUnitCompleted)}
            leftIcon={<CheckedCircle fill="white" />}
            style={[
              styles.unitButton,
              { justifyContent: "center", alignItems: "center", gap: 4 },
              { backgroundColor: isUnitCompleted ? "#66C61C" : primaryColor },
            ]}
          />
          {route.params.page !== "Unit Assessment" && (
            <CustomButton
              text="Next"
              bold
              style={[styles.unitButton, { border: `2px solid ${primaryColor}` }]}
              textStyle={{ fontSize: 14 }}
              styleType="primaryLight"
              onPress={handleNextUnit}
            />
          )}
        </View>
        {!isMedium && <CustomSpacing type="vertical" size="l" />}
      </View>
    </LearnChatLayout>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 40,
  },
  mobileContainer: {
    flex: 1,
    paddingHorizontal: 20,
    paddingTop: 20,
  },
  unitButton: {
    padding: 12,
  },
  bottomRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
});

export default LessonChatView;
