import { useEffect } from "react";
import { View, StyleSheet } from "react-native";
import { usePostHog } from "posthog-js/react";
import { useMeQuery } from "../graphql/generated/graphql";
import DefaultLayout from "../layouts/DefaultLayout.web";
import CustomText from "../components/common/general/CustomText/CustomText";
import CustomSpacing from "../components/common/layout/CustomSpacing/CustomSpacing";
import CustomTextInput from "../components/common/general/CustomTextInput/CustomTextInput";
import Divider from "../components/common/layout/Divider/Divider";
import ProfileImage from "../components/profile/ProfileImage/ProfileImage";
import IntegrationCardListContainer from "../containers/profile/IntegrationCardListContainer/IntegrationCardListContainer";
import PlanCardContainer from "../containers/profile/PlanCardContainer/PlanCardContainer";
import { ScrollView } from "react-native-gesture-handler";
import { MotiView } from "moti";

function ProfileView() {
  const posthog = usePostHog();
  const { data, refetch } = useMeQuery({});

  useEffect(() => {
    posthog?.capture("Profile Page Visited");
  }, []);

  return (
    <DefaultLayout>
      <MotiView
        style={{ flex: 1 }}
        from={{
          opacity: 0,
          translateY: 20,
          scale: 1,
        }}
        animate={{
          opacity: 1,
          translateY: 0,
          scale: 1,
        }}
        delay={500}
        transition={{ type: "timing", duration: 600 }}>
        <CustomText text="Profile" size="xs" textType="display" />
        <CustomSpacing type="vertical" size="l" />
        <ScrollView style={styles.scrollView} showsVerticalScrollIndicator={false}>
          <View style={styles.infoContainer}>
            <View>
              <ProfileImage />
              <CustomSpacing type="vertical" size="m" />
            </View>
            <CustomSpacing type="horizontal" size="xxl" />
            <View>
              <CustomText text="Basic Information" size="xs" textType="display" />
              <CustomSpacing type="vertical" size="l" />

              <CustomText text="Name" size="m" />
              <CustomSpacing type="vertical" size="s" />
              {/* Add a loader here? */}
              {data?.me?.name && (
                <CustomTextInput value={data?.me?.name} style={{ pointerEvents: "none" }} editable={false} />
              )}
            </View>
          </View>

          <Divider size="xl" type="vertical" />

          <CustomText text="Current Plan" size="xs" textType="display" />
          <CustomSpacing type="vertical" size="l" />
          <PlanCardContainer userData={data} />

          <Divider size="xl" type="vertical" />

          <CustomText text="Integrations" size="xs" textType="display" />
          <CustomSpacing type="vertical" size="l" />
          <IntegrationCardListContainer userData={data} refetchUserData={refetch} />
        </ScrollView>
      </MotiView>
    </DefaultLayout>
  );
}

const styles = StyleSheet.create({
  infoContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  scrollView: {
    height: 20,
    paddingHorizontal: 10,
  },
});

export default ProfileView;
