import React from "react";
import { StyleSheet, View } from "react-native";
import VideoCard from "../VideoCard/VideoCard";

const VideoCardList = ({ videoCardData }) => {
  return (
    <View>
      <View style={styles.container}>
        {videoCardData.map((card, index) => (
          <VideoCard key={index} title={card.title} imageUrl={card.imageUrl} url={card.url} />
        ))}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
  },
});

export default VideoCardList;
