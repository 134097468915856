import { View, StyleSheet } from "react-native";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import { MotiView } from "moti";
import { ScrollView } from "react-native-gesture-handler";
import Logo from "../../general/logos/Logo/Logo";
import MiniLogo from "../../general/logos/MiniLogo/MiniLogo";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import UserInitial from "../../general/UserInitial/UserInitial";
import DailyChatProgress from "../DailyChatProgress/DailyChatProgress";
import MainMenuItem from "../MainMenuItem/MainMenuItem.web";
import { LogoutIcon } from "../../svgIcons";

function MainMenu({
  menuItems,
  selectedItem,
  currentDomain,
  showMiniLogo,
  messageCount,
  userInitial,
  isPro,
  isFreemium,
  isLMS,
  showFeedback,
  onUpgradePress,
  onItemPress,
  onFeedbackPress,
  onProfilePress,
  onLogoutPress,
}) {
  const { isXLarge, isMedium, hp } = useResponsiveScreen();

  const isExpanded = isXLarge || !isMedium;

  const profileTitle = isMedium ? "Profile" : "PROFILE";
  const logoutTitle = isMedium ? "Logout" : "LOGOUT";

  return (
    <MotiView
      style={styles.menu}
      animate={{ maxWidth: isXLarge ? 240 : isMedium ? 80 : hp(100) }}
      transition={{ type: "timing", duration: 600 }}>
      <ScrollView
        style={isMedium ? styles.scrollViewDesktop : styles.scrollViewMobile}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={isMedium ? styles.scrollViewDesktopContainer : {}}>
        <View>
          {isMedium && (
            <>
              <View style={styles.desktopTopDivider} />
              <View style={isXLarge ? styles.topContainer : {}}>
                {isXLarge ? (
                  <Logo name={currentDomain} height={40} />
                ) : showMiniLogo ? (
                  <MiniLogo name={currentDomain} height={40} width={50} />
                ) : (
                  <View style={{ width: 80, height: 40 }}></View>
                )}
              </View>
              <View style={styles.desktopLogoDivider} />
            </>
          )}

          <View>
            {menuItems.map((item, index) => (
              <MainMenuItem
                key={index}
                item={item}
                isSelected={selectedItem === item.name}
                onPress={() => onItemPress(item)}
              />
            ))}
          </View>
        </View>

        <View style={[styles.bottomContainer, isMedium ? {} : { padding: 15 }]}>
          {isExpanded && (
            <>
              {isFreemium && !isLMS && (
                <>
                  <DailyChatProgress
                    onUpgradePress={onUpgradePress}
                    currentDomain={currentDomain}
                    messageCount={messageCount}
                    isPro={isPro}
                  />
                  <CustomSpacing type="vertical" size="l" />
                </>
              )}

              {showFeedback && (
                <>
                  <CustomButton
                    onPress={onFeedbackPress}
                    text="Share Feedback"
                    styleType="primary"
                    textStyle={styles.largeButtonText}
                  />
                  <CustomSpacing type="vertical" size="l" />
                </>
              )}
            </>
          )}

          <CustomButton
            onPress={onProfilePress}
            text={isExpanded ? profileTitle : null}
            leftIcon={
              <View style={isExpanded ? styles.settingsIconSpacing : {}}>
                <UserInitial initial={userInitial} />
              </View>
            }
            size={isMedium ? "m" : "s"}
            styleType="transparent"
            style={[isMedium ? styles.desktopSettingsButtons : {}, styles.settingsButtons]}
            textStyle={isMedium ? [{ fontSize: 14 }] : [styles.menuButtonText, { fontSize: hp(1.7) }]}
          />
          {!isMedium && <CustomSpacing type="vertical" size="l" />}
          <CustomButton
            onPress={onLogoutPress}
            text={isExpanded ? logoutTitle : null}
            leftIcon={
              <View style={isExpanded ? styles.settingsIconSpacing : {}}>
                <LogoutIcon />
              </View>
            }
            size={isMedium ? "m" : "s"}
            styleType="transparent"
            style={[isMedium ? styles.desktopSettingsButtons : {}, styles.settingsButtons]}
            textStyle={isMedium ? [{ fontSize: 14 }] : [styles.menuButtonText, { fontSize: hp(1.7) }]}
          />
          <CustomSpacing type="vertical" size="m" />
        </View>
      </ScrollView>
    </MotiView>
  );
}

const styles = StyleSheet.create({
  menu: {
    top: 0,
    left: 0,
    bottom: 0,
    flex: 1,
    backgroundColor: "#fff",
    borderTopRightRadius: 24,
    zIndex: 20,
    shadowColor: "#000",
    shadowOffset: {
      width: 8,
      height: 0,
    },
    shadowOpacity: 0.02,
    shadowRadius: 8,
    elevation: 5,
  },
  topContainer: {
    paddingHorizontal: 6.39,
  },
  scrollViewDesktop: {
    height: 20,
    paddingHorizontal: 15,
  },
  scrollViewDesktopContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  },
  scrollViewMobile: {
    height: 20,
    paddingHorizontal: 20,
  },
  bottomContainer: {
    width: "100%",
  },
  largeButtonText: {
    fontSize: 14,
  },
  settingsButtons: {
    justifyContent: "flex-start",
    padding: 0,
  },
  desktopSettingsButtons: {
    height: 48,
    paddingHorizontal: 12,
    paddingVertical: 12,
  },
  settingsIconSpacing: {
    marginRight: 12,
  },
  menuButtonText: {
    letterSpacing: 2,
    fontFeatureSettings: "'cling' off, 'liga' off",
  },
  desktopTopDivider: {
    height: 30,
  },
  desktopLogoDivider: {
    height: 20,
  },
});

export default MainMenu;
