import apiClient from "./ApiService";

const calendar = async (token) => {
  try {
    const response = await apiClient.get("/calendar", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error getting GCal:", error);
    throw error;
  }
};

const calendarEvents = async (token, dateString, timezoneoffset) => {
  try {
    const response = await apiClient.get(
      `/calendar/events?date=${dateString}&timezoneoffset=${encodeURIComponent(timezoneoffset)}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error getting GCal Events:", error);
    throw error;
  }
};

const createCalendarEvent = async (token, eventDetails) => {
  try {
    const response = await apiClient.post(`/calendar/createEvent`, eventDetails, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error creating GCal Event:", error);
    throw error;
  }
};

export { calendar, calendarEvents, createCalendarEvent };
