import { View, StyleSheet } from "react-native";
import { useRef, useState, useEffect } from "react";
import { ScrollView } from "react-native-gesture-handler";
import { useAppState, actions } from "../contexts/AppStateContext";
import { useIsFocused } from "@react-navigation/native";

import JourneyCardList from "../components/journey/CareerPathCardList/JourneyCardList";
import CustomButton from "../components/common/general/CustomButton/CustomButton";
import { PlusCircle } from "../components/svgIcons";
import {
  useJourneySubmitFormMutation,
  useGetJourneysQuery,
  GetJourneysDocument,
  TodosDocument,
} from "../graphql/generated/graphql";
import JourneyFormModalContainer from "../containers/journey/JourneyFormModalContainer/JourneyFormModalContainer";
import Loader from "../components/common/status/Loader/Loader";
import CustomSpacing from "../components/common/layout/CustomSpacing/CustomSpacing";
import CustomText from "../components/common/general/CustomText/CustomText";

function JourneysView({ onPress }) {
  const scrollViewRef = useRef(null);
  const [isModalVisible, setModalVisible] = useState(false);
  const isFocused = useIsFocused();

  const { dispatch } = useAppState();

  const openModal = () => {
    dispatch({
      type: actions.SET_META,
      payload: { keypressIsListening: false },
    });
    setModalVisible(true);
  };
  const closeModal = () => {
    dispatch({
      type: actions.SET_META,
      payload: { keypressIsListening: true },
    });
    setModalVisible(false);
  };

  const { data: journeys, loading: journeyLoading, refetch } = useGetJourneysQuery();

  useEffect(() => {
    if (isFocused) {
      refetch();
    }
  }, [isFocused, refetch]);

  const journeyCardData = journeys?.getJourneys || [];

  const [submitJourney, { loading }] = useJourneySubmitFormMutation({
    refetchQueries: [{ query: GetJourneysDocument }, { query: TodosDocument }],
  });

  const handleCreateJourney = async (description, date) => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    try {
      await submitJourney({
        variables: {
          description: description,
          dueDate: date,
          timezone: timezone,
        },
      });
    } catch (error) {
      console.error("Error submitting career form:", error);
    }
  };

  if (journeyLoading) {
    return (
      <>
        <CustomSpacing type="vertical" size="m" />
        <Loader />
        <CustomSpacing type="vertical" size="m" />
      </>
    );
  }

  return (
    <>
      <View style={styles.headerContainer}>
        <JourneyFormModalContainer onSubmit={handleCreateJourney} visible={isModalVisible} onClose={closeModal} />
        <CustomButton
          onPress={openModal}
          text={"Create a New Journey"}
          styleType="primaryLight"
          leftIcon={<PlusCircle />}
          disabled={loading}
        />
      </View>
      <ScrollView ref={scrollViewRef} style={styles.scrollView} showsVerticalScrollIndicator={false}>
        {loading && (
          <View style={styles.loaderContainer}>
            <CustomText text="Your journey is being generated. Feel free to explore other areas while you wait." />
            <CustomSpacing type="vertical" size="m" />
            <Loader />
          </View>
        )}
        <JourneyCardList onPress={onPress} journeyCardData={journeyCardData} />
      </ScrollView>
    </>
  );
}

const styles = StyleSheet.create({
  scrollView: {
    height: 20,
    paddingHorizontal: 10,
  },
  loaderContainer: {
    justifyContent: "center",
    alignItems: "center",
  },
  headerContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    padding: 20,
  },
});

export default JourneysView;
