import { useState } from "react";
import { View, StyleSheet } from "react-native";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";

function MainMenuItem({ item, isSelected, onPress }) {
  const { isXLarge, isMedium, hp } = useResponsiveScreen();

  const [isHovered, setIsHovered] = useState(false);

  const isExpanded = isXLarge || !isMedium;

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const buttonStyleType = isSelected ? "primary" : isHovered ? "primaryLightened" : "transparent";
  const iconFill = isSelected ? "#fff" : "#000";

  return (
    <>
      <View onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <CustomButton
          onPress={onPress}
          text={isExpanded ? (isMedium ? item.name : item.name.toUpperCase()) : null}
          leftIcon={
            <View style={isExpanded ? styles.menuButtonIcon : null}>
              <item.icon fill={iconFill} />
            </View>
          }
          styleType={buttonStyleType}
          size={isMedium ? "m" : "s"}
          style={[
            isMedium ? [styles.defaultPadding, styles.menuButtons] : {},
            isExpanded ? styles.menuButtonFlex : styles.menuButtonFlexCollapsed,
          ]}
          textStyle={isMedium ? [styles.desktopButtonText] : [styles.menuButtonText, { fontSize: hp(1.7) }]}
        />
      </View>
      {isMedium ? <View /> : <CustomSpacing type="vertical" size="m" />}
    </>
  );
}

const styles = StyleSheet.create({
  defaultPadding: {
    padding: 8,
    marginVertical: 3,
  },
  menuButtons: {
    height: 40,
    paddingHorizontal: 12,
    paddingVertical: 8,
    borderRadius: 8,
  },
  menuButtonFlex: {
    justifyContent: "flex-start",
  },
  menuButtonIcon: {
    marginRight: 12,
  },
  menuButtonText: {
    letterSpacing: 2,
    fontFeatureSettings: "'cling' off, 'liga' off",
  },
  desktopButtonText: {
    fontSize: 14,
    lineHeight: 20,
    fontWeight: 500,
  },
  menuButtonFlexCollapsed: {
    justifyContent: "center",
    alignItems: "center",
  },
});

export default MainMenuItem;
