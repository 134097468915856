import React from "react";
import { ScrollView, StyleSheet, View, Pressable } from "react-native";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import CustomText from "../../common/general/CustomText/CustomText";
import CustomMarkdown from "../../common/general/CustomMarkdown/CustomMarkdown";
import ProsCons from "../ProsCons/ProsCons";

const CareerPathNav = ({ tabData, activeTab, onTabPress, rightButtonComponent, flexDirection }) => {
  const { isLarge } = useResponsiveScreen();

  const tabConfig = {
    description: "Deep Dive",
    path: "Career Path",
    trends: "Industry Trends",
    prosCons: {
      title: "Pros & Cons",
    },
  };
  const generateTabData = (data, config) => {
    return Object.entries(config).map(([key, value]) => {
      if (typeof value === "string") {
        return { key, title: value, content: data[key] };
      } else {
        const props = key === "prosCons" ? { pros: data.pros, cons: data.cons } : data[key];

        return {
          key,
          title: value.title,
          props,
          Component: value.Component,
        };
      }
    });
  };

  const careerData = generateTabData(tabData, tabConfig);

  const activeTabContent = careerData.find((tab) => tab.key === activeTab);

  return (
    <View style={styles.container}>
      <View style={{ alignItems: "flex-end" }}>{rightButtonComponent ? rightButtonComponent : null}</View>
      <View style={[styles.navBar, { marginTop: flexDirection === "row" ? 2 : -15 }]}>
        <ScrollView horizontal={true} showsHorizontalScrollIndicator={false}>
          {careerData.map((tab) => (
            <Pressable
              key={tab.key}
              style={activeTab === tab.key ? styles.activeTab : styles.tab}
              onPress={() => onTabPress(tab.key)}>
              <CustomText
                key={tab.key}
                style={activeTab === tab.key ? styles.activeText : styles.otherText}
                bold="true"
                text={tab.title}
              />
            </Pressable>
          ))}
        </ScrollView>
      </View>
      <View style={{ flex: 1 }}>
        {isLarge ? (
          <View style={styles.scrollView}>
            {activeTabContent &&
              (activeTab === "prosCons" ? (
                <ProsCons pros={tabData.pros} cons={tabData.cons} />
              ) : (
                <CustomMarkdown content={activeTabContent.content} />
              ))}
          </View>
        ) : (
          <View style={styles.scrollView}>
            {activeTabContent &&
              (activeTab === "prosCons" ? (
                <ProsCons pros={tabData.pros} cons={tabData.cons} />
              ) : (
                <CustomMarkdown content={activeTabContent.content} />
              ))}
          </View>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: 10,
  },
  navBar: {
    flexDirection: "row",
    justifyContent: "flex-start",
    borderBottomWidth: 1,
    borderBottomColor: "#D3D3D3",
    alignItems: "center",
  },
  tab: {
    padding: 15,
  },
  activeTab: {
    padding: 15,
    borderBottomWidth: 2,
    borderBottomColor: "blue",
    color: "blue",
  },

  activeText: {
    color: "blue",
  },
  otherText: {
    color: "#646877",
  },
  scrollView: {
    // height: 20,
    marginTop: 10,
    paddingHorizontal: 10,
  },
});

export default CareerPathNav;
