import { Image, StyleSheet } from "react-native";

function ProfileImage({ gender }) {
  if (gender === "female") {
    return <Image style={styles.image} source={require("../../../assets/female.png")} resizeMode="contain" />;
  } else {
    return <Image style={styles.image} source={require("../../../assets/male.png")} resizeMode="contain" />;
  }
}

const styles = StyleSheet.create({
  image: {
    width: 200,
    height: 200,
    borderRadius: 99,
    backgroundColor: "#fff",
    justifyContent: "center",
    alignItems: "center",
  },
});

export default ProfileImage;
