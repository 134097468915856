import { Svg, Path } from "react-native-svg";

const Redo = () => (
  <Svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M17.8125 10C17.8127 12.054 17.004 14.0254 15.5614 15.4876C14.1188 16.9498 12.1585 17.785 10.1047 17.8125H10C8.00552 17.8167 6.08591 17.0533 4.63906 15.6805C4.45828 15.5097 4.35273 15.2742 4.34562 15.0256C4.33851 14.7771 4.43044 14.5359 4.60117 14.3551C4.7719 14.1743 5.00746 14.0688 5.25602 14.0616C5.50458 14.0545 5.74578 14.1465 5.92656 14.3172C6.77519 15.1184 7.84111 15.6517 8.99112 15.8505C10.1411 16.0493 11.3242 15.9048 12.3926 15.435C13.4609 14.9653 14.3671 14.191 14.998 13.2092C15.6288 12.2273 15.9563 11.0812 15.9395 9.91428C15.9227 8.74733 15.5623 7.61121 14.9035 6.64789C14.2446 5.68458 13.3165 4.93679 12.2351 4.49798C11.1536 4.05916 9.96684 3.94879 8.82303 4.18064C7.67922 4.41249 6.62911 4.97629 5.80391 5.80157C5.79375 5.81173 5.78438 5.82111 5.77344 5.83048L4.28828 7.18751H5.625C5.87364 7.18751 6.1121 7.28628 6.28791 7.4621C6.46373 7.63791 6.5625 7.87637 6.5625 8.12501C6.5625 8.37365 6.46373 8.61211 6.28791 8.78792C6.1121 8.96374 5.87364 9.06251 5.625 9.06251H1.875C1.62636 9.06251 1.3879 8.96374 1.21209 8.78792C1.03627 8.61211 0.9375 8.37365 0.9375 8.12501V4.37501C0.9375 4.12637 1.03627 3.88791 1.21209 3.7121C1.3879 3.53628 1.62636 3.43751 1.875 3.43751C2.12364 3.43751 2.3621 3.53628 2.53791 3.7121C2.71373 3.88791 2.8125 4.12637 2.8125 4.37501V5.99376L4.49062 4.45782C5.58508 3.36925 6.97748 2.6293 8.49209 2.33136C10.0067 2.03341 11.5756 2.19084 13.0008 2.78376C14.4261 3.37668 15.6437 4.37853 16.5001 5.66284C17.3564 6.94716 17.8131 8.45638 17.8125 10Z"
      fill="#667085"
    />
  </Svg>
);

export default Redo;
