import { useNavigation, useRoute } from "@react-navigation/native";
import LearnMainCard from "../../../components/learning/general/LearnMainCard/LearnMainCard";
import getLearningRoute from "../../../utils/getLearningRoute";
import { useGetLearningTreeQuery } from "../../../graphql/generated/graphql";

const LearnCategoryCardListContainer = ({ data, isRecent = false }) => {
  const navigation = useNavigation();
  const route = useRoute();

  const params = route.params;
  const { name, label } = getLearningRoute(params);

  const { refetch } = useGetLearningTreeQuery({
    variables: { level: "Subfield", name: "" },
    skip: true,
  });

  const handleCardPress = async (nodeName, nodeType, parentField) => {
    if (isRecent) {
      try {
        const result = await refetch({ level: "Subfield", name: nodeName });
        if (result.data && result.data.getLearningTree) {
          parentField = result.data.getLearningTree.FieldName;
          navigation.navigate("Tracks", {
            field: parentField,
            subfield: nodeName,
          });
        }
      } catch (fetchError) {
        console.error("Error fetching learning tree data:", fetchError);
      }
    } else {
      navigation.navigate("Tracks", {
        [label?.toLowerCase()]: name,
        [nodeType.toLowerCase()]: nodeName,
      });
    }
  };

  return (
    <>
      {data?.map((node) => {
        return (
          <LearnMainCard
            key={node.name}
            name={node.name}
            onCardPress={() => handleCardPress(node.name, node.label, node?.parentFields)}
            completedTracks={node.completedTracks ?? node.completedUnits}
            tracksCount={node.totalUnits ?? node.totalTracks}
            mastery={node.mastery}
            image={node.imageUrl}
          />
        );
      })}
    </>
  );
};

export default LearnCategoryCardListContainer;
