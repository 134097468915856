import React from "react";
import { ImageBackground, StyleSheet } from "react-native";

const questImages = {
  creativityboost: require("../../../assets/quests/creativityboost.png"),
  dailyselfreflection: require("../../../assets/quests/dailyselfreflection.png"),
  digitaldetox: require("../../../assets/quests/digitaldetox.png"),
  empathypractice: require("../../../assets/quests/empathypractice.png"),
  expressgratitude: require("../../../assets/quests/expressgratitude.png"),
  growthmindset: require("../../../assets/quests/growthmindset.png"),
  introspectivejournaling: require("../../../assets/quests/introspectivejournaling.png"),
  learnsomethingnew: require("../../../assets/quests/learnsomethingnew.png"),
  meditationsession: require("../../../assets/quests/meditationsession.png"),
  mindfuleating: require("../../../assets/quests/mindfuleating.png"),
  musicmoment: require("../../../assets/quests/musicmoment.png"),
  natureconnection: require("../../../assets/quests/natureconnection.png"),
  physicalactivity: require("../../../assets/quests/physicalactivity.png"),
  positiveaffirmation: require("../../../assets/quests/positiveaffirmation.png"),
  randomactofkindness: require("../../../assets/quests/randomactofkindness.png"),
  readachapterofabook: require("../../../assets/quests/readachapterofabook.png"),
  skillbuilding: require("../../../assets/quests/skillbuilding.png"),
  sleepfocus: require("../../../assets/quests/sleepfocus.png"),
  socialconnection: require("../../../assets/quests/socialconnection.png"),
  visionsetting: require("../../../assets/quests/visionsetting.png"),
};

const QuestImageBackground = ({ questTitle, children, style }) => {
  const imageName = questTitle.toLowerCase().replace(/\s/g, "");
  const imageSource = questImages[imageName];

  return (
    <ImageBackground style={[styles.background, style]} source={imageSource} resizeMode="cover">
      {children}
    </ImageBackground>
  );
};

const styles = StyleSheet.create({
  background: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    overflow: "hidden",
  },
});

export default QuestImageBackground;
