import React from "react";
import { View, Text, StyleSheet } from "react-native";
import Logo from "../../general/logos/Logo/Logo";
import CustomTextInput from "../../common/general/CustomTextInput/CustomTextInput";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";
import CustomText from "../../common/general/CustomText/CustomText";
import { DatePickerInput } from "react-native-paper-dates";
import DropdownSelect from "react-native-input-select";
import { Checked, UnChecked } from "../../svgs/common";

function SignupForm({
  name,
  email,
  password,
  confirmPassword,
  birthday,
  gender,
  hasAgreedToTerms,
  isValidName,
  isValidEmail,
  isPasswordMatching,
  errorMessage,
  companionName,
  onNameChangeText,
  onEmailChangeText,
  onPasswordChangeText,
  onConfirmPasswordChangeText,
  onDatePickerChange,
  onGenderValueChange,
  onTermsAgreePress,
  onTermsPress,
  onSubmit,
  onSignInPress,
}) {
  return (
    <View style={styles.container}>
      <Logo name={companionName} height={100} width={280} />
      <CustomSpacing type="vertical" size="m" />

      <CustomText text="Create your free account" textType="display" size="xs" bold />

      <CustomSpacing type="vertical" size="m" />

      <CustomText text="First Name" size="s" />
      <CustomSpacing type="vertical" size="s" />
      <CustomTextInput
        value={name}
        onChangeText={(text) => onNameChangeText(text)}
        placeholder="First Name..."
        style={[!isValidName && styles.textInputError, { backgroundColor: "#F8F8F8", borderColor: "#DEDEDE" }]}
      />
      {!isValidName ? (
        <>
          <CustomSpacing type="vertical" size="xs" />
          <CustomText text="For security, please only provide your first name." size="xs" style={styles.errorText} />
        </>
      ) : (
        <CustomSpacing type="vertical" size="m" />
      )}
      <CustomSpacing type="vertical" size="s" />

      <CustomText text="Email Address" size="s" />
      <CustomSpacing type="vertical" size="s" />
      <CustomTextInput
        value={email}
        onChangeText={(text) => onEmailChangeText(text)}
        placeholder="Email..."
        style={[!isValidEmail && styles.textInputError, { backgroundColor: "#F8F8F8", borderColor: "#DEDEDE" }]}
      />
      {!isValidEmail ? (
        <>
          <CustomSpacing type="vertical" size="xs" />
          <CustomText text="Please enter a valid email." size="xs" style={styles.errorText} />
        </>
      ) : (
        <CustomSpacing type="vertical" size="m" />
      )}
      <CustomSpacing type="vertical" size="s" />

      <CustomText text="Password" size="s" />
      <CustomSpacing type="vertical" size="s" />
      <CustomTextInput
        value={password}
        placeholder="Password..."
        style={[!isPasswordMatching && styles.textInputError, { backgroundColor: "#F8F8F8", borderColor: "#DEDEDE" }]}
        onChangeText={(text) => onPasswordChangeText(text)}
        secureTextEntry
      />
      <CustomSpacing type="vertical" size="m" />
      <CustomSpacing type="vertical" size="s" />

      <CustomText text="Confirm Password" size="s" />
      <CustomSpacing type="vertical" size="s" />
      <CustomTextInput
        value={confirmPassword}
        placeholder="Confirm Password..."
        style={[!isPasswordMatching && styles.textInputError, { backgroundColor: "#F8F8F8", borderColor: "#DEDEDE" }]}
        onChangeText={(text) => onConfirmPasswordChangeText(text)}
        secureTextEntry
      />
      {!isPasswordMatching ? (
        <>
          <CustomSpacing type="vertical" size="xs" />
          <CustomText text="Passwords do not match." size="xs" style={styles.errorText} />
        </>
      ) : (
        <CustomSpacing type="vertical" size="m" />
      )}
      <CustomSpacing type="vertical" size="s" />

      <CustomText text="Birthday" size="s" />
      <DatePickerInput
        locale="en"
        label="Birthday"
        value={birthday}
        onChange={(d) => onDatePickerChange(d)}
        inputMode="start"
        mode="outlined"
        outlineColor={"#D1D5DB"}
        selectionColor={false}
        activeOutlineColor={"blue"}
        inputProps={{
          color: "#000000",
          mode: "outline",
        }}
        theme={{
          colors: "#000000",
          roundness: 8,
          opacity: 1,
        }}
        style={styles.datePicker}
      />
      <CustomSpacing type="vertical" size="m" />
      <CustomSpacing type="vertical" size="s" />

      <CustomText text="Gender" size="s" />
      <CustomSpacing type="vertical" size="s" />
      <DropdownSelect
        placeholder="Select an option..."
        options={[
          { label: "Male", value: "Male" },
          { label: "Female", value: "Female" },
          { label: "Non-Binary", value: "Non-Binary" },
        ]}
        dropdownStyle={styles.genderDropdown}
        selectedValue={gender}
        optionLabel={"label"}
        optionValue={"value"}
        onValueChange={(value) => onGenderValueChange(value)}
        primaryColor={"blue"}
        labelStyle={styles.genderLabel}
        listHeaderComponent={
          <View style={styles.genderDropdownLabel}>
            <CustomText text="Select Your Gender" weight="bold" />
          </View>
        }
      />
      <CustomSpacing type="vertical" size="s" />

      <View style={styles.termsContainer}>
        <CustomButton
          styleType="transparent"
          style={styles.termsButton}
          leftIcon={hasAgreedToTerms ? <Checked /> : <UnChecked />}
          onPress={onTermsAgreePress}
        />
        <Text style={styles.termsText}>
          I agree to the{" "}
          <Text style={styles.termsLink} onPress={onTermsPress}>
            Terms of Service and Privacy Policy.
          </Text>
        </Text>
      </View>

      {errorMessage ? (
        <>
          <CustomSpacing type="vertical" size="s" />
          <CustomText text={errorMessage} size="s" style={styles.errorMessage} />
          <CustomSpacing type="vertical" size="s" />
        </>
      ) : (
        <CustomSpacing type="vertical" size="xl" />
      )}

      <CustomButton text="Sign up" onPress={onSubmit} size="m" styleType="primary" style={styles.signupButton} />

      <CustomSpacing type="vertical" size="l" />

      <View style={styles.footer}>
        <CustomText text="Already a member?" size="s" style={styles.footerText} />
        <CustomButton text="Sign In" styleType="transparent" size="s" onPress={onSignInPress} />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginvertical: "auto",
    width: "100%",
    maxWidth: 600,
  },
  textInputError: {
    borderColor: "red",
    padding: 9,
  },
  errorText: {
    color: "red",
  },
  errorMessage: {
    color: "red",
    textAlign: "center",
  },
  datePicker: {
    borderWidth: 0,
    paddingVertical: 10,
    backgroundColor: "#F8F8F8",
    borderColor: "#000000",
    borderRadius: 10,
    color: "#000000",
    fontSize: 14,
  },
  genderDropdown: {
    backgroundColor: "#F8F8F8",
    paddingHorizontal: 10,
    minHeight: 45,
    borderColor: "#D1D5DB",
  },
  genderDropdownLabel: {
    marginLeft: 10,
  },
  genderLabel: {
    color: "#000000",
    fontSize: 15,
    fontWeight: "500",
  },
  termsContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  termsButton: {
    padding: 0,
    marginRight: 10,
  },
  termsText: {
    fontSize: 14,
  },
  termsLink: {
    color: "#3E68FE",
    textDecorationLine: "underline",
  },
  signupButton: {
    justifyContent: "center",
  },
  footer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  footerText: {
    color: "#6B7280",
  },
});

export default SignupForm;
