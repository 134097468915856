import React from "react";
import { Modal, View, StyleSheet } from "react-native";
import CustomButton from "../CustomButton/CustomButton";
import CustomText from "../CustomText/CustomText";
import { ModalExit } from "../../../svgIcons";

const CustomModal = ({ visible, onRequestClose, children, style, showCloseButton, title, exitFill, onShow }) => {
  return (
    <Modal animationType="fade" transparent={true} visible={visible} onRequestClose={onRequestClose} onShow={onShow}>
      <View style={styles.centeredView}>
        <View style={[styles.modalView, style]}>
          {(title || showCloseButton) && (
            <View style={styles.header}>
              <CustomText text={title} size="s" textType="display" weight="ultraBold" />
              {showCloseButton && (
                <CustomButton
                  styleType="transparent"
                  leftIcon={<ModalExit fill={exitFill} />}
                  onPress={onRequestClose}
                />
              )}
            </View>
          )}
          {children}
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  modalView: {
    backgroundColor: "white",
    borderRadius: 20,
    padding: 20,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 16,
    elevation: 5,
    height: "auto",
    maxWidth: 600,
    width: "auto",
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: 5,
  },
});

export default CustomModal;
