import React from "react";
import { View, StyleSheet } from "react-native";
import CustomText from "../../common/general/CustomText/CustomText";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";
import CustomTextInput from "../../common/general/CustomTextInput/CustomTextInput";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import Logo from "../../general/logos/Logo/Logo";

function LoginForm({
  email,
  password,
  isValidEmail,
  errorMessage,
  companionName,
  onEmailChangeText,
  onPasswordChangeText,
  onSubmit,
  onResetPasswordPress,
  onSignupPress,
}) {
  return (
    <View style={styles.container}>
      <Logo name={companionName} height={100} width={280} />
      <CustomSpacing type="vertical" size="m" />

      <CustomText
        text={"Sign in to " + companionName.charAt(0).toUpperCase() + companionName.slice(1)}
        textType="display"
        size="xs"
        weight="bold"
      />
      <CustomSpacing type="vertical" size="m" />

      <CustomText text="Email Address" size="s" />
      <CustomSpacing type="vertical" size="s" />
      <CustomTextInput
        style={[!isValidEmail && styles.textInputError, { backgroundColor: "#F8F8F8", borderColor: "#DEDEDE" }]}
        value={email}
        placeholder="Email..."
        onChangeText={(text) => onEmailChangeText(text)}
        autoComplete="email"
      />
      {!isValidEmail ? (
        <>
          <CustomSpacing type="vertical" size="xs" />
          <CustomText text="Please enter a valid email." size="xs" style={styles.errorText} />
        </>
      ) : (
        <CustomSpacing type="vertical" size="m" />
      )}
      <CustomSpacing type="vertical" size="s" />

      <CustomText text="Password" size="s" />
      <CustomSpacing type="vertical" size="s" />
      <CustomTextInput
        value={password}
        style={{ backgroundColor: "#F8F8F8", borderColor: "#DEDEDE" }}
        placeholder="Password..."
        onChangeText={(text) => onPasswordChangeText(text)}
        secureTextEntry
      />

      {errorMessage ? (
        <>
          <CustomSpacing type="vertical" size="s" />
          <CustomText text={errorMessage} size="s" style={styles.errorMessage} />
          <CustomSpacing type="vertical" size="s" />
        </>
      ) : (
        <CustomSpacing type="vertical" size="xl" />
      )}

      <CustomButton
        text="Sign in"
        size="m"
        bold={true}
        styleType="primary"
        style={styles.loginButton}
        onPress={onSubmit}
      />
      <CustomSpacing type="vertical" size="l" />

      <View style={styles.footer}>
        <CustomText text="Forgot your password?" size="s" style={styles.footerText} />
        <CustomButton text="Reset Password" styleType="transparent" size="s" onPress={onResetPasswordPress} />
      </View>
      <CustomSpacing type="vertical" size="s" />

      <View style={styles.footer}>
        <CustomText text="Not a member?" size="s" style={styles.footerText} />
        <CustomButton text="Sign Up" styleType="transparent" size="s" onPress={onSignupPress} />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginvertical: "auto",
    width: "100%",
    maxWidth: 600,
  },
  textInputError: {
    borderColor: "red",
    padding: 9,
  },
  errorText: {
    color: "red",
  },
  errorMessage: {
    color: "red",
    textAlign: "center",
  },
  loginButton: {
    justifyContent: "center",
  },
  footer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  footerText: {
    color: "#6B7280",
  },
});

export default LoginForm;
