import { useEffect } from "react";
import { View, StyleSheet } from "react-native";
import { useAppConfig } from "../AppConfigProvider";
import { useNavigation } from "@react-navigation/native";
import BlankLayout from "../layouts/BlankLayout";
import CustomText from "../components/common/general/CustomText/CustomText";
import CustomButton from "../components/common/general/CustomButton/CustomButton";
import Logo from "../components/general/logos/Logo/Logo";
import CustomSpacing from "../components/common/layout/CustomSpacing/CustomSpacing";
import { usePostHog } from "posthog-js/react";
import { useMeQuery } from "../graphql/generated/graphql";
function ThankYouView() {
  const appConfig = useAppConfig();
  const navigate = useNavigation();
  const posthog = usePostHog();
  const { data: meData, loading: meDataLoading } = useMeQuery();

  const companionName = appConfig.companionName;
  const domainKey = appConfig.key;

  const handleContinuePress = () => {
    navigate.navigate("My Day");
  };

  useEffect(() => {
    fbq("track", "Subscribe", {
      value: 1499 / 100,
      currency: "USD",
    });

    if (!meDataLoading) {
      posthog?.capture("Subscription Completed", {
        $set: {
          isPayingUser: true,
          hasFreeTrial: !!meData?.me?.trialEndDate || false,
        },
      });
    }
  }, [meDataLoading]);

  return (
    <BlankLayout>
      <View style={styles.container}>
        <Logo name={domainKey} height={100} width={280} />
        <CustomText
          text={`Thank you for your purchase! ${companionName} is excited to talk to you! \nEnjoy your pro features!`}
          size="l"
          style={styles.text}
        />
        <CustomSpacing type="vertical" size="l" />
        <CustomButton text="Continue" onPress={handleContinuePress} size="l" styleType="primary" />
      </View>
    </BlankLayout>
  );
}

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
  text: {
    textAlign: "center",
  },
});

export default ThankYouView;
