import { View, Text, Linking, StyleSheet } from "react-native";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import CustomModal from "../../common/general/CustomModal/CustomModal";
import CustomText from "../../common/general/CustomText/CustomText";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";
import CustomButton from "../../common/general/CustomButton/CustomButton";

function GoogleConsentModal({ visible, companionName, onClose, onConfirm }) {
  const { isMedium } = useResponsiveScreen();

  return (
    <CustomModal
      visible={visible}
      onRequestClose={onClose}
      showCloseButton={true}
      style={{ width: isMedium ? "50%" : "90%" }}
      title="Google Calendar Consent">
      <CustomText
        text={`We require your consent to access your Google Calendar. This optional access enables ${companionName}, through our AI models and OpenAI as a third party, to assist you with scheduling, reminders, and agenda discussions. Your calendar data will be used solely for these purposes, ensuring an enhanced and personalized experience with ${companionName}.`}
        size="l"
      />
      <CustomSpacing type="vertical" size="l" />

      <Text style={styles.text}>
        Please review our{" "}
        <Text style={[styles.text, styles.link]} onPress={() => Linking.openURL("https://primer.net/Terms-of-Service")}>
          Terms of Service
        </Text>{" "}
        before proceeding.
      </Text>
      <CustomSpacing type="vertical" size="l" />

      <View style={styles.buttonContainer}>
        <CustomButton
          text="Cancel"
          onPress={onClose}
          styleType="primary"
          style={[styles.cancelButton, styles.noShadow]}
        />
        <CustomButton text="Give Consent" onPress={onConfirm} styleType="primary" style={styles.noShadow} />
      </View>
    </CustomModal>
  );
}

const styles = StyleSheet.create({
  text: {
    color: "#474747",
    fontSize: 18,
  },
  link: {
    color: "blue",
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
  },
  noShadow: {
    shadowColor: "transparent",
    elevation: 0,
  },
  cancelButton: {
    backgroundColor: "grey",
  },
});

export default GoogleConsentModal;
