import React from "react";
import { MotiView } from "moti";
import { View, Pressable, StyleSheet } from "react-native";
import CustomText from "../../common/general/CustomText/CustomText";

function SourceCard({ title, description, url }) {
  const handlePress = () => {
    Linking.openURL(url).catch((err) => {
      console.error("Couldn't load page", err);
    });
  };

  return (
    <MotiView
      from={{
        opacity: 0,
        translateY: 20,
      }}
      animate={{
        opacity: 1,
        translateY: 0,
      }}
      delay={1000}
      transition={{ type: "timing", duration: 500 }}>
      <Pressable onPress={handlePress}>
        <View style={styles.card}>
          <CustomText size="xs" weight="medium" text={title} />
          <CustomText size="xs" weight="normal" style={styles.subText} text={description} />
        </View>
      </Pressable>
    </MotiView>
  );
}

const styles = StyleSheet.create({
  card: {
    flex: 1,
    minWidth: 260,
    maxWidth: 260,
    height: 44,
    paddingVertical: 4,
    paddingHorizontal: 12,
    backgroundColor: "#F9FAFB",
    borderRadius: 10,
    marginBottom: 6,
  },
  subText: {
    color: "#667085",
  },
});

export default SourceCard;
