import { View, StyleSheet } from "react-native";
import Logo from "../../general/logos/Logo/Logo";
import CustomText from "../../common/general/CustomText/CustomText";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";
import CustomTextInput from "../../common/general/CustomTextInput/CustomTextInput";
import CustomButton from "../../common/general/CustomButton/CustomButton";

function ResetPasswordForm({
  errorMessage,
  companionName,
  newPassword,
  confirmPassword,
  onNewPasswordChange,
  onConfirmPasswordChange,
  onResetPassword,
}) {
  return (
    <View style={styles.container}>
      <Logo name={companionName} height={55} width={190} />
      <CustomSpacing type="vertical" size="m" />

      <CustomText text="Reset Password" size="s" weight="bold" />
      <CustomSpacing type="vertical" size="m" />

      <CustomText text="Password" size="s" />
      <CustomSpacing type="vertical" size="xs" />
      <CustomTextInput
        style={errorMessage && styles.textInputError}
        value={newPassword}
        placeholder="New Password..."
        onChangeText={(text) => onNewPasswordChange(text)}
        secureTextEntry
      />
      <CustomSpacing type="vertical" size="m" />

      <CustomText text="Confirm Password" size="s" />
      <CustomSpacing type="vertical" size="xs" />
      <CustomTextInput
        style={errorMessage && styles.textInputError}
        value={confirmPassword}
        placeholder="Confirm Password..."
        onChangeText={(text) => onConfirmPasswordChange(text)}
        secureTextEntry
      />
      <CustomSpacing type="vertical" size="m" />

      <CustomButton text="Reset Password" styleType="primary" style={styles.submitButton} onPress={onResetPassword} />
      <CustomSpacing type="vertical" size="m" />

      {errorMessage ? (
        <>
          <CustomSpacing type="vertical" size="s" />
          <CustomText text={errorMessage} size="s" style={styles.errorMessage} />
          <CustomSpacing type="vertical" size="s" />
        </>
      ) : (
        <CustomSpacing type="vertical" size="xl" />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginvertical: "auto",
    width: "100%",
    maxWidth: 600,
  },
  textInputError: {
    borderColor: "red",
  },
  submitButton: {
    justifyContent: "flex-start",
  },
  errorMessage: {
    color: "red",
    textAlign: "center",
  },
});

export default ResetPasswordForm;
