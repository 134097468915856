import { useState, useEffect } from "react";
import { useAppConfig } from "../../../AppConfigProvider";
import { useAppState, actions } from "../../../contexts/AppStateContext";
import AsyncStorage from "@react-native-async-storage/async-storage";
import VerifyEmailForm from "../../../components/auth/VerifyEmailForm/VerifyEmailForm";
import { sendEmailOTP, verifyEmailOTP } from "../../../services";

function VerifyEmailContainer() {
  const appConfig = useAppConfig();
  const { state, dispatch } = useAppState();

  const [otp, setOtp] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [lastSentTime, setLastSentTime] = useState(null);

  const domainKey = appConfig.key;

  const email = state?.meta?.email;

  useEffect(() => {
    if (email) {
      handleSendOTP();
    } else {
      setErrorMessage("Error sending code. Please try again.");
    }
  }, []);

  const handleSendOTP = async () => {
    if (email === "") {
      setErrorMessage("Error sending code. Please try again.");
      return;
    }

    // Check if email is valid
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setErrorMessage("Error sending code. Please try again.");
      return;
    }

    try {
      const token = await AsyncStorage.getItem("token");

      await sendEmailOTP(email, token);
      setErrorMessage("");
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setErrorMessage(error.response.data.error);
      } else {
        setErrorMessage("Error sending code. Please try again.");
      }
    }
  };

  const handleOTPChange = (text) => {
    setOtp(text);
  };

  const handleVerifyOTP = async () => {
    if (otp === "") {
      setErrorMessage("Field must be filled out");
      return;
    }

    // Check if code is valid
    const otpRegex = /^\d{6}$/;
    if (!otpRegex.test(otp)) {
      setErrorMessage("Code must be 6 digits");
      return;
    }

    try {
      const token = await AsyncStorage.getItem("token");
      await verifyEmailOTP(email, otp, token);

      setErrorMessage("");

      dispatch({
        type: actions.SET_META,
        payload: { isEmailVerified: true },
      });
      fbq("track", "SubmitApplication");
      window.location.href = "/"; //TODO BREAKS NATIVE, note: from here maybe go directly to onboarding page
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setErrorMessage(error.response.data.error);
      } else {
        setErrorMessage("Error sending code. Please try again.");
      }
    }
  };

  const handleResendOTP = async () => {
    const canResendOTP = !lastSentTime || Date.now() - lastSentTime > 60000;

    if (!canResendOTP) {
      setErrorMessage("Please wait before requesting a new code.");
      return;
    }

    setErrorMessage("");
    await handleSendOTP();
    setLastSentTime(Date.now());
  };

  return (
    <VerifyEmailForm
      errorMessage={errorMessage}
      companionName={domainKey}
      otp={otp}
      onOTPChange={handleOTPChange}
      onVerifyOTP={handleVerifyOTP}
      onResendOTP={handleResendOTP}
    />
  );
}

export default VerifyEmailContainer;
