import React from "react";
import { View, Pressable, StyleSheet } from "react-native";
import { MotiView } from "moti";
import { RightArrow2, JourneyPathBold } from "../../svgs/common";
import CustomText from "../../common/general/CustomText/CustomText";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import { capitalizeFirstLetterOfEachWord } from "../../../utils/string";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";
import { formatDateAsDayOrFutureDate } from "../../../utils/date";

function DashboardTask({
  title,
  onJourneyPathPress,
  completed,
  priority,
  date,
  isPartOfJourney,
  onTaskPress,
  isJourneyView,
}) {
  const formattedTitle = capitalizeFirstLetterOfEachWord(title);
  const formattedPriority = capitalizeFirstLetterOfEachWord(priority);

  const formattedDate = formatDateAsDayOrFutureDate(new Date(date));

  const getPriorityText = (priority) => {
    switch (priority) {
      case "low":
        return "#3B7C0F"; // Green
      case "medium":
        return "#B54708"; // Yellow
      case "high":
        return "#C01048"; // Red
      default:
        return "#CCC"; // Default color
    }
  };

  const getPriorityColor = (priority) => {
    switch (priority) {
      case "low":
        return "#E3FBCC"; // Green
      case "medium":
        return "#FEF0C7"; // Yellow
      case "high":
        return "#FFE4E8"; // Red
      default:
        return "#CCC"; // Default color
    }
  };

  return (
    <MotiView
      from={{
        opacity: 0,
        translateX: -400,
      }}
      animate={{
        opacity: 1,
        translateX: 0,
      }}
      delay={200}
      transition={{ type: "spring" }}>
      <View
        style={[
          styles.card,
          {
            backgroundColor: getPriorityColor(priority),
          },
        ]}>
        <View styles={styles.column}>
          <View style={styles.wrapper}>
            <View style={styles.roundedLabel}>
              <CustomText
                text={`${formattedPriority} Priority`}
                style={{ color: getPriorityText(priority) }}
                size="xs"
                weight="bold"
              />
            </View>
          </View>
          <CustomSpacing size="s" />
          {!completed && (
            <CustomText
              text={`Task due ${formattedDate}`}
              numberOfLines={2}
              ellipsizeMode="tail"
              size="l"
              style={{ color: getPriorityText(priority) }}
              weight="bold"
            />
          )}
        </View>

        <View style={styles.column}>
          <CustomText
            text={formattedTitle}
            numberOfLines={2}
            ellipsizeMode="tail"
            size="l"
            style={{ color: getPriorityText(priority) }}
            weight="bold"
          />
          <CustomSpacing size="l" />
          <CustomSpacing size="l" />
          <View style={styles.bottomRow}>
            <CustomButton
              text="View task"
              styleType="primaryTransparent"
              onPress={onTaskPress}
              textStyle={[{ color: getPriorityText(priority) }, styles.viewButton]}
              style={{ padding: 0, gap: 3 }}
              rightIcon={<RightArrow2 fill={getPriorityText(priority)} />}
              bold
            />
            <View>
              {isPartOfJourney && !isJourneyView && (
                <Pressable onPress={onJourneyPathPress}>
                  <JourneyPathBold fill={getPriorityText(priority)} />
                </Pressable>
              )}
            </View>
          </View>
        </View>
      </View>
    </MotiView>
  );
}

const styles = StyleSheet.create({
  card: {
    padding: 20,
    borderRadius: 24.391,
    elevation: 5,
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    height: 260,
    width: 245,
    marginRight: 16,
  },
  column: {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "100%",
  },
  bottomRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
    width: "100%",
  },
  roundedLabel: {
    borderRadius: 100,
    paddingHorizontal: 13,
    paddingVertical: 8,
    backgroundColor: "#fff",
  },
  wrapper: {
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    width: "100%",
  },
  viewButton: {
    fontFamily: "Inter",
    fontSize: 12,
    lineHeight: 20,
    fontWeight: 700,
  },
});

export default DashboardTask;
