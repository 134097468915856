import * as Progress from "react-native-progress";

const DEFAULT_UNFILLED_COLOR = "#EAECF0";
const DEFAULT_COLOR = "#5BE068";

const CustomProgressBar = ({
  progress,
  color = DEFAULT_COLOR,
  unfilledColor = DEFAULT_UNFILLED_COLOR,
}: {
  progress: number;
  color: string;
  unfilledColor: string;
}) => {
  return (
    <Progress.Bar
      progress={progress / 100}
      width={null}
      animated
      unfilledColor={unfilledColor}
      color={color}
      borderWidth={0}
      height={8}
    />
  );
};

export default CustomProgressBar;
