import React from "react";
import { StyleSheet, View } from "react-native";
import DashboardTask from "../DashboardTask/DashboardTask";
import { MotiView } from "moti";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";
import CustomText from "../../common/general/CustomText/CustomText";
import ScrollCarousel from "../ScrollCarousel/ScrollCarousel";

function DashboardTaskList({ tasks, onJourneyPathPress, isJourneyView, onTaskPress, onAddTask }) {
  return (
    <MotiView
      from={{
        opacity: 0,
        translateY: 20,
        scale: 0.95,
      }}
      animate={{
        opacity: 1,
        translateY: 0,
        scale: 1,
      }}
      style={{ flex: 1 }}
      delay={0}
      transition={{ type: "timing", duration: 800 }}>
      {tasks.length === 0 ? (
        <MotiView
          from={{
            opacity: 0,
            translateY: 20,
            scale: 0.95,
          }}
          animate={{
            opacity: 1,
            translateY: 0,
            scale: 1,
          }}
          style={{ flex: 1 }}
          delay={1000}
          transition={{ type: "timing", duration: 800 }}>
          <View style={styles.placeholder}>
            <CustomText
              text="No tasks on your list!"
              size="xl"
              weight="medium"
              // style={{ color: "#101828", textAlign: "center" }}
              textType="text"
            />
            <CustomText
              text="Ready to get organized?"
              style={{ color: "#A9A9A9" }}
              size="m"
              weight="normal"
              textType="text"
            />

            <CustomSpacing size="xxl" />
            <CustomButton
              text="Create a task"
              onPress={() => onAddTask()}
              styleType="primary"
              size="s"
              textStyle={styles.addTaskButtonText}
              style={styles.customButton}
            />
          </View>
        </MotiView>
      ) : (
        <ScrollCarousel data={tasks} cardWidth={245} padding={10}>
          {tasks.map((task, index) => (
            <DashboardTask
              key={task.id}
              id={task.id}
              title={task.name}
              onJourneyPathPress={() => onJourneyPathPress(task.journeyId)}
              completed={task.completed}
              priority={task.priority}
              date={task.date}
              index={index}
              isPartOfJourney={task.isPartOfJourney}
              onTaskPress={() => onTaskPress(task.id)}
              isJourneyView={isJourneyView}
            />
          ))}
        </ScrollCarousel>
      )}
    </MotiView>
  );
}

const styles = StyleSheet.create({
  placeholder: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    textAlign: "center",
    backgroundColor: "#F9FAFB",
    padding: 40,
    borderRadius: 20,
  },
  customButton: {
    backgroundColor: "#3E68FE",
    borderRadius: 8,
    paddingVertical: 10,
    paddingHorizontal: 20,
    justifyContent: "center",
    alignItems: "center",
  },
  addTaskButtonText: {
    color: "#FFFFFF",
    fontWeight: "bold",
  },
});

export default DashboardTaskList;
