import React from "react";
import { View, StyleSheet, Text } from "react-native";
import CustomText from "../../../common/general/CustomText/CustomText";
import CustomSpacing from "../../../common/layout/CustomSpacing/CustomSpacing";
import useResponsiveScreen from "../../../../hooks/useResponsiveScreen";

const TrackOverviewLessonsBox = ({ lessons = [] }) => {
  const { isMedium } = useResponsiveScreen();

  return (
    <View style={styles.container}>
      <CustomText
        text={`There ${lessons.length > 1 ? "are" : "is"} ${
          lessons.length
        } lesson${lessons.length > 1 ? "s" : ""} in this Track`}
        style={{ color: "#182230" }}
        size="xs"
        textType="display"
        weight="ultraBold"
      />
      <CustomSpacing type="vertical" size="s" />
      {lessons.map((item, index, array) => (
        <View key={index}>
          <Text>
            <CustomText
              text={`${item.name}: `}
              style={{ color: "#182230" }}
              weight="bold"
              size={isMedium ? "m" : "s"}
            />
            <CustomText text={item.description} style={styles.descriptionText} size={isMedium ? "m" : "s"} />
          </Text>
          {index !== array.length - 1 && <CustomSpacing type="vertical" size={isMedium ? "s" : "m"} />}
        </View>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    padding: 32,
    borderRadius: 10,
    borderColor: "#EAECF0",
    borderWidth: 1,
  },
  descriptionText: {
    color: "#344054",
    fontWeight: 400,
  },
});

export default TrackOverviewLessonsBox;
