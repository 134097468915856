import { useNavigation } from "@react-navigation/native";
import LearnTrackCard from "../../../components/learning/tracks/LearnTrackCard/LearnTrackCard";
import { useMeQuery, useGetLearningTreeQuery } from "../../../graphql/generated/graphql";

const LearnTrackCardListContainer = ({ data }) => {
  const navigation = useNavigation();
  const { data: meData } = useMeQuery({});

  const { refetch } = useGetLearningTreeQuery({
    variables: { level: "Topic", name: "" },
    skip: true,
  });

  const handleCardPress = async (nodeName) => {
    let navigationParams = {};

    const result = await refetch({ level: "Topic", name: nodeName });
    try {
      if (result.data && result.data.getLearningTree) {
        navigationParams = {
          field: result.data.getLearningTree.FieldName,
          subfield: result.data.getLearningTree.SubfieldName,
          chapter: result.data.getLearningTree.ChapterName,
          topic: nodeName,
        };
      }
    } catch (error) {
      console.error("Error fetching learning tree data:", error);
      return;
    }

    const isUserAlreadyEnrolled = meData.me?.enrolledTracks?.includes(nodeName);
    navigation.navigate(isUserAlreadyEnrolled ? "Lesson" : "Track Overview", navigationParams);
  };

  return (
    <>
      {data?.map((node) => (
        <LearnTrackCard
          key={node.name}
          name={node.name}
          description={node?.description}
          onCardPress={() => handleCardPress(node.name)}
          totalTracks={node?.totalTracks ?? node?.totalUnits}
          completedTracks={node?.completedTracks ?? node?.completedUnits}
          isUserEnrolled={meData.me?.enrolledTracks?.includes(node.name)}
          mastery={node?.mastery}
          image={node.imageUrl}
        />
      ))}
    </>
  );
};

export default LearnTrackCardListContainer;
