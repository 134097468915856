import React, { useEffect, useState, useRef, useCallback } from "react";
import { Text, View, Pressable } from "react-native";
import { Svg, Circle, G, Text as SvgText, Path, Rect } from "react-native-svg";
import { useNavigation, useRoute } from "@react-navigation/native";
import * as d3 from "d3";
import { useAppConfig } from "../../../AppConfigProvider";
import getLearningRoute from "../../../utils/getLearningRoute";
import {
  useSetKnowledgeRelevanceMutation,
  FieldsDocument,
  NodeGraphDocument,
} from "../../../graphql/generated/graphql";
import CustomText from "../../../components/common/general/CustomText/CustomText";
import CustomSpacing from "../../../components/common/layout/CustomSpacing/CustomSpacing";

const LearnGraphContainer = ({ isMobile, width, height, data }) => {
  const appConfig = useAppConfig();
  const navigation = useNavigation();
  const route = useRoute();

  const [hoveredNode, setHoveredNode] = useState(null);
  const [isEnterButtonHovered, setIsEnterButtonHovered] = useState(false);
  const [isIgnoreButtonHovered, setIsIgnoreButtonHovered] = useState(false);
  const [nodes, setNodes] = useState([]);
  const [links, setLinks] = useState([]);
  const [translateX, setTranslateX] = useState(0);
  const [translateY, setTranslateY] = useState(0);
  const [scale, setScale] = useState(1);

  const params = route.params;
  const { name, label } = getLearningRoute(params);

  const [isNotRelevant] = useSetKnowledgeRelevanceMutation({
    refetchQueries: [
      {
        query: FieldsDocument,
        variables: { nodeLabel: label, nodeName: name },
      },
      {
        query: NodeGraphDocument,
        variables: { nodeLabel: label, nodeName: name },
      },
    ],
  });

  const companionName = appConfig.companionName;
  const learningFieldColor = appConfig.learningFieldColor;
  const learningSubFieldColor = appConfig.learningSubFieldColor;

  const filteredNodes = nodes;

  const sortedNodes = [...filteredNodes].sort((a, b) => {
    const isAHovered = a.data.id === hoveredNode;
    const isBHovered = b.data.id === hoveredNode;

    if (isAHovered) return 1; // if node A is hovered, push it to the end
    if (isBHovered) return -1; // if node B is hovered, push A before B
    return 0; // if none are hovered, keep their order
  });

  const [isPanning, setIsPanning] = useState(false);
  const lastPanPosition = useRef({ x: 0, y: 0 });

  // Define a configuration object for styling
  const levelStyles = {
    Field: {
      color: learningFieldColor,
      size: 60,
      fontSize: 14,
      fontWeight: "bold",
      fontColor: "#ffffff",
      progressColor: "#5BE068",
    },
    Subfield: {
      color: learningSubFieldColor,
      size: 55,
      fontWeight: "bold",
      fontSize: 14,
      fontColor: "#ffffff",
      progressColor: "#5BE068",
    },
    Topic: {
      color: "#1A8D9D",
      size: 50,
      fontSize: 14,
      fontWeight: "bold",
      fontColor: "#ffffff",
      progressColor: "#5BE068",
    },
    Chapter: {
      color: "#923DAF",
      size: 45,
      fontWeight: "bold",
      fontSize: 10,
      fontColor: "#ffffff",
      progressColor: "#5BE068",
    },
    Subchapter: {
      color: "#F5F8F8",
      size: 40,
      fontWeight: "bold",
      fontSize: 10,
      fontColor: "#131A2D",
      progressColor: "#5BE068",
    },
  };

  const wrapText = (text, maxChar, shouldSlice = true) => {
    const words = text.split(" ");
    let lines = [];
    let line = "";

    words.forEach((word) => {
      if ((line + word).length < maxChar) {
        line += `${word} `;
      } else {
        lines.push(line);
        line = `${word} `;
      }
    });

    lines.push(line);

    return shouldSlice ? lines.slice(0, 3) : lines;
  };

  useEffect(() => {
    if (!data) return;

    let hierarchyData = data;
    if (data.type === "root") {
      hierarchyData = { ...data };
      hierarchyData.children = data.children;
    }

    const root = d3.hierarchy(hierarchyData);
    const nodesArray = root.descendants();
    const maxDepth = d3.max(nodesArray, (d) => d.depth);
    const adjustedHeight = data.type === "root" ? height / (maxDepth + 2) : height / (maxDepth + 3);

    const treeLayout = d3
      .tree()
      .size([width, adjustedHeight])
      .separation((a, b) => (a.parent === b.parent ? 2 : 2.5));

    treeLayout(root);

    // Extract nodes and links
    const linksArray = root.links();

    const simulation = d3
      .forceSimulation(nodesArray)
      .force(
        "link",
        d3
          .forceLink(linksArray)
          .id((d) => d.id)
          .distance(300)
      )
      .force("charge", d3.forceManyBody().strength(-400))
      .force("center", d3.forceCenter(width / 2, height / 2))
      .force("collide", d3.forceCollide(70))
      .force("y", d3.forceY((d) => d.y).strength(1.5))
      .force("x", d3.forceX((d) => d.x).strength(1.5))
      .on("tick", () => {
        setNodes([...nodesArray]);
      });

    for (let i = 0; i < 300; ++i) simulation.tick();
    setNodes(nodesArray);

    setLinks(linksArray);

    return () => simulation.stop();
  }, [data]);

  const handleStart = (e) => {
    e.preventDefault();
    document.body.style.overflow = "hidden";
    setIsPanning(true);

    let clientX, clientY;

    if (e.type === "mousedown") {
      // Mouse event
      clientX = e.clientX;
      clientY = e.clientY;
    } else {
      // Touch event
      clientX = e.touches[0].clientX;
      clientY = e.touches[0].clientY;
    }

    lastPanPosition.current = { x: clientX, y: clientY };
  };

  const handleMove = (e) => {
    if (!isPanning) return;

    let clientX, clientY;

    if (e.type === "mousemove") {
      // Mouse event
      clientX = e.clientX;
      clientY = e.clientY;
    } else {
      // Touch event
      clientX = e.touches[0].clientX;
      clientY = e.touches[0].clientY;
    }

    let dx = clientX - lastPanPosition.current.x;
    let dy = clientY - lastPanPosition.current.y;

    // Invert the movement for both mouse and touch events
    dx = -dx;
    dy = -dy;

    setTranslateX(translateX + dx);
    setTranslateY(translateY + dy);
    lastPanPosition.current = { x: clientX, y: clientY };
  };

  const handleEnd = () => {
    document.body.style.overflow = "";
    setIsPanning(false);
  };

  const handleWheel = (event) => {
    // For web: handle mouse wheel for zooming
    const zoomAmount = event.nativeEvent.deltaY > 0 ? 0.9 : 1.1;
    setScale((prevScale) => Math.min(Math.max(prevScale * zoomAmount, 0.5), 3)); // Limit zoom between 0.5x and 3x
  };

  const handleNodeEnter = useCallback((nodeId) => {
    setHoveredNode(nodeId);
  }, []);

  const handleNodeLeave = useCallback(() => {
    setHoveredNode(null);
  }, []);

  return (
    <View
      className="bg-white rounded-lg shadow-md"
      style={{
        flex: 1,
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}>
      <View
        style={{
          felx: 1,
          // width: 500,
          backgroundColor: "#EAEFFE",
          borderRadius: 12,
          justifyContent: "center",
          alignItems: "center",
          display: "inline-flex",
          padding: isMobile ? "2px" : "10px",
          margin: isMobile ? "1px" : "4px",
          gap: isMobile ? "2.5" : "0",
        }}>
        <CustomSpacing type="horizontal" size="m" />
        <CustomText
          style={{
            textAlign: "center",
            maxWidth: isMobile ? "50px" : "",
          }}
          text={`Simply say 'I want to learn' followed by your topic, and let ${companionName} guide you to the right track!`}
        />
        <CustomSpacing type="horizontal" size="m" />
      </View>
      <View
        style={{
          position: "absolute",
          top: 100,
          right: 10,
          zIndex: 10,
          flexDirection: "column",
        }}>
        <Pressable
          style={{
            backgroundColor: "white",
            paddingTop: 1,
            paddingBottom: 1,
            paddingLeft: 6,
            paddingRight: 6,
            border: "1px solid black",
            borderRadius: 5,
            marginBottom: 10, // Add space between the buttons
          }}
          onPress={() => {
            setScale((prevScale) => Math.min(prevScale * 1.1, 3)); // Zoom In, max 3x
          }}>
          {/* Replace with your own Zoom In Icon */}
          <Text>+</Text>
        </Pressable>

        <Pressable
          style={{
            backgroundColor: "white",
            paddingTop: 1,
            paddingBottom: 1,
            paddingLeft: 6,
            paddingRight: 6,
            border: "1px solid black",
            borderRadius: 5,
          }}
          onPress={() => {
            setScale((prevScale) => Math.max(prevScale * 0.9, 0.5)); // Zoom Out, min 0.5x
          }}>
          {/* Replace with your own Zoom Out Icon */}
          <Text>-</Text>
        </Pressable>
      </View>

      <Svg
        style={{ touchAction: "none", cursor: "grab" }} // Changed the cursor style to 'grab'
        width={"100%"}
        height={height}
        viewBox={`${translateX} ${translateY} ${width / scale} ${height / scale}`}
        {...(!isMobile
          ? {
              onTouchStart: handleStart,
              onTouchMove: handleMove,
              onTouchEnd: handleEnd,
              onMouseDown: handleStart,
              onMouseMove: handleMove,
              onMouseUp: handleEnd,
              onMouseLeave: handleEnd,
              onWheel: handleWheel,
            }
          : {
              onTouchStart: handleStart,
              onTouchMove: handleMove,
              onTouchEnd: handleEnd,
              onMouseDown: handleStart,
              onMouseMove: handleMove,
              onMouseUp: handleEnd,
              onMouseLeave: handleEnd,
              onWheel: handleWheel,
            })}>
        <defs>
          <filter
            id="filter0_dddd_207_5330"
            x="0"
            y="0"
            width="80.52"
            height="80.52"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="3" />
            <feGaussianBlur stdDeviation="3" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_207_5330" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="10" />
            <feGaussianBlur stdDeviation="5" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
            <feBlend mode="normal" in2="effect1_dropShadow_207_5330" result="effect2_dropShadow_207_5330" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="23" />
            <feGaussianBlur stdDeviation="7" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
            <feBlend mode="normal" in2="effect2_dropShadow_207_5330" result="effect3_dropShadow_207_5330" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="41" />
            <feGaussianBlur stdDeviation="8.5" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.01 0" />
            <feBlend mode="normal" in2="effect3_dropShadow_207_5330" result="effect4_dropShadow_207_5330" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect4_dropShadow_207_5330" result="shape" />
          </filter>
        </defs>
        <defs>
          <marker
            id="arrow"
            viewBox="0 0 10 10"
            refX="9"
            refY="5"
            markerWidth="10"
            markerHeight="10"
            orient="auto-start-reverse">
            <path d="M 0 0 L 10 5 L 0 10 z" fill="#84818A" />
          </marker>
        </defs>

        {links
          .filter((link) => link.source.data.type !== "root" && link.target.data.type !== "root")
          .map((link, index) => {
            const isLinkInSearchResults =
              filteredNodes.some((filteredNode) => filteredNode.data.id === link.source.data.id) ||
              filteredNodes.some((filteredNode) => filteredNode.data.id === link.target.data.id);
            const isLinkHovered = hoveredNode === link.source.data.id || hoveredNode === link.target.data.id;
            const linkOpacity = isLinkInSearchResults || isLinkHovered ? 1 : 0;

            const dx = link.target.x - link.source.x;
            const dy = link.target.y - link.source.y;

            const targetRadius = levelStyles[link.target.data.type].size;
            const ringWidth = 10;
            const padding = 5;

            let x2, y2;

            // Determine the closest side of the target node for line attachment
            if (Math.abs(dx) > Math.abs(dy)) {
              // Target is primarily horizontal to source
              if (dx > 0) {
                // Target is to the right
                x2 = link.target.x - targetRadius - ringWidth - padding;
                y2 = link.target.y;
              } else {
                // Target is to the left
                x2 = link.target.x + targetRadius + ringWidth + padding;
                y2 = link.target.y;
              }
            } else {
              // Target is primarily vertical to source
              if (dy > 0) {
                // Target is below
                x2 = link.target.x;
                y2 = link.target.y - targetRadius - ringWidth - padding;
              } else {
                // Target is above
                x2 = link.target.x;
                y2 = link.target.y + targetRadius + ringWidth + padding;
              }
            }

            // Adjust the control points to invert the bend direction
            let controlPointX = dy > 0 ? link.source.x + Math.abs(dx * 0.5) : link.source.x - Math.abs(dx * 0.5);
            let controlPointY = dx > 0 ? link.source.y + Math.abs(dy * 0.5) : link.source.y - Math.abs(dy * 0.5);

            const pathData = `M ${link.source.x} ${link.source.y} Q ${controlPointX} ${controlPointY} ${x2} ${y2}`;

            return (
              <Path
                key={index}
                opacity={linkOpacity}
                d={pathData}
                stroke="#000000"
                fill="none"
                markerEnd="url(#arrow)"
              />
            );
          })}

        {sortedNodes.map((node, index) => {
          const anyNodeHovered = hoveredNode !== null;

          const isNodeHovered = node.data.id === hoveredNode;
          const nodeOpacity = anyNodeHovered ? (isNodeHovered ? 1 : 0.3) : 1;

          if (node.data.type === "root") return null;
          const nodeStyle = levelStyles[node.data.type];
          const lines = wrapText(node.data.name, 15, hoveredNode !== node.data.id);
          const numberOfLines = lines.length;
          const verticalOffset = ((numberOfLines - 1) * 20) / 2;

          const adjustedIconYPosition = 50;

          // Assuming you have a completion percentage for each node
          const completionPercentage = node.data.mastery || 0;
          const baseRingRadius = nodeStyle.size + 5;

          const scalePerLine = 5;

          const adjustedSizeIncrease = numberOfLines * scalePerLine;

          const currentRadius =
            hoveredNode === node.data.id
              ? (nodeStyle.size + adjustedSizeIncrease) * 1.8
              : nodeStyle.size + adjustedSizeIncrease;

          const BUTTON_WIDTH = currentRadius * 0.5; // Adjust to the desired width
          const BUTTON_HEIGHT = currentRadius * 0.3; // Adjust to the desired height

          // X Positions for the buttons. We place one button to the left and one to the right.
          const leftButtonX = -currentRadius + (currentRadius - BUTTON_WIDTH) / 2;
          const rightButtonX = currentRadius - (currentRadius + BUTTON_WIDTH) / 2;

          // Adjust the ring radius based on the current node radius
          const adjustedRingRadius = baseRingRadius + (currentRadius - nodeStyle.size);

          // Calculate the circumference based on the adjusted ring radius
          const adjustedCircumference = 2 * Math.PI * adjustedRingRadius;
          const adjustedOffset = adjustedCircumference * (1 - completionPercentage / 100);

          return (
            <G
              onMouseEnter={() => handleNodeEnter(node.data.id)}
              onMouseLeave={handleNodeLeave}
              style={{ cursor: "pointer" }}
              key={index}
              transform={`translate(${node.x}, ${node.y - 15})`}>
              <Circle
                r={currentRadius}
                fill={hoveredNode === node.data.id ? "#FFFFFF" : nodeStyle.color}
                stroke="#000000"
                strokeWidth={0}
                opacity={nodeOpacity}
              />

              {node.data.type !== "Field" ? (
                <>
                  <Circle
                    r={adjustedRingRadius}
                    fill="transparent"
                    stroke="#ECF0F2"
                    strokeWidth={10}
                    opacity={nodeOpacity}
                  />

                  <Circle
                    r={adjustedRingRadius}
                    fill="transparent"
                    stroke={nodeStyle.progressColor}
                    strokeWidth={3}
                    strokeDasharray={adjustedCircumference}
                    strokeDashoffset={adjustedOffset}
                    opacity={nodeOpacity}
                  />
                </>
              ) : (
                <Circle
                  r={adjustedRingRadius}
                  fill={hoveredNode === node.data.id ? "#FFFFFF" : nodeStyle.color}
                  stroke="#E5E5E5"
                  strokeWidth={1}
                  opacity={nodeOpacity}
                />
              )}

              {lines.map((line, lineIndex) => (
                <SvgText
                  dy={lineIndex * 20 - verticalOffset}
                  fontSize={hoveredNode === node.data.id ? nodeStyle.fontSize * 1.5 : nodeStyle.fontSize}
                  fontFamily="Inter"
                  fontWeight={nodeStyle.fontWeight}
                  lineheight={"109%"}
                  fill={hoveredNode === node.data.id ? learningFieldColor : nodeStyle.fontColor}
                  textAnchor="middle"
                  opacity={nodeOpacity}
                  key={lineIndex}>
                  {hoveredNode === node.data.id ? line : line.trim()}
                </SvgText>
              ))}

              {hoveredNode === node.data.id ? (
                <>
                  <G
                    transform={`translate(${leftButtonX}, ${adjustedIconYPosition})`}
                    onClick={async () => {
                      await isNotRelevant({
                        variables: {
                          nodeName: node.data.name,
                          nodeLabel: node.data.type,
                          isRelevant: false,
                        },
                      });
                    }}
                    style={{ cursor: "pointer" }}>
                    <Rect
                      x={0}
                      y={-BUTTON_HEIGHT / 2}
                      width={BUTTON_WIDTH}
                      height={BUTTON_HEIGHT}
                      rx={10}
                      ry={10}
                      fill={isIgnoreButtonHovered ? "#D0D4D7" : "#ECF0F2"}
                      onMouseEnter={() => setIsIgnoreButtonHovered(true)}
                      onMouseLeave={() => setIsIgnoreButtonHovered(false)}
                    />
                    {/* eslint-disable react-native/no-raw-text */}
                    <SvgText
                      x={BUTTON_WIDTH / 2}
                      y={0}
                      textAnchor="middle"
                      fontSize="9"
                      fontWeight={800}
                      fill="red"
                      alignmentBaseline="middle"
                      onMouseEnter={() => setIsIgnoreButtonHovered(true)}
                      onMouseLeave={() => setIsIgnoreButtonHovered(false)}>
                      Hide
                    </SvgText>
                    {/* eslint-enable react-native/no-raw-text */}
                  </G>

                  <G
                    transform={`translate(${rightButtonX}, ${adjustedIconYPosition})`}
                    onClick={async () => {
                      if (node.data.type === "Field" && !node.parent) return;
                      if (node.parent && node.children) return;

                      if (node.data.type !== "Subchapter") {
                        if (node.children && node.children.length > 0) {
                          const array = ["chapter", "topic", "subfield", "field"];

                          let newParams = {
                            ...params,
                            [node.data.type.toLowerCase()]: node.data.name,
                          };

                          for (let item of array) {
                            if (Object.keys(params).includes(item)) {
                              delete newParams[item];
                              break;
                            }
                          }

                          navigation.navigate("Categories", newParams);
                        }

                        if (!node.children && node.parent) {
                          navigation.navigate("Categories", {
                            ...params,
                            [node.data.type.toLowerCase()]: node.data.name,
                          });
                        }
                      } else {
                        navigation.navigate("Lesson", {
                          ...params,
                          [node.data.type.toLowerCase()]: node.data.name,
                        });
                      }
                    }}
                    style={{ cursor: "pointer" }}>
                    <Rect
                      x={0}
                      y={-BUTTON_HEIGHT / 2}
                      width={BUTTON_WIDTH}
                      height={BUTTON_HEIGHT}
                      rx={10}
                      ry={10}
                      fill={isEnterButtonHovered ? "#D0D4D7" : "#ECF0F2"}
                      onMouseEnter={() => setIsEnterButtonHovered(true)}
                      onMouseLeave={() => setIsEnterButtonHovered(false)}
                    />
                    {/* eslint-disable react-native/no-raw-text */}
                    <SvgText
                      x={BUTTON_WIDTH / 2}
                      y={0}
                      textAnchor="middle"
                      fontSize="9"
                      fontWeight={800}
                      fill={learningFieldColor}
                      alignmentBaseline="middle"
                      onMouseEnter={() => setIsEnterButtonHovered(true)}
                      onMouseLeave={() => setIsEnterButtonHovered(false)}>
                      Enter
                    </SvgText>
                    {/* eslint-enable react-native/no-raw-text */}
                  </G>
                </>
              ) : (
                <></>
              )}
            </G>
          );
        })}
      </Svg>
    </View>
  );
};

export default LearnGraphContainer;
