import React from "react";
import { View, StyleSheet } from "react-native";
import CustomText from "../../common/general/CustomText/CustomText";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";
import CustomButton from "../../common/general/CustomButton/CustomButton";

function IntegrationCard({ Icon, header, body, isConnected, onConnect }) {
  return (
    <View style={styles.container}>
      <View>
        <Icon />
      </View>
      <CustomSpacing type="horizontal" size="l" />
      <View style={styles.textContainer}>
        <CustomText text={header} size="m" weight="bold" />
        <CustomSpacing type="vertical" size="s" />
        <CustomText text={body} size="xs" numberOfLines={1} ellipsizeMode="tail" />
      </View>

      <CustomButton
        text={isConnected ? "Connected" : "Connect Account"}
        styleType="transparent"
        onPress={onConnect}
        textStyle={isConnected ? styles.connectedButtonText : styles.connectButtonText}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 20,
    borderRadius: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.04,
    shadowRadius: 3.84,
    elevation: 5,
    flexShrink: 1,
  },
  textContainer: {
    flexShrink: 1,
  },
  connectedButtonText: {
    color: "#209B51",
  },
  connectButtonText: {
    color: "#3E68FE",
  },
});

export default IntegrationCard;
