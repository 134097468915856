import React, { useState, useMemo } from "react";
import { getWelcomeOnboardingModalData } from "../../../constants/onboarding";
import { useMeQuery, useUpdateUserModalOnboardingMutation } from "../../../graphql/generated/graphql";
import OnboardingModal from "../../../components/onboarding/OnboardModal/OnboardModal";
import { useAppConfig } from "../../../AppConfigProvider";
import { getEdlinkLoginUrl } from "../../../services";
import handleOpenLink from "../../../utils/handleOpenLink";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Platform } from "react-native";

const OnboardModalContainer = ({ visible, onClose }) => {
  const appConfig = useAppConfig();
  const isLMS = appConfig.isLMS || false;
  const companionName = appConfig.companionName;
  const contactEmail = appConfig.bugReportEmails;
  const [modalIndex, setModalIndex] = useState(1);
  const [updateModalOnboarding, loading] = useUpdateUserModalOnboardingMutation();
  const { data } = useMeQuery();

  const modalsData = useMemo(() => getWelcomeOnboardingModalData(data?.me?.name, companionName), [modalIndex, data]);

  const currentModalData = modalsData[`step${modalIndex}`];

  const numberOfSteps = Object.keys(modalsData).length;

  const ctaButtonText = modalIndex === numberOfSteps ? "Done" : `Next (${modalIndex}/${numberOfSteps})`;

  const updateModalOnboardingFunction = async () => {
    const modalOnboarding = data?.me?.modal_onboarding;

    await updateModalOnboarding({
      variables: {
        modal_onboarding: modalOnboarding ? modalOnboarding + ",welcome" : "welcome",
      },
    });
  };

  const handleConnectSchool = async () => {
    let newWindow;
    if (Platform.OS === "web") {
      newWindow = window.open("", "_blank");
    }

    const token = await AsyncStorage.getItem("token");

    getEdlinkLoginUrl(token).then(async (response) => {
      const url = response.loginUrl;
      await handleOpenLink(url, newWindow);
    });
  };

  const handleNext = async () => {
    if (modalIndex === numberOfSteps) {
      onClose();
      await updateModalOnboardingFunction();
      return;
    }

    setModalIndex((prevIndex) => prevIndex + 1);
  };

  return (
    <>
      {isLMS ? (
        <OnboardingModal
          visible={visible}
          title={"Connect to your LMS"}
          description={
            "By connecting to your school's Learning Management System, you can access your courses and assignments directly from Primer. This is a mandatory step for unlocking your full account. If you have any issues connecting, please contact us at " +
            contactEmail +
            "."
          }
          onCtaClick={handleConnectSchool}
          image={currentModalData?.image}
          ctaButtonText={"Connect"}
          aspectRatio={currentModalData?.aspectRatio}
          height={currentModalData?.imageHeight}
          loading={loading?.loading}
        />
      ) : (
        <OnboardingModal
          visible={visible}
          title={currentModalData?.title}
          description={currentModalData?.description}
          onCtaClick={handleNext}
          image={currentModalData?.image}
          ctaButtonText={ctaButtonText}
          aspectRatio={currentModalData?.aspectRatio}
          height={currentModalData?.imageHeight}
          loading={loading?.loading}
        />
      )}
    </>
  );
};

export default OnboardModalContainer;
