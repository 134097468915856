import apiClient from "./ApiService";

const searchWeb = async (query, token) => {
  try {
    const response = await apiClient.get("/imgSearch", {
      params: { q: query },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error performing image search:", error);
    throw error;
  }
};

export { searchWeb };
