import React, { useRef, useEffect, useState } from "react";
import { View, StyleSheet, Pressable } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { RightIcon } from "../../svgs/common";
import { LeftIcon } from "../../svgs/common";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";

const ScrollCarousel = ({ children, cardWidth, padding, data }) => {
  const totalAssignments = data.length;
  const scrollViewRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [rightButtonVisible, setRightButtonVisible] = useState(true);
  const [leftButtonVisible, setLeftButtonVisible] = useState(false);
  const { isLarge } = useResponsiveScreen();
  const [nextButton, setnextButton] = useState(false);
  const [prevButton, setprevButton] = useState(false);

  useEffect(() => {
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollEnabled = true;
      scrollViewRef.current.scrollTo({ x: 0, animated: false });
      if (isLarge) {
        scrollViewRef.current.scrollEnabled = false;
      }
    }

    if (totalAssignments <= 4) {
      setRightButtonVisible(false);
      setLeftButtonVisible(false);
    }
  }, []);

  useEffect(() => {
    if (totalAssignments - 1 > 3) {
      setCurrentIndex(0);
    }
  }, [totalAssignments]);

  useEffect(() => {
    if (isLarge) {
      setCurrentIndex(0);
      scrollViewRef.current.scrollTo({ x: 0, animated: true });
    }
  }, [isLarge]);

  useEffect(() => {
    if (nextButton) {
      if (totalAssignments - 1 - currentIndex >= 3 && totalAssignments - 1 - currentIndex <= 5) {
        scrollViewRef.current.scrollToEnd({ animated: true });
        if (!leftButtonVisible) {
          setLeftButtonVisible(true);
        }

        if (rightButtonVisible) {
          setRightButtonVisible(false);
        }
      } else {
        const position = currentIndex * (cardWidth + padding);
        scrollViewRef.current.scrollTo({ x: position, animated: true });
        if (!leftButtonVisible) {
          setLeftButtonVisible(true);
        }
        if (!rightButtonVisible) {
          setRightButtonVisible(true);
        }
      }
    } else if (prevButton) {
      if (currentIndex > 0) {
        const position = currentIndex * (cardWidth + padding);
        scrollViewRef.current.scrollTo({ x: position, animated: true });
        if (!leftButtonVisible) {
          setLeftButtonVisible(true);
        }
        if (!rightButtonVisible) {
          setRightButtonVisible(true);
        }
      } else {
        scrollViewRef.current.scrollTo({ x: 0, animated: true });

        if (leftButtonVisible) {
          setLeftButtonVisible(false);
        }
        if (!rightButtonVisible) {
          setRightButtonVisible(true);
        }
      }
    }
  }, [currentIndex, nextButton, prevButton]);

  const nextCard = () => {
    if (isLarge) {
      setnextButton(true);
      setprevButton(false);

      if (totalAssignments - 1 - currentIndex >= 3 && totalAssignments - 1 - currentIndex <= 5) {
        setCurrentIndex(currentIndex);
      } else if (totalAssignments - 1 - currentIndex > 5) {
        setCurrentIndex(currentIndex + 3);
      }
    }
  };

  const prevCard = () => {
    if (isLarge) {
      setprevButton(true);
      setnextButton(false);
      if (currentIndex >= 3) {
        setCurrentIndex(currentIndex - 3);

        if (!leftButtonVisible) {
          setLeftButtonVisible(true);
        }
        if (!rightButtonVisible) {
          setRightButtonVisible(true);
        }
      } else {
        setCurrentIndex(0);
      }
    }
  };

  return (
    <View style={styles.container}>
      <ScrollView
        ref={scrollViewRef}
        contentContainerStyle={styles.scrollView}
        horizontal={true}
        scrollEnabled={isLarge ? false : true}
        showsHorizontalScrollIndicator={false}>
        {children}
      </ScrollView>
      {isLarge && leftButtonVisible && (
        <Pressable style={styles.prevButton} onPress={prevCard}>
          <LeftIcon style={styles.buttonText} />
        </Pressable>
      )}

      {isLarge && rightButtonVisible && (
        <Pressable style={styles.nextButton} onPress={nextCard}>
          <RightIcon style={styles.buttonText} />
        </Pressable>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
  },
  scrollView: {
    // paddingHorizontal: 10,
  },
  nextButton: {
    position: "absolute",
    right: -12,
    top: "50%",
    transform: [{ translateY: -25 }],

    justifyContent: "center",
    alignItems: "center",
  },

  buttonText: {
    color: "white",
    fontSize: 24,
  },
  prevButton: {
    position: "absolute",
    left: -12,
    top: "50%",
    transform: [{ translateY: -25 }],

    justifyContent: "center",
    alignItems: "center",
  },
});

export default ScrollCarousel;
