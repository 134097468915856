import { View, StyleSheet } from "react-native";
import CustomText from "../../common/general/CustomText/CustomText";
function UserMessage({ text }) {
  return (
    <View style={styles.bubble}>
      <CustomText text={text} style={styles.text} />
    </View>
  );
}

const styles = StyleSheet.create({
  bubble: {
    backgroundColor: "#E0E7FE",
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    borderBottomRightRadius: 12,
    paddingHorizontal: 15,
    paddingVertical: 12,
    alignSelf: "flex-start",
    flexShrink: 1,
  },
  text: {
    lineHeight: 24,
  },
});

export default UserMessage;
