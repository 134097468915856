import React, { useState } from "react";
import { View } from "react-native";
import LinearGradient from "react-native-web-linear-gradient";
import Animated, { Extrapolate, interpolate, useAnimatedStyle } from "react-native-reanimated";
import Carousel from "react-native-reanimated-carousel";
import CustomText from "../../common/general/CustomText/CustomText";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";

const colors = ["#fda282", "#fdba4e", "#800015"];

const contentItems = [
  {
    title: "Explore countless learning paths",
    subtitle:
      "Explore and uncover exciting learning paths with Primer. Delight in discovering journeys that inspire your personal and professional growth.",
    source: require("../../../assets/informational/doors.jpg"),
  },
  {
    title: "A personalized experience unique to you",
    subtitle:
      "The more you chat with Primer, the more personalized your experience becomes. Primer fine-tunes its conversations, weaving in details that resonate uniquely with you.",
    source: require("../../../assets/informational/adventure.jpg"),
  },
  {
    title: "Stay Healthy, Stay Motivated",
    subtitle:
      "Track fitness goals and receive personalized workouts and meal plans with Primer. Your key to a balanced lifestyle, blending health with learning and growth.",
    source: require("../../../assets/informational/health.jpg"),
  },
];

const isValidSize = (size) => {
  // "worklet"; // This is specific to react-native-reanimated worklets. You can omit it if not using worklets.
  return size && size.width > 0 && size.height > 0;
};

const defaultAnchorPoint = { x: 0.5, y: 0.5 };

const withAnchorPoint = (transform, anchorPoint, size) => {
  // "worklet"; // This is specific to react-native-reanimated worklets. You can omit it if not using worklets.

  if (!isValidSize(size)) return transform;

  let injectedTransform = transform.transform;
  if (!injectedTransform) return transform;

  if (anchorPoint.x !== defaultAnchorPoint.x && size.width) {
    const shiftTranslateX = [];

    // shift before rotation
    shiftTranslateX.push({
      translateX: size.width * (anchorPoint.x - defaultAnchorPoint.x),
    });
    injectedTransform = [...shiftTranslateX, ...injectedTransform];
    // shift after rotation
    injectedTransform.push({
      translateX: size.width * (defaultAnchorPoint.x - anchorPoint.x),
    });
  }

  if (anchorPoint.y !== defaultAnchorPoint.y && size.height) {
    const shiftTranslateY = [];
    // shift before rotation
    shiftTranslateY.push({
      translateY: size.height * (anchorPoint.y - defaultAnchorPoint.y),
    });
    injectedTransform = [...shiftTranslateY, ...injectedTransform];
    // shift after rotation
    injectedTransform.push({
      translateY: size.height * (defaultAnchorPoint.y - anchorPoint.y),
    });
  }

  return { transform: injectedTransform };
};

function MainCarousel() {
  const { hp, wp } = useResponsiveScreen();
  const [isAutoPlay] = useState(true);

  const baseOptions = {
    vertical: false,
    width: wp(60),
    height: hp(80) - 120,
  };

  return (
    <>
      <LinearGradient
        // Button Linear Gradient
        start={{ x: 0, y: 0 }}
        end={{ x: 1, y: 1 }}
        colors={["#020C30", "#031655"]}
        style={{ flex: 1 }}>
        <CustomSpacing type="vertical" size="l" />
        <CustomSpacing type="vertical" size="l" />
        <View
          style={{
            justifyContent: "center",
            alignItems: "center",
            paddingHorizontal: 30,
          }}>
          <CustomText
            text="Meet Your AI Companion for Personal & Professional Growth"
            style={{ textAlign: "center" }}
            textType="display"
            size="xs"
            styleType="light"
          />
        </View>
        {/* <CustomSpacing type="vertical" size="l" /> */}
        <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
          <Carousel
            {...baseOptions}
            loop
            autoPlay={isAutoPlay}
            withAnimation={{
              type: "spring",
              config: {
                damping: 13,
              },
            }}
            autoPlayInterval={8000}
            data={colors}
            renderItem={({ index, animationValue }) => (
              <Card animationValue={animationValue} key={index} index={index} />
            )}
          />
        </View>
      </LinearGradient>
    </>
  );
}

const Card = ({ index, animationValue }) => {
  const { hp, wp } = useResponsiveScreen();
  const WIDTH = wp(80) / 1.5;
  const HEIGHT = hp(80) / 1.5;

  const cardStyle = useAnimatedStyle(() => {
    const scale = interpolate(animationValue.value, [-0.1, 0, 1], [0.95, 1, 1], Extrapolate.CLAMP);

    const translateX = interpolate(animationValue.value, [-1, -0.2, 0, 1], [0, WIDTH * 0.3, 0, 0]);

    const transform = {
      transform: [
        { scale },
        { translateX },
        { perspective: 200 },
        {
          rotateY: `${interpolate(animationValue.value, [-1, 0, 0.4, 1], [30, 0, -25, -25], Extrapolate.CLAMP)}deg`,
        },
      ],
    };

    return {
      ...withAnchorPoint(transform, { x: 0.5, y: 0.5 }, { width: WIDTH, height: HEIGHT }),
    };
  }, [index]);

  return (
    <Animated.View
      style={{
        // flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}>
      <Animated.View
        style={[
          {
            // flex: 1,
          },
          cardStyle,
        ]}
      />

      <Animated.Image
        source={contentItems[index].source}
        style={[
          {
            width: WIDTH / 1.5,
            height: HEIGHT,
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
            justifyContent: "center",
            alignItems: "center",
            maxWidth: 800,
            zIndex: 999,
          },
          cardStyle,
        ]}
        resizeMode={"cover"}
      />
      <Animated.View
        style={[
          {
            // flex: 1,
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row",
            width: WIDTH / 1.49,
            maxWidth: 800,
            border: "1px solid #010A28",
            backgroundColor: "#010A28",
            paddingVertical: 20,
            paddingHorizontal: 30,
            borderBottomLeftRadius: 20,
            borderBottomRightRadius: 20,
          },
          cardStyle,
        ]}>
        <View style={{ flex: 3 }}>
          <CustomText
            size="xs"
            style={{ textAlign: "left" }}
            textType="display"
            text={contentItems[index].title}
            styleType="light"
          />
        </View>
        <CustomSpacing type="horizontal" size="s" />
        <View style={{ flex: 7 }}>
          <CustomText
            style={{ fontFamily: "MontrealBook", textAlign: "left" }}
            size="m"
            text={contentItems[index].subtitle}
            styleType="light"
          />
        </View>
      </Animated.View>
    </Animated.View>
  );
};

export default MainCarousel;
