import { Svg, G, Path, Circle } from "react-native-svg";

const ToggleOn = () => (
  <Svg width="40" height="28" viewBox="0 0 44 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M0 14C0 8.47715 4.47715 4 10 4H30C35.5228 4 40 8.47715 40 14C40 19.5228 35.5228 24 30 24H10C4.47715 24 0 19.5228 0 14Z"
      fill="#3E68FE"
    />
    <G filter="url(#filter0_d_557_2993)">
      <Circle cx="30" cy="14" r="8" fill="white" />
    </G>
  </Svg>
);

export default ToggleOn;
