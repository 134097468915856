import { useState, useEffect } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { MotiView } from "moti";
import { listPages, listAssignments } from "../../../services";
import ItemCardList from "../../../components/lms/ItemCardList/ItemCardList";
import { useNavigation } from "@react-navigation/native";
import Loader from "../../../components/common/status/Loader/Loader";
import CustomSpacing from "../../../components/common/layout/CustomSpacing/CustomSpacing";
import CustomText from "../../../components/common/general/CustomText/CustomText";
import { Linking, View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";

function ResourceCardListContainer({ integrationId, courseId, isGoogle }) {
  const [resources, setResources] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigation = useNavigation();

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const token = await AsyncStorage.getItem("token");
      let pages = [];
      let assignments = [];

      try {
        pages = await listPages(token, integrationId, courseId);
      } catch (error) {
        // trigger error on sentry
        console.log("Failed to fetch pages:", error);
      }

      try {
        assignments = await listAssignments(token, integrationId, courseId);
      } catch (error) {
        onError(true);
        setIsLoading(false);
        return;
      }

      const pagesWithType = pages.map((page) => ({ ...page, type: "page" }));
      const assignmentsWithType = assignments.map((assignment) => ({
        ...assignment,
        type: "assignment",
      }));

      let combinedResources = [...pagesWithType, ...assignmentsWithType];

      // Sort by updated_date, if no updated_date, put at the end
      combinedResources.sort((a, b) => {
        if (!a.updated_date && !b.updated_date) return 0;
        if (!a.updated_date) return 1;
        if (!b.updated_date) return -1;
        return new Date(b.updated_date) - new Date(a.updated_date);
      });

      setResources(combinedResources);
      setIsLoading(false);
    };

    fetchData();
  }, [integrationId, courseId, isGoogle]);

  const handlePress = (id, externalLink) => {
    if (externalLink) {
      Linking.openURL(externalLink).catch((err) => {
        console.error("Couldn't load page", err);
      });
      return;
    }

    const resource = resources.filter((resource) => resource.id === id)[0];

    navigation.navigate("Resource", {
      resource_type: resource.type,
      resource_id: resource.id,
      course_id: courseId,
      integration_id: integrationId,
    });
  };

  return (
    <MotiView
      from={{ opacity: 0, translateY: 20 }}
      animate={{ opacity: 1, translateY: 0 }}
      delay={500}
      transition={{ type: "timing", duration: 300 }}
      style={{ flex: 1 }}>
      {isLoading ? (
        <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
          <Loader />
          <CustomSpacing type="vertical" size="s" />
          <CustomText text="Loading..." size="m" />
        </View>
      ) : (
        <ScrollView style={{ flex: 1 }}>
          <ItemCardList items={resources} onPress={handlePress} />
        </ScrollView>
      )}
    </MotiView>
  );
}

export default ResourceCardListContainer;
