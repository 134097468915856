export const formatDate = (dateString, isDueDate = true) => {
  if (!dateString) {
    return isDueDate ? "No Due Date" : "No Date";
  }
  // Parse the date string into a Date object
  const date = new Date(dateString);
  // Create an array of month names to convert month number to month name
  const monthNames = [
    "January,",
    "February,",
    "March,",
    "April,",
    "May,",
    "June,",
    "July,",
    "August,",
    "September,",
    "October,",
    "November,",
    "December,",
  ];
  // Construct the formatted date string
  const formattedDate = `${isDueDate ? "" : ""} ${monthNames[date.getMonth()]} ${date.getDate()} ${date.getFullYear()}`;

  return formattedDate;
};

export function formatDateAsDayOrFutureDate(date) {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);

  const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

  // Remove the time portion of the dates for accurate comparison
  const todayStart = new Date(today.getFullYear(), today.getMonth(), today.getDate());
  const tomorrowStart = new Date(tomorrow.getFullYear(), tomorrow.getMonth(), tomorrow.getDate());
  const dateStart = new Date(date.getFullYear(), date.getMonth(), date.getDate());

  if (dateStart.getTime() === todayStart.getTime()) {
    return "today";
  } else if (dateStart.getTime() === tomorrowStart.getTime()) {
    return "tomorrow";
  } else {
    const differenceInDays = (dateStart - todayStart) / (1000 * 60 * 60 * 24);
    if (differenceInDays >= 0 && differenceInDays <= 7) {
      return daysOfWeek[dateStart.getDay()];
    }
  }

  return date.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });
}
