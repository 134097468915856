import React from "react";
import { StyleSheet, View } from "react-native";
import JourneyCard from "../JourneyCard/JourneyCard";

const JourneyCardList = ({ journeyCardData, onPress }) => {
  function formatDateString(dateString) {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  }

  return (
    <View>
      <View style={styles.container}>
        {journeyCardData.map((card) => (
          <JourneyCard
            onPress={onPress}
            id={card.id}
            key={card.id}
            title={card.name}
            completedTasks={card.completedTasks}
            totalTasks={card.totalTasks}
            description={card.description}
            started={formatDateString(card.created)}
            expectedCompletion={formatDateString(card.dueDate)}
            url={card.url}
          />
        ))}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "flex-start",
  },
});

export default JourneyCardList;
