import React from "react";
import { View, Pressable, StyleSheet } from "react-native";

import CustomText from "../../common/general/CustomText/CustomText";
import { formatDate } from "../../../utils/date";

function AnnouncementCard({ text, createdDate, onPress }) {
  return (
    <View style={styles.card}>
      <Pressable style={styles.infoContainer} onPress={onPress}>
        <View style={styles.rightColContainer}>
          <CustomText text={text} numberOfLines={3} ellipsizeMode="tail" size="m" />
        </View>
        <CustomText text={formatDate(createdDate, false)} size="s" />
      </Pressable>
    </View>
  );
}

const styles = StyleSheet.create({
  card: {
    flex: 1,
    flexDirection: "column",
    padding: 20,
    backgroundColor: "#fff",
    borderTopColor: "#D9D9D9",
    borderTopWidth: 1,
    borderBottomColor: "#D9D9D9",
    borderBottomWidth: 1,
  },
  rightColContainer: {
    flexDirection: "column",
    maxWidth: "70%",
  },
  infoContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
});

export default AnnouncementCard;
