import React, { createContext, useState, useEffect, useContext, useRef } from "react";
import { Audio as ExpoAudio } from "expo-av";
import * as FileSystem from "expo-file-system";
import { Platform } from "react-native";
import { useAppState } from "../contexts/AppStateContext";
import useResponsiveScreen from "../hooks/useResponsiveScreen";

const AudioPlayerContext = createContext();

export const useAudioPlayer = () => useContext(AudioPlayerContext);

export const AudioPlayerProvider = ({ children }) => {
  const { isMedium } = useResponsiveScreen();

  return (
    <AudioPlayerContext.Provider value={{}}>
      {!isMedium ? <AudioPlayerMobile /> : <AudioPlayerDesktop />}
      {children}
    </AudioPlayerContext.Provider>
  );
};

const AudioPlayerDesktop = () => {
  const { state } = useAppState();
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioQueue, setAudioQueue] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [lastIndex, setLastIndex] = useState(100000);
  const soundObject = useRef(new ExpoAudio.Sound()).current;
  const webAudioRef = useRef(null);

  useEffect(() => {
    playNextAudio();
  }, [audioQueue, currentIndex]);

  const playNextAudio = async () => {
    if (audioQueue.length === 0 || isPlaying || audioQueue.length <= currentIndex) {
      return;
    }

    setIsPlaying(true);

    const onAudioEnd = () => {
      setIsPlaying(false);
      setCurrentIndex((previousIndex) => previousIndex + 1);
      if (currentIndex >= lastIndex) {
        setAudioQueue([]);
        setCurrentIndex(0);
      }
    };

    if (Platform.OS === "web") {
      const audioSrc = "data:audio/mp3;base64," + audioQueue[currentIndex];
      const audio = new Audio(audioSrc);
      webAudioRef.current = audio;
      webAudioRef.current.muted = !state.meta.isAudioOn;
      audio.onended = onAudioEnd;
      await audio.play();
    } else {
      const uri = `${FileSystem.documentDirectory}audio.mp3`;
      await FileSystem.writeAsStringAsync(uri, audioQueue[currentIndex], {
        encoding: FileSystem.EncodingType.Base64,
      });

      try {
        await soundObject.current.unloadAsync();
        await soundObject.current.loadAsync({ uri });
        soundObject.current.setOnPlaybackStatusUpdate((status) => {
          if (status.didJustFinish) {
            onAudioEnd();
          }
        });
        await soundObject.current.playAsync();
      } catch (error) {
        console.error("Error playing audio:", error);
      }
    }
  };

  const resetAudioPlayer = async () => {
    if (Platform.OS === "web" && webAudioRef.current) {
      webAudioRef.current.pause();
      webAudioRef.current = null;
    } else if (soundObject.current) {
      await soundObject.current.stopAsync();
      await soundObject.current.unloadAsync();
    }

    setIsPlaying(false);
    setAudioQueue([]);
    setCurrentIndex(0);
    setLastIndex(100000);
  };

  const muteAudioPlayer = () => {
    if (webAudioRef.current) {
      webAudioRef.current.muted = !state.meta.isAudioOn;
    }
  };

  const unmuteAudioPlayer = () => {
    if (webAudioRef.current) {
      webAudioRef.current.muted = !state.meta.isAudioOn;
    }
  };

  useEffect(() => {
    if (state.meta.isAudioOn) {
      unmuteAudioPlayer();
    } else {
      muteAudioPlayer();
    }
  }, [state.meta.isAudioOn]);

  useEffect(() => {
    const audioSegments = state.audio.data;
    if (state.audio.type === "AI_STOP") {
      setLastIndex(audioSegments.length - 1);
    } else if (audioSegments && audioSegments.length > 0 && state.audio.type === "AI_STREAMING") {
      setAudioQueue(audioSegments);
    } else if (state.audio.type === "AI_ABORT") {
      resetAudioPlayer();
    }
  }, [state.audio]);

  useEffect(() => {
    return () => {
      if (soundObject.current) {
        soundObject.current.unloadAsync();
      }
    };
  }, []);

  return null;
};

const AudioPlayerMobile = () => {
  const { state } = useAppState();
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioQueue, setAudioQueue] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [lastIndex, setLastIndex] = useState(100000);
  const soundObject = useRef(new ExpoAudio.Sound()).current;
  const silentAudioRef = useRef(null);

  useEffect(() => {
    if (Platform.OS === "web") {
      const silentAudio = new Audio("../../../assets/audio/general/silence.mp3");
      silentAudio.loop = true;
      silentAudio.play();
      silentAudioRef.current = silentAudio;
    }
  }, []);

  useEffect(() => {
    playNextAudio();
  }, [audioQueue, currentIndex]);

  const muteAudioPlayer = () => {
    if (silentAudioRef.current) {
      silentAudioRef.current.muted = !state.meta.isAudioOn;
    }
  };

  const unmuteAudioPlayer = () => {
    if (silentAudioRef.current) {
      silentAudioRef.current.muted = !state.meta.isAudioOn;
    }
  };

  useEffect(() => {
    if (state.meta.isAudioOn) {
      unmuteAudioPlayer();
    } else {
      muteAudioPlayer();
    }
  }, [state.meta.isAudioOn]);

  const playNextAudio = async () => {
    if (audioQueue.length === 0 || isPlaying || audioQueue.length <= currentIndex) {
      return;
    }

    setIsPlaying(true);

    const onAudioEnd = () => {
      setIsPlaying(false);
      setCurrentIndex((previousIndex) => previousIndex + 1);
      if (currentIndex >= lastIndex) {
        setAudioQueue([]);
        setCurrentIndex(0);
      }
    };

    if (Platform.OS === "web") {
      const audioSrc = "data:audio/mp3;base64," + audioQueue[currentIndex];
      silentAudioRef.current.src = audioSrc;
      silentAudioRef.current.onended = onAudioEnd;
      await silentAudioRef.current.play();
    } else {
      const uri = `${FileSystem.documentDirectory}audio.mp3`;
      await FileSystem.writeAsStringAsync(uri, audioQueue[currentIndex], {
        encoding: FileSystem.EncodingType.Base64,
      });

      try {
        await soundObject.current.unloadAsync();
        await soundObject.current.loadAsync({ uri });
        soundObject.current.setOnPlaybackStatusUpdate((status) => {
          if (status.didJustFinish) {
            onAudioEnd();
          }
        });
        await soundObject.current.playAsync();
      } catch (error) {
        console.error("Error playing audio:", error);
      }
    }
  };

  const resetAudioPlayer = async () => {
    if (Platform.OS === "web" && silentAudioRef.current) {
      silentAudioRef.current.src = "../../../assets/audio/general/silence.mp3";
      silentAudioRef.current.play();
    } else if (soundObject.current) {
      await soundObject.current.stopAsync();
      await soundObject.current.unloadAsync();
    }

    setIsPlaying(false);
    setAudioQueue([]);
    setCurrentIndex(0);
    setLastIndex(100000);
  };

  useEffect(() => {
    const audioSegments = state.audio.data;
    if (state.audio.type === "AI_STOP") {
      silentAudioRef.current.loop = true;
      setLastIndex(audioSegments.length - 1);
    } else if (audioSegments && audioSegments.length > 0 && state.audio.type === "AI_STREAMING") {
      silentAudioRef.current.loop = false;
      setAudioQueue(audioSegments);
    } else if (state.audio.type === "AI_ABORT") {
      silentAudioRef.current.loop = true;
      resetAudioPlayer();
    }
  }, [state.audio]);

  useEffect(() => {
    return () => {
      if (soundObject.current) {
        soundObject.current.unloadAsync();
      }
    };
  }, []);

  return null;
};
