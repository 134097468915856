import React from "react";
import { StyleSheet, View } from "react-native";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";

import CustomText from "../../common/general/CustomText/CustomText";

const ProsCons = ({ pros, cons }) => {
  const { isLarge } = useResponsiveScreen();

  return (
    <View style={[styles.container, isLarge ? { flexDirection: "row" } : { flexDirection: "column}" }]}>
      <View style={styles.pros}>
        <View style={styles.textSection}>
          <CustomText size="xl" bold="true" style={styles.proText} text="Pros" />
          <CustomText style={styles.text} text={pros} />
        </View>
      </View>
      <View style={styles.cons}>
        <View style={styles.textSection}>
          <CustomText size="xl" bold="true" style={styles.conText} text="Cons" />
          <CustomText style={styles.text} text={cons} />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 10,
  },
  textSection: {
    margin: 20,
  },
  pros: {
    flex: 1,
    margin: 5,
    backgroundColor: "#EFFCF0",
    borderRadius: 20,
  },
  cons: {
    flex: 1,
    margin: 5,
    backgroundColor: "#A50A3814",
    borderRadius: 20,
  },
  proText: {
    color: "#0AA519",
    marginBottom: 10,
  },
  conText: {
    color: "#A50A38",
    marginBottom: 10,
  },
  text: {
    fontSize: 14,
    color: "#333",
  },
});

export default ProsCons;
