import { View, StyleSheet } from "react-native";
import CustomText from "../../common/general/CustomText/CustomText";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";
import CustomTextInput from "../../common/general/CustomTextInput/CustomTextInput";
import CustomButton from "../../common/general/CustomButton/CustomButton";

function SMSOTPForm({
  isOTPSent,
  errorMessage,
  countryCode,
  phoneNumber,
  onCountryCodeChange,
  onPhoneNumberChange,
  onSendOTP,
  otp,
  onOTPChange,
  onVerifyOTP,
  onResentOTP,
}) {
  const inputStyle = errorMessage ? styles.inputError : null;

  return (
    <View style={styles.container}>
      {!isOTPSent ? (
        <>
          <CustomText text="Add a Phone Number" size="xxl" weight="bold" />
          <CustomSpacing type="vertical" size="l" />

          <CustomTextInput
            placeholder="Country Code (e.g. 1)"
            onChangeText={(text) => onCountryCodeChange(text)}
            value={countryCode}
            style={inputStyle}
          />
          <CustomSpacing type="vertical" size="m" />

          <CustomTextInput
            placeholder="Phone Number"
            onChangeText={(text) => onPhoneNumberChange(text)}
            value={phoneNumber}
            style={inputStyle}
          />
          <CustomSpacing type="vertical" size="m" />

          <CustomButton text="Text Me" onPress={onSendOTP} styleType="primary" style={styles.button} />
          <CustomSpacing type="vertical" size="m" />
        </>
      ) : (
        <>
          <CustomText text="Enter Verification Code" size="xxl" weight="bold" />
          <CustomSpacing type="vertical" size="l" />

          <CustomTextInput
            placeholder="Enter 6 digit code"
            autoComplete="one-time-code"
            onChangeText={(text) => onOTPChange(text)}
            value={otp}
            style={inputStyle}
          />
          <CustomSpacing type="vertical" size="m" />

          <CustomButton text="Verify Phone Number" onPress={onVerifyOTP} styleType="primary" style={styles.button} />
          <CustomSpacing type="vertical" size="s" />

          <CustomButton text="Resend Code" onPress={onResentOTP} styleType="transparent" />
        </>
      )}
      {errorMessage ? (
        <CustomText text={errorMessage} size="s" style={styles.errorText} />
      ) : (
        <CustomSpacing type="vertical" size="m" />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    justifyContent: "center",
  },
  inputError: {
    borderColor: "red",
  },
  button: {
    width: "100%",
  },
  errorText: {
    color: "red",
  },
});

export default SMSOTPForm;
