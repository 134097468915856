import { View, StyleSheet } from "react-native";
import { useAppConfig } from "../AppConfigProvider";
import BlankLayout from "../layouts/BlankLayout";
import CustomText from "../components/common/general/CustomText/CustomText";
import Logo from "../components/general/logos/Logo/Logo";

function EdlinkSuccessView() {
  const appConfig = useAppConfig();

  const domainKey = appConfig.key;

  return (
    <BlankLayout>
      <View style={styles.container}>
        <Logo name={domainKey} height={100} width={280} />
        <CustomText text={`You have successfully linked your school account with Axio!`} size="l" style={styles.text} />
        <CustomText text={`Please reload the previous page to continue.`} size="l" style={styles.text} />
      </View>
    </BlankLayout>
  );
}

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
  text: {
    textAlign: "center",
  },
});

export default EdlinkSuccessView;
