import React from "react";
import { StyleSheet, View } from "react-native";
import { useAppConfig } from "../../../AppConfigProvider";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import { ScrollView } from "react-native-gesture-handler";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";
import { LinearGradient } from "expo-linear-gradient";

function SelectableMenu({ items, onSelect, selectedItem }) {
  const { primaryColor } = useAppConfig();

  return (
    <View style={{ flexDirection: "row", justifyContent: "flex-end" }}>
      <ScrollView horizontal={true} showsHorizontalScrollIndicator={false} style={styles.container}>
        {items.map((item, index) => (
          <View style={styles.buttonContainer} key={item}>
            <CustomButton
              text={item}
              size="xxl"
              styleType="primaryTransparent"
              style={styles.itemButton}
              textStyle={
                selectedItem === item ? { color: primaryColor, fontSize: 18, fontFamily: "Inter" } : styles.itemText
              }
              onPress={() => onSelect(item)}
              bold
            />
            {index < items.length - 1 && <CustomSpacing type="horizontal" size="xl" />}
          </View>
        ))}
      </ScrollView>
      <LinearGradient
        colors={["transparent", "rgba(255,255,255,1.0)"]}
        style={{
          position: "absolute",
          width: 39,
          height: 48,
          elevation: 5,
        }}
        start={{ x: 0, y: 0 }}
        end={{ x: 0.1, y: 0 }}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  buttonContainer: {
    flexDirection: "row",
  },
  itemButton: {
    padding: 0,
  },
  itemText: {
    color: "#98A2B3",
    fontSize: 18,
    fontFamily: "Inter",
  },
});

export default SelectableMenu;
