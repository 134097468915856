import { StyleSheet } from "react-native";
import CustomText from "../../../common/general/CustomText/CustomText";
import { AnimatedCircularProgress } from "react-native-circular-progress";

function LearnProgressCircle({ value, size = 45, width = 5 }) {
  return (
    <AnimatedCircularProgress
      size={size}
      width={width}
      fill={value}
      tintColor="#5BE068"
      backgroundColor="#E6EBFF"
      lineCap="round">
      {() => <CustomText text={`${value}%`} weight="bold" style={styles.text} />}
    </AnimatedCircularProgress>
  );
}

const styles = StyleSheet.create({
  text: {
    fontSize: 10,
  },
});

export default LearnProgressCircle;
