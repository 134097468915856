import React from "react";
import { StyleSheet, View, ImageBackground, Pressable } from "react-native";
import CustomText from "../../common/general/CustomText/CustomText";
import CustomProgressBar from "../../common/general/CustomProgressBar/CustomProgressBar";

const JourneyCard = ({ title, id, started, expectedCompletion, completedTasks, totalTasks, url, onPress }) => {
  return (
    <Pressable onPress={() => onPress(id)} style={styles.container}>
      <ImageBackground source={{ uri: url }} style={styles.image}>
        <View style={styles.progressBarOverlay}>
          <View style={{ flex: 1, padding: 10, flexDirection: "column" }}>
            <View style={{ paddingHorizontal: 20 }}>
              <CustomText
                weight="bold"
                text={`${completedTasks} of ${totalTasks} Tasks Complete`}
                styleType="light"
                size="m"
              />
            </View>
            <View style={styles.progressBarContainer}>
              <CustomProgressBar progress={Math.round((completedTasks / totalTasks) * 100)} />
            </View>
          </View>
        </View>
      </ImageBackground>
      <View style={{ margin: 20 }}>
        <CustomText weight="bold" text={title} size="l" />
        <View style={styles.textRow}>
          <CustomText text={"Started: "} weight="bold" size="s" />
          <CustomText text={started} weight="light" size="s" />
        </View>
        <View style={styles.textRow}>
          <CustomText text={"Expected Completion: "} weight="bold" size="s" />
          <CustomText text={expectedCompletion} weight="light" size="s" />
        </View>
      </View>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 20,
    overflow: "hidden",
    backgroundColor: "white",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.08,
    shadowRadius: 4,
    elevation: 5,
    maxWidth: 570,
    width: "100%",
    margin: 10,
  },
  progressBarContainer: {
    width: "100%",
    paddingHorizontal: 20,
    paddingVertical: 4,
  },
  textRow: {
    flexDirection: "row",
    marginTop: 12,
  },
  image: {
    width: "100%",
    height: 244,
  },
  progressBarOverlay: {
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    borderRadius: 10,
    position: "absolute",
    bottom: 10,
    left: 10,
    right: 10,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
});

export default JourneyCard;
