import { useState, useEffect } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import ClassCardList from "../../../components/lms/ClassCardList/ClassCardList";
import { MotiView } from "moti";
import { useNavigation } from "@react-navigation/native";
import { listClasses } from "../../../services";
import Loader from "../../../components/common/status/Loader/Loader";
import { View } from "react-native";
import CustomText from "../../../components/common/general/CustomText/CustomText";
import CustomSpacing from "../../../components/common/layout/CustomSpacing/CustomSpacing";

function ClassCardListContainer({ integrationId }) {
  const [courses, setCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigation = useNavigation();

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const token = await AsyncStorage.getItem("token");
      const data = await listClasses(token, integrationId);

      setCourses(data);
      setIsLoading(false);
    };

    fetchData();
  }, [integrationId]);

  const handlePress = (id) => {
    const course = courses.filter((course) => course.id === id)[0];
    navigation.navigate("Course", {
      course_id: course.id,
      integration_id: integrationId,
    });
  };

  return (
    <MotiView
      from={{ opacity: 0, translateY: 20 }}
      animate={{ opacity: 1, translateY: 0 }}
      delay={500}
      transition={{ type: "timing", duration: 300 }}
      style={{ flex: 1 }}>
      {isLoading ? (
        <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
          <Loader />
          <CustomSpacing type="vertical" size="s" />
          <CustomText text="Loading..." size="m" />
        </View>
      ) : (
        <ClassCardList courses={courses} onPress={handlePress} />
      )}
    </MotiView>
  );
}

export default ClassCardListContainer;
