import React from "react";
import { StyleSheet, View } from "react-native";
import useResponsiveScreen from "../../../../hooks/useResponsiveScreen";
import { PivotCareer } from "../../../svgs/careerGoals/index";
import CustomText from "../../../common/general/CustomText/CustomText";

const PivotNewCareer = () => {
  const { isLarge } = useResponsiveScreen();

  return (
    <View style={styles.container}>
      <PivotCareer style={{ paddingRight: 4 }} />
      <CustomText size={isLarge ? "s" : "xs"} style={styles.text} text="Pivot Career" />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
});

export default PivotNewCareer;
