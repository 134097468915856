import React from "react";
import { MotiView } from "moti";
import { View, StyleSheet, Pressable } from "react-native";

import CustomText from "../../common/general/CustomText/CustomText";
import Divider from "../../common/layout/Divider/Divider";
import {
  LinkIcon,
  GoogleDocumentIcon,
  GoogleSheetIcon,
  GoogleDiscIcon,
  GooglePresentationIcon,
  GoogleDrawingsIcon,
  YoutubeIcon,
  GoogleFormsIcon,
} from "../../svgIcons";
import handleOpenLink from "../../../utils/handleOpenLink";

//TODO add right hand side for delete when isUploaded is true

function getIconComponent(url) {
  if (!url) {
    return LinkIcon;
  }

  const iconComponents = {
    "docs.google.com/document": GoogleDocumentIcon,
    "docs.google.com/forms": GoogleFormsIcon,
    "docs.google.com/presentation": GooglePresentationIcon,
    "docs.google.com/spreadsheets": GoogleSheetIcon,
    "docs.google.com/drawings": GoogleDrawingsIcon,
    "drive.google.com/file": GoogleDiscIcon,
    "www.youtube.com": YoutubeIcon,
    default: LinkIcon,
  };

  for (const prefix in iconComponents) {
    if (url.startsWith(`https://${prefix}`)) {
      return iconComponents[prefix];
    }
  }

  return iconComponents["default"];
}

function FileCard({ title, url }) {
  const handleFileCardPress = async () => {
    if (!url) {
      return;
    }
    await handleOpenLink(url, false);
  };

  const IconComponent = getIconComponent(url);

  return (
    <MotiView
      from={{
        opacity: 0,
        translateX: -400,
      }}
      animate={{
        opacity: 1,
        translateX: 0,
      }}
      delay={200}
      transition={{ type: "spring" }}>
      <Pressable onPress={handleFileCardPress}>
        <View style={styles.card} onPress>
          <View style={styles.infoContainer}>
            <View style={styles.iconContainer}>
              <IconComponent />
            </View>
            <Divider size="s" type="horizontal" />
            <View style={styles.textContainer}>
              <CustomText numberOfLines={1} text={title} style={{ maxWidth: 200 }} weight="bold" size="xs" />
            </View>
          </View>
        </View>
      </Pressable>
    </MotiView>
  );
}

const styles = StyleSheet.create({
  card: {
    width: 290,
    flexDirection: "column",
    padding: 20,
    backgroundColor: "#fff",
    borderColor: "#D9D9D9",
    borderWidth: 1,
    borderRadius: 10,
    justifyContent: "flex-start",
  },
  infoContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  textContainer: {
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  iconContainer: {
    maxWidth: 40,
    maxHeight: 40,
    width: 40,
    height: 40,
  },
});

export default FileCard;
