import { View, StyleSheet } from "react-native";
import CustomModal from "../../common/general/CustomModal/CustomModal";
import QuestImageBackground from "../../gamification/QuestImageBackground/QuestImageBackground";
import CustomText from "../../common/general/CustomText/CustomText";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";
import CustomTextInput from "../../common/general/CustomTextInput/CustomTextInput";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import Loader from "../../common/status/Loader/Loader";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";

function QuestModal({
  type,
  isLoading,
  isFailed,
  description,
  responseInput,
  userResponse,
  visible,
  onClose,
  onChangeText,
  onSubmit,
}) {
  const { isMedium, hp } = useResponsiveScreen();

  return (
    <CustomModal visible={visible} onRequestClose={onClose} showCloseButton={true} exitFill="#FFF" width="90%">
      <QuestImageBackground questTitle={type} style={[styles.backgroundImage, { height: isMedium ? 400 : 235 }]}>
        <CustomText
          styleType="light"
          textType="display"
          size={isMedium ? "m" : "s"}
          style={[styles.header, { padding: isMedium ? 10 : 0 }]}
          text={type}
          weight="bold"
        />
      </QuestImageBackground>

      {userResponse ? (
        <View style={isMedium ? styles.bodyContainer : styles.bodyContainerMobile}>
          <CustomText text={userResponse} size="xl" />
        </View>
      ) : (
        <View style={isMedium ? styles.bodyContainer : styles.bodyContainerMobile}>
          <CustomText text={description} size={isMedium ? "xl" : "s"} />
          <CustomSpacing type="vertical" size="s" />

          {isFailed ? (
            <>
              <CustomText text="Quest Failed. Please try again." size="xs" style={styles.failedText} />
              <CustomSpacing type="vertical" size="s" />
            </>
          ) : (
            <CustomSpacing type="vertical" size="m" />
          )}
          {isLoading ? (
            <View style={styles.loadingContainer}>
              <Loader />
            </View>
          ) : (
            <CustomTextInput
              multiline
              numberOfLines={8}
              onChangeText={(text) => onChangeText(text)}
              value={responseInput}
            />
          )}

          <CustomSpacing type="vertical" size={isMedium ? "m" : "l"} />
          <CustomButton
            text={isLoading ? "Checking..." : "Submit"}
            styleType="primary"
            onPress={onSubmit}
            bold={!isMedium}
            disabled={isLoading}
            textStyle={isMedium ? {} : { fontSize: hp(1.5) }}
          />
        </View>
      )}
    </CustomModal>
  );
}

const styles = StyleSheet.create({
  backgroundImage: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    zIndex: -1,
  },
  header: {
    textShadow: "0px 4px 6px rgba(0, 0, 0, 0.60)",
    elevation: 4,
    position: "absolute",
    bottom: 10,
    left: 20,
    padding: 10,
  },
  bodyContainer: {
    marginTop: 328,
    padding: 12,
    flex: 1,
  },
  bodyContainerMobile: {
    marginTop: 175,
    padding: 0,
    flex: 1,
  },
  loadingContainer: {
    alignItems: "center",
  },
  failedText: {
    color: "red",
  },
});

export default QuestModal;
