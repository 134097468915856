import React from "react";
import { Pressable, View, Text, StyleSheet } from "react-native";
import CustomText from "../../common/general/CustomText/CustomText";

import useResponsiveScreen from "../../../hooks/useResponsiveScreen";

const SuggestionCard = ({ content, type, onPress }) => {
  const { isMedium } = useResponsiveScreen();

  const typeMap = {
    Assignment: "Assignment",
    Todo: "Tasks",
    Journey: "Journey",
    Topic: "Track",
  };

  return (
    <View style={isMedium ? styles.container : styles.containerMobile}>
      <Text style={styles.title} numberOfLines={3}>
        {content}
      </Text>
      <View style={styles.buttonContainer}>
        <Pressable style={styles.button} onPress={() => onPress(content)}>
          <CustomText text={`View ${typeMap[type]} →`} size="s" style={styles.buttonText} />
        </Pressable>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    flex: 1,
    paddingRight: 16,
    marginBottom: 20,
    backgroundColor: "#FFF",
  },
  containerMobile: {
    width: "100%",
    maxWidth: 280,
    paddingRight: 16,
    // marginHorizontal: 8,
    marginBottom: 20,
    backgroundColor: "#FFF",
    // borderRadius: 10,
    // shadowColor: "#000",
    // shadowOffset: { width: 0, height: 4 },
    // shadowOpacity: 0.1,
    // shadowRadius: 12,
    // elevation: 6,
  },
  title: {
    color: "#8F8F8F",
    fontSize: 16,
    marginBottom: 6,
  },
  buttonContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
    marginTop: 10,
  },
  button: {
    flexDirection: "row",
    alignItems: "center",
    paddingRight: 8,
  },
  buttonText: {
    marginLeft: 4,
    color: "#333",
    fontWeight: "bold",
    fontSize: 13,
  },
});

export default SuggestionCard;
