import * as React from "react";
import Svg, { Path } from "react-native-svg";

function Edit(props) {
  return (
    <Svg width={17} height={17} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M16.663 3.336l-2.5-2.5a.937.937 0 00-1.328 0l-7.5 7.5A.937.937 0 005.063 9v2.5a.937.937 0 00.937.937h2.5a.937.937 0 00.663-.274l7.5-7.5a.939.939 0 000-1.327zM13.5 2.828L14.672 4l-.86.859-1.171-1.172.859-.86zm-5.39 7.734H6.937V9.39l4.375-4.375 1.171 1.172-4.375 4.375zm8.203-1.205v5.893a1.562 1.562 0 01-1.563 1.562H2.25A1.563 1.563 0 01.687 15.25V2.75A1.563 1.563 0 012.25 1.187h5.893a.938.938 0 110 1.875h-5.58v11.875h11.874v-5.58a.938.938 0 011.876 0z"
        fill="#fff"
      />
    </Svg>
  );
}

export default Edit;
