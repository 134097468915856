export const getWelcomeOnboardingModalData = (name, companionName) => {
  return {
    step1: {
      title: `Hi ${name}, Welcome to ${companionName}!`,
      description: `${companionName} is an AI assistant that evolves with you over time, supporting you in areas such as productivity, and learning. To help you get started, ${companionName} has created a few tasks for you.`,
      image: require("../assets/onboardingModals/Welcome.png"),
      aspectRatio: 1,
      imageHeight: 219,
    },
    // step2: {
    //   title: "A personalized experience unique to you",
    //   description:
    //     "The more you chat with ${companionName}, the more personalized your experience becomes. ${companionName} fine-tunes its conversations, weaving in details that resonate uniquely with you.",
    //   image: require("../assets/onboardingModals/Experience.png"),
    //   aspectRatio: 1.53,
    //   imageHeight: 350,
    // },
    // step3: {
    //   title: "Earn XP and Level Up",
    //   description:
    //     "You’ll earn XP as you complete Daily Quests and Objectives, Tasks, Learning Modules and more!",
    //   image: require("../assets/onboardingModals/Level.png"),
    //   aspectRatio: 1.09,
    //   imageHeight: 320,
    // },
    // step4: {
    //   title: "Explore countless learning paths",
    //   description:
    //     "Explore and uncover exciting learning paths with ${companionName}. Delight in discovering journeys that inspire your personal and professional growth.",
    //   image: require("../assets/onboardingModals/Paths.png"),
    //   aspectRatio: 1.53,
    //   imageHeight: 350,
    // },
    // step5: {
    //   title: "Learn your way",
    //   description:
    //     "Choose from diverse learning activities like stories, interactive content, and hands-on projects to suit your learning style.",
    //   image: require("../assets/onboardingModals/Way.png"),
    //   aspectRatio: 1,
    //   imageHeight: 446,
    // },
    // step6: {
    //   title: "Put Your Learning to the Test!",
    //   description:
    //     "Test and reinforce your knowledge through various challenges, from interactive games to quizzes and discussions.",
    //   image: require("../assets/onboardingModals/Choice.png"),
    //   aspectRatio: 1.09,
    //   imageHeight: 429,
    // },
    // step7: {
    //   title: "Stay Healthy, Stay Motivated",
    //   description:
    //     "Track fitness goals and receive personalized workouts and meal plans with Primer. Your key to a balanced lifestyle, blending health with learning and growth.",
    //   image: require("../assets/onboardingModals/Health.png"),
    //   aspectRatio: 1.53,
    //   imageHeight: 350,
    // },
  };
};
