import { useState } from "react";
import { View, StyleSheet } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useDeleteMessageMutation, TodayChatDocument, DateForChatDocument } from "../../../graphql/generated/graphql";
import useWebSocket from "../../../hooks/useWebSocket";
import { useNavigation, useRoute } from "@react-navigation/native";
import AIMessage from "../../../components/chat/AIMessage/AIMessage";
import UserMessage from "../../../components/chat/UserMessage/UserMessage";
import LearningMessage from "../../../components/chat/LearningMessage/LearningMessage";
import AspectMessage from "../../../components/chat/AspectMessage/AspectMessage";
import AspectModal from "../../../components/chat/AspectModal/AspectModal";
import AspectFormContainer from "../../chat/AspectFormContainer/AspectFormContainer";
import QuestMessage from "../../../components/chat/QuestMessage/QuestMessage";
import QuestModal from "../../../components/gamification/QuestModal/QuestModal";
import CustomSpacing from "../../../components/common/layout/CustomSpacing/CustomSpacing";
import CustomButton from "../../../components/common/general/CustomButton/CustomButton";
import ConfirmationModal from "../../../components/general/ConfirmationModal/ConfirmationModal";
import { EditIcon } from "../../../components/svgIcons";

// Date is only being passed in here until it is a part of the message object
function JournalMessageContainer({ message, date }) {
  const { sendMessage } = useWebSocket();
  const navigation = useNavigation();
  const router = useRoute();

  const [deleteMessage] = useDeleteMessageMutation({
    refetchQueries: [
      {
        query: TodayChatDocument,
        variables: {
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          context: router.name,
          params: router.params,
        },
      },
      {
        query: DateForChatDocument,
        variables: {
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          date,
        },
      },
    ],
  });

  const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);
  const [aspectModalVisible, setAspectModalVisible] = useState(false);
  const [questModalVisible, setQuestModalVisible] = useState(false);

  const type = message.type || message.sender || null;

  const handleOpenConfirmationModal = () => {
    setConfirmationModalVisible(true);
  };

  const handleCloseConfirmationModal = () => {
    setConfirmationModalVisible(false);
  };

  const handleDeleteMessage = async (id) => {
    await deleteMessage({
      variables: {
        id,
      },
    });

    handleCloseConfirmationModal();
  };

  const handleOpenAspectModal = () => {
    setAspectModalVisible(true);
  };

  const handleCloseAspectModal = () => {
    setAspectModalVisible(false);
  };

  const handleOpenQuestModal = () => {
    setQuestModalVisible(true);
  };

  const handleCloseQuestModal = () => {
    setQuestModalVisible(false);
  };

  const handleLearningPress = async () => {
    const token = await AsyncStorage.getItem("token");
    sendMessage({
      meta: {
        token: token,
        abort: true,
      },
      state: {},
      content: {},
    });

    if (message.parameters.lastGeneratedLabel === "Subchapter") {
      const token = await AsyncStorage.getItem("token");
      sendMessage({
        meta: {
          token: token,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          isLoading: true,
        },
        state: {
          general: "LEARNING",
        },
      });

      navigation.navigate("Tracks", {
        subchapter: message.parameters.lastGeneratedTopic,
      });
    } else {
      navigation.navigate("Categories", {
        [message.parameters.lastGeneratedLabel.toLowerCase()]: message.parameters.lastGeneratedTopic,
      });
    }
  };

  switch (type) {
    case "ai":
      return (
        <>
          <CustomSpacing type="vertical" size="xs" />
          <View style={styles.messageWrapper}>
            <CustomButton
              leftIcon={<EditIcon />}
              styleType="transparent"
              onPress={() => handleOpenConfirmationModal(message.id)}
            />
            <AIMessage text={message.content} />
          </View>
          <CustomSpacing type="vertical" size="xs" />

          <ConfirmationModal
            message="Are you sure you want to delete this message?"
            visible={confirmationModalVisible}
            onClose={handleCloseConfirmationModal}
            onConfirm={() => handleDeleteMessage(message.id)}
          />
        </>
      );
    case "human":
      return (
        <>
          <CustomSpacing type="vertical" size="m" />
          <View style={styles.messageWrapper}>
            <CustomButton
              leftIcon={<EditIcon />}
              styleType="transparent"
              onPress={() => handleOpenConfirmationModal(message.id)}
            />
            <UserMessage text={message.content} />
          </View>
          <CustomSpacing type="vertical" size="m" />

          <ConfirmationModal
            message="Are you sure you want to delete this message?"
            visible={confirmationModalVisible}
            onClose={handleCloseConfirmationModal}
            onConfirm={() => handleDeleteMessage(message.id)}
          />
        </>
      );
    case "aspect":
      return (
        <>
          <CustomSpacing type="vertical" size="m" />
          <AspectMessage message={message} onPress={handleOpenAspectModal} />
          <CustomSpacing type="vertical" size="m" />

          <AspectModal message={message} visible={aspectModalVisible} onClose={handleCloseAspectModal}>
            <CustomSpacing type="vertical" size="m" />
            <AspectFormContainer message={message} onClose={handleCloseAspectModal} />
          </AspectModal>
        </>
      );
    case "quest":
      return (
        <>
          <CustomSpacing type="vertical" size="m" />
          <QuestMessage type={message.snackType} onPress={handleOpenQuestModal} />
          <CustomSpacing type="vertical" size="m" />

          <QuestModal
            type={message.snackType}
            userResponse={message.parameters.userResponse}
            visible={questModalVisible}
            onClose={handleCloseQuestModal}
          />
        </>
      );
    case "snack":
      return (
        <>
          <CustomSpacing type="vertical" size="m" />
          <LearningMessage
            topic={message.parameters.lastGeneratedTopic}
            label={message.parameters.lastGeneratedLabel}
            onPress={handleLearningPress}
          />
          <CustomSpacing type="vertical" size="m" />
        </>
      );
    default:
      return <></>;
  }
}

const styles = StyleSheet.create({
  messageWrapper: {
    flexDirection: "row",
    alignItems: "flex-start",
  },
});

export default JournalMessageContainer;
