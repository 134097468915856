import { useState, useEffect, useCallback } from "react";
import { View, StyleSheet } from "react-native";
import { useAppConfig } from "../AppConfigProvider";
import useWebSocket from "../hooks/useWebSocket";
import { usePostHog } from "posthog-js/react";
import useResponsiveScreen from "../hooks/useResponsiveScreen";
import {
  useMeQuery,
  useDailyNutritionTotalsByDateQuery,
  useDailyExerciseTotalsByDateQuery,
} from "../graphql/generated/graphql";
import AsyncStorage from "@react-native-async-storage/async-storage";
import DefaultLayout from "../layouts/DefaultLayout.web";
import SelectableMenu from "../components/navigation/SelectableMenu/SelectableMenu";
import WeightGoal from "../components/health/WeightGoal/WeightGoal";
import HealthSuggestions from "../components/health/HealthSuggestions/HealthSuggestions";
import HealthTable from "../components/health/HealthTable/HealthTable";
import HydrationAnimationContainer from "../containers/health/HydrationAnimationContainer/HydrationAnimationContainer";
import MealCardListContainer from "../containers/health/MealCardListContainer/MealCardListContainer";
import { ScrollView } from "react-native-gesture-handler";
import CustomSpacing from "../components/common/layout/CustomSpacing/CustomSpacing";
import CustomText from "../components/common/general/CustomText/CustomText";
import DatePickerButton from "../components/general/DatePickerButton/DatePickerButton";
import { DatePickerModal } from "react-native-paper-dates";
import Loader from "../components/common/status/Loader/Loader";
import ErrorLoader from "../components/common/status/ErrorLoader/ErrorLoader";
import { MotiView } from "moti";
import { useChatBar } from "../contexts/ChatBarContext";
import { useRoute } from "@react-navigation/native";

function HealthView() {
  const appConfig = useAppConfig();
  const { sendMessage } = useWebSocket();
  const posthog = usePostHog();
  const { isMedium } = useResponsiveScreen();
  const route = useRoute();
  const { setShowChat, mapRouteToContext } = useChatBar();

  const now = new Date();
  const localDate = new Date(now.getTime() - now.getTimezoneOffset() * 60000);
  const [date, setDate] = useState(localDate.toISOString().split("T")[0]);
  const [pickerDate, setPickerDate] = useState(undefined);
  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);
  const [selectedView, setSelectedView] = useState("My Health");

  const { data: meData, loading: loadingMe, error: errorMe } = useMeQuery();

  const {
    data: nutritionData,
    loading: loadingNutrition,
    error: errorNutrition,
  } = useDailyNutritionTotalsByDateQuery({
    variables: {
      date: date,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  });

  const {
    data: exerciseData,
    loading: loadingExercise,
    error: errorExercise,
  } = useDailyExerciseTotalsByDateQuery({
    variables: {
      date: date,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  });

  const companionName = appConfig.companionName;

  const loading = loadingMe || loadingNutrition || loadingExercise;
  const error = errorMe || errorNutrition || errorExercise;

  useEffect(() => {
    posthog.capture("Health Page Visited");
  }, []);

  const handleDatePick = () => {
    setIsDatePickerVisible(true);
  };

  const handleDatePickerDismiss = useCallback(() => {
    setIsDatePickerVisible(false);
  }, [setIsDatePickerVisible]);

  const handleDatePickerConfirm = useCallback(
    (params) => {
      selectedDateHandler(params.date);
      setIsDatePickerVisible(false);
      setPickerDate(params.date);
    },
    [isDatePickerVisible, setIsDatePickerVisible]
  );

  const selectedDateHandler = (d) => {
    const selectedDate = new Date(d.getTime() - d.getTimezoneOffset() * 60000);
    const parsedDate = selectedDate.toISOString().split("T")[0];
    setDate(parsedDate);
  };

  const handleWorkoutPressed = async () => {
    const token = await AsyncStorage.getItem("token");
    sendMessage({
      meta: {
        token: token,
        abort: false,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        isLoading: true,
      },
      state: {
        general: "GENERAL_CONVERSATION",
        context: mapRouteToContext(route.name),
        params: route.params,
      },
      content: {
        message: "Suggest a workout for me",
        author: "HUMAN",
        type: null,
      },
    });
    setShowChat(true);
  };

  const handleMealPressed = async () => {
    const token = await AsyncStorage.getItem("token");
    sendMessage({
      meta: {
        token: token,
        abort: false,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        isLoading: true,
      },
      state: {
        general: "GENERAL_CONVERSATION",
        context: mapRouteToContext(route.name),
        params: route.params,
      },
      content: {
        message: "Suggest a meal for me",
        author: "HUMAN",
        type: null,
      },
    });
    setShowChat(true);
  };

  const handleSelect = (item) => {
    setSelectedView(item);
  };

  if (loading) {
    return (
      <DefaultLayout>
        <View style={styles.loaderContainer}>
          <Loader />
        </View>
      </DefaultLayout>
    );
  }
  if (error) {
    return (
      <DefaultLayout>
        <View style={styles.loaderContainer}>
          <ErrorLoader message="Something went wrong! Try reloading the page." />
        </View>
      </DefaultLayout>
    );
  }

  function formatNutritionValue(nutritionData, key, precision = 0, unit = "") {
    const value = nutritionData.getDailyNutritionTotalsByDate?.[key];
    return value ? `${value.toFixed(precision)}${unit}` : `0${unit}`;
  }

  const nutrition = [
    {
      label: "Calories",
      value: formatNutritionValue(nutritionData, "totalCalories"),
    },
    {
      label: "Proteins",
      value: formatNutritionValue(nutritionData, "totalProteins", 1, "g"),
    },
    {
      label: "Fats",
      value: formatNutritionValue(nutritionData, "totalFats", 1, "g"),
    },
    {
      label: "Carbs",
      value: formatNutritionValue(nutritionData, "totalCarbs", 1, "g"),
    },
  ];

  function formatExerciseValue(exerciseData, key, precision = 0) {
    const value = exerciseData.getDailyExerciseTotalsByDate?.[key];
    return value ? value.toFixed(precision) : "0";
  }

  const exercises = [
    {
      label: "Calories Burned",
      value: formatExerciseValue(exerciseData, "totalCaloriesBurned"),
    },
    {
      label: "Minutes",
      value: formatExerciseValue(exerciseData, "totalDurationMinutes"),
    },
    {
      label: "Workouts",
      value: formatExerciseValue(exerciseData, "totalWorkouts"),
    },
  ];

  return (
    <DefaultLayout>
      {isMedium ? (
        <>
          <MotiView
            from={{
              opacity: 0,
              translateY: 20,
              scale: 1,
            }}
            animate={{
              opacity: 1,
              translateY: 0,
              scale: 1,
            }}
            delay={10}
            transition={{ type: "timing", duration: 500 }}>
            <View style={styles.header}>
              <CustomText text="My Health" size="l" textType="display" weight="ultraBold" />
              <DatePickerButton date={date} onPress={handleDatePick} />
            </View>
            <CustomSpacing type="vertical" size="m" />
          </MotiView>
          <ScrollView style={styles.scrollView} showsVerticalScrollIndicator={false}>
            <MotiView
              from={{
                opacity: 0,
                translateY: 20,
                scale: 1,
              }}
              animate={{
                opacity: 1,
                translateY: 0,
                scale: 1,
              }}
              delay={700}
              transition={{ type: "timing", duration: 500 }}>
              <View style={styles.mainRow}>
                <View style={styles.rowItem}>
                  <WeightGoal value={meData.me.current_weight_lbs} goal={meData.me.goal_weight_lbs} />
                </View>
                <CustomSpacing type="horizontal" size="xl" />

                <View style={styles.rowItem}>
                  <HealthSuggestions
                    companionName={companionName}
                    onMealPress={handleMealPressed}
                    onWorkoutPress={handleWorkoutPressed}
                  />
                </View>
                <CustomSpacing type="horizontal" size="xl" />

                <View style={styles.rowItem}>
                  <View style={styles.row}>
                    <HealthTable items={nutrition} />
                    <CustomSpacing type="horizontal" size="xl" />

                    <HealthTable items={exercises} />
                  </View>
                </View>
              </View>
              <CustomSpacing type="vertical" size="xl" />
            </MotiView>
            <MotiView
              from={{
                opacity: 0,
                translateY: 20,
                scale: 1,
              }}
              animate={{
                opacity: 1,
                translateY: 0,
                scale: 1,
              }}
              delay={1300}
              transition={{ type: "timing", duration: 500 }}>
              <View style={styles.mainRow}>
                <View style={styles.hydrationContainer}>
                  <CustomText text="Water Intake" size="xs" textType="display" />
                  <CustomSpacing type="vertical" size="l" />

                  <HydrationAnimationContainer date={date} />
                </View>
                <CustomSpacing type="horizontal" size="xl" />

                <View style={styles.mealContainer}>
                  <View style={styles.row}>
                    <CustomText text="Meals" size="xs" textType="display" />
                    <CustomSpacing type="horizontal" size="m" />
                    <View style={styles.mealsInfo}>
                      <CustomText
                        text={`To add data, just tell ${companionName} in the chat!`}
                        size="m"
                        style={styles.mealsInfoText}
                      />
                    </View>
                  </View>
                  <CustomSpacing type="vertical" size="l" />

                  <MealCardListContainer date={date} />
                </View>
              </View>
            </MotiView>
            <CustomSpacing type="vertical" size={isMedium ? "l" : "xl"} />
          </ScrollView>
        </>
      ) : (
        <>
          <SelectableMenu
            items={["My Health", "Water Intake", "Meals"]}
            selectedItem={selectedView}
            onSelect={handleSelect}
          />
          <CustomSpacing type="vertical" size="m" />

          {selectedView === "My Health" && (
            <ScrollView style={styles.scrollView} showsVerticalScrollIndicator={false}>
              <MotiView
                from={{
                  opacity: 0,
                  translateY: 20,
                  scale: 1,
                }}
                animate={{
                  opacity: 1,
                  translateY: 0,
                  scale: 1,
                }}
                delay={100}
                transition={{ type: "timing", duration: 600 }}>
                <View style={styles.row}>
                  <WeightGoal value={meData.me.current_weight_lbs} goal={meData.me.goal_weight_lbs} />
                  <CustomSpacing type="horizontal" size="xl" />

                  <HealthSuggestions
                    companionName={companionName}
                    onMealPress={handleMealPressed}
                    onWorkoutPress={handleWorkoutPressed}
                  />
                </View>
                <CustomSpacing type="vertical" size="l" />

                <HealthTable items={[...nutrition, ...exercises]} />
                <CustomSpacing type="vertical" size="xl" />
              </MotiView>
            </ScrollView>
          )}

          {selectedView === "Water Intake" && (
            <View style={styles.mobileHydrationContainer}>
              <MotiView
                from={{
                  opacity: 0,
                  translateY: 20,
                  scale: 1,
                }}
                animate={{
                  opacity: 1,
                  translateY: 0,
                  scale: 1,
                }}
                delay={100}
                transition={{ type: "timing", duration: 600 }}>
                <HydrationAnimationContainer date={date} />
              </MotiView>
            </View>
          )}

          {selectedView === "Meals" && (
            <MotiView
              from={{
                opacity: 0,
                translateY: 20,
                scale: 1,
              }}
              animate={{
                opacity: 1,
                translateY: 0,
                scale: 1,
              }}
              style={{ flex: 1 }}
              delay={100}
              transition={{ type: "timing", duration: 600 }}>
              <View style={styles.mobileMealContainer}>
                <View style={styles.mealsInfo}>
                  <CustomText
                    text={`To add data, just tell ${companionName} in the chat!`}
                    size="m"
                    style={styles.mealsInfoText}
                  />
                </View>
                <CustomSpacing type="vertical" size="l" />

                <MealCardListContainer date={date} />
                <CustomSpacing type="vertical" size="l" />
              </View>
            </MotiView>
          )}
        </>
      )}

      <DatePickerModal
        locale="en"
        mode="single"
        label="Select date"
        visible={isDatePickerVisible}
        onDismiss={handleDatePickerDismiss}
        date={pickerDate}
        onConfirm={handleDatePickerConfirm}
      />
    </DefaultLayout>
  );
}

const styles = StyleSheet.create({
  loaderContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  mainRow: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  rowItem: {
    marginVertical: 16,
    flexGrow: 1,
  },
  row: {
    flexDirection: "row",
  },
  scrollView: {
    height: 20,
    paddingHorizontal: 10,
  },
  hydrationContainer: {
    flex: 1,
    minWidth: 300,
  },
  mobileHydrationContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  mealContainer: {
    flex: 1,
    minWidth: 300,
    minHeight: 300,
  },
  mobileMealContainer: {
    flex: 1,
  },
  mealsInfo: {
    borderRadius: 20,
    backgroundColor: "rgba(74, 222, 128, 0.1)",
    alignItems: "center",
    padding: 10,
  },
  mealsInfoText: {
    color: "#16a34a",
  },
});

export default HealthView;
