import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import CustomText from "../../common/general/CustomText/CustomText";
import CustomTextInput from "../../common/general/CustomTextInput/CustomTextInput";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import { GreenCircle } from "../../svgs/common/index";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import SelectableList from "../../common/form/SelectableList/SelectableList";
import LookingForAJob from "../CareerGoals/LookingForAJob/LookingForAJob";
import PivotNewCareer from "../CareerGoals/PivotNewCareer/PivotNewCareer";
import UpskillAndGrow from "../CareerGoals/UpskillAndGrow/UpskillAndGrow";
import { CloseIcon } from "../../svgIcons";

const EditCareerDetails = ({ occupation, selectedItem, activePath, savePress, removePath, onFocus }) => {
  //Todo:
  //Remove these two
  const { isLarge } = useResponsiveScreen();
  const [selectedCareerGoal, setSelectedCareerGoal] = useState(selectedItem);
  const [currentOccupation, setCurrentOccupation] = useState(occupation);
  const optionComponents = {
    upskill: UpskillAndGrow,
    pivot: PivotNewCareer,
    looking: LookingForAJob,
  };

  const options = ["upskill", "pivot", "looking"].map((key) => {
    const SpecificComponent = optionComponents[key];
    return {
      value: key,
      component: (
        <View style={styles.selectable}>
          <SpecificComponent />
        </View>
      ),
    };
  });

  return (
    <View style={styles.container}>
      <View style={styles.smallerContainer}>
        <CustomText bold="true" size="xl" text="Current Occupation" style={styles.title} />
        <CustomTextInput onFocus={onFocus} placeholder={currentOccupation} onChangeText={setCurrentOccupation} />
      </View>
      <View style={styles.smallerContainer}>
        <CustomText bold="true" size="xl" text="Career Goal" style={styles.title} />
        <View>
          <SelectableList
            items={options}
            selectedItem={selectedCareerGoal}
            onSelect={(item) => setSelectedCareerGoal(item)}
          />
        </View>
      </View>
      <View style={styles.smallerContainer}>
        <CustomText weight="bold" size="l" text="Active Career Path" style={styles.title} />
        <View style={styles.activePathRow}>
          <View style={styles.activePathContainer}>
            {activePath ? (
              <>
                <GreenCircle style={{ paddingRight: 8 }} />
                <CustomText
                  weight="bold"
                  size={isLarge ? "s" : "xs"}
                  text="Active Career Path:"
                  style={{ paddingRight: 8 }}
                />
                <CustomText text={activePath} weight="bold" size={isLarge ? "s" : "xs"} style={{ color: "#737373" }} />
              </>
            ) : (
              <>
                <CustomText
                  text="No active career path"
                  weight="bold"
                  size={isLarge ? "s" : "xs"}
                  style={{ color: "#667085" }}
                />
              </>
            )}
          </View>
          {activePath && (
            <CustomButton styleType="danger" text="Remove" leftIcon={<CloseIcon fill="#fff" />} onPress={removePath} />
          )}
        </View>
        {activePath && (
          <CustomText
            size="xs"
            text="If you remove your active Career path, your Career page will be reset"
            style={{ color: "#737786" }}
          />
        )}
      </View>
      <View style={styles.smallerContainer}>
        <CustomButton
          styleType="primary"
          text="Save"
          style={styles.saveButton}
          onPress={() => savePress(currentOccupation, selectedCareerGoal, activePath)}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
  },
  smallerContainer: {
    paddingBottom: 10,
  },
  title: {
    paddingBottom: 10,
  },
  selectable: {
    alignItems: "center",
    shadowColor: "#000",
    padding: 10,
    borderRadius: 8,
    shadowOffset: { width: 0, height: 8 },
    shadowOpacity: 0.08,
    shadowRadius: 4,
    elevation: 5,
  },
  activePathRow: {
    flexWrap: "wrap",
    flexDirection: "row",
    alignContent: "center",
    marginBottom: 10,
    width: "100%",
  },
  activePathContainer: {
    flexWrap: "wrap",
    flexDirection: "row",
    borderRadius: 100,
    shadowRadius: 2,
    backgroundColor: "#FFFFFF",
    alignItems: "center",
    paddingHorizontal: 10,
    marginRight: 24,
    paddingVertical: 8,
    marginVertical: 8,
  },
  saveButton: {
    maxWidth: "15%",
    marginTop: 8,
  },
});

export default EditCareerDetails;
