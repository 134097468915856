import React from "react";
import { View, StyleSheet } from "react-native";
import CustomText from "../../common/general/CustomText/CustomText";

const Placeholder = ({ title, subtitle }) => {
  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <View style={styles.textContainer}>
          <CustomText text={title} size="xs" textType="display" style={styles.title} />
          <CustomText text={subtitle} size="xs" style={styles.subtitle} />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 20,
    backgroundColor: "#ffffff",
    elevation: 4,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 5 },
    shadowOpacity: 0.05,
    shadowRadius: 16,
    marginTop: 40,
    marginBottom: 20,
    padding: 36,
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    alignItems: "center",
    justifyContent: "center",
  },
  textContainer: {
    alignItems: "center",
    justifyContent: "flex-start",
  },
  title: {
    textAlign: "center",
  },
  subtitle: {
    textAlign: "center",
    maxWidth: 280,
  },
});

export default Placeholder;
