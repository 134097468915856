import React from "react";
import { View, StyleSheet } from "react-native";
import { BackArrowIcon } from "../../svgIcons";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";
import CustomButton from "../../common/general/CustomButton/CustomButton";

function BackButton({ onPress }) {
  return (
    <CustomButton
      leftIcon={
        <View style={styles.row}>
          <BackArrowIcon />
          <CustomSpacing type="horizontal" size="s" />
        </View>
      }
      text="Back"
      styleType="transparent"
      onPress={onPress}
      style={styles.button}
    />
  );
}

const styles = StyleSheet.create({
  button: {
    alignSelf: "center",
  },
  row: {
    flexDirection: "row",
  },
});

export default BackButton;
