import { useState, useRef } from "react";
import { View, StyleSheet } from "react-native";
import CustomModal from "../../common/general/CustomModal/CustomModal";
import CustomText from "../../common/general/CustomText/CustomText";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";
import CustomTextInput from "../../common/general/CustomTextInput/CustomTextInput";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import Loader from "../../common/status/Loader/Loader";
import AsyncStorage from "@react-native-async-storage/async-storage";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import { sendUserIdEmail } from "../../../services";
import { usePostHog } from "posthog-js/react";

function FeedbackModal({ visible, onClose }) {
  const posthog = usePostHog();
  const { isMedium } = useResponsiveScreen();
  const [responseInput, setResponseInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleClose = () => {
    setResponseInput("");
    setIsLoading(false);
    setErrorMessage("");
    onClose();
  };

  const handleTextChange = (text) => {
    setResponseInput(text);
  };

  const handleSubmit = async () => {
    setErrorMessage("");
    setIsLoading(true);
    if (!responseInput || responseInput.length < 1) {
      setErrorMessage("Please enter a valid response.");
      setIsLoading(false);
      return;
    }

    const token = await AsyncStorage.getItem("token");

    try {
      await sendUserIdEmail(token, responseInput);
      posthog?.capture("feedback_sent", {
        message: responseInput,
      });
      return handleClose();
    } catch (error) {
      setIsLoading(false);
      setErrorMessage("Failed to send feedback. Please try again.");
      return;
    }
  };

  const inputRef = useRef(null);

  const handleShow = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <CustomModal
      visible={visible}
      onRequestClose={handleClose}
      showCloseButton={true}
      exitFill="#BBB"
      title="Report Bug or Request a Feature"
      onShow={handleShow}>
      <View style={{ ...styles.bodyContainer, padding: isMedium ? 12 : 0 }}>
        <CustomText
          text="Please describe the issue or feature in detail, including steps to reproduce if reporting a bug. Thank you for your input!"
          size="s"
        />
        <CustomSpacing type="vertical" size="s" />
        {errorMessage ? (
          <>
            <CustomText text={errorMessage} size="xs" style={styles.failedText} />
            <CustomSpacing type="vertical" size="s" />
          </>
        ) : (
          <CustomSpacing type="vertical" size="m" />
        )}
        {isLoading ? (
          <View style={styles.loadingContainer}>
            <Loader />
          </View>
        ) : (
          <CustomTextInput
            placeholder="Please describe the bug ..."
            multiline
            numberOfLines={6}
            onChangeText={handleTextChange}
            value={responseInput}
            autoFocus={true}
            inputRef={inputRef}
          />
        )}
        <CustomSpacing type="vertical" size="m" />
        <CustomButton
          text={isLoading ? "Sending..." : "Submit"}
          styleType="primary"
          onPress={handleSubmit}
          disabled={isLoading}
        />
      </View>
    </CustomModal>
  );
}

const styles = StyleSheet.create({
  bodyContainer: {
    flex: 1,
  },
  loadingContainer: {
    alignItems: "center",
    minHeight: 118,
  },
  failedText: {
    color: "red",
  },
});

export default FeedbackModal;
