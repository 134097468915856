import * as React from "react";
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg";

function VideoPlay(props) {
  return (
    <Svg width={12} height={12} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <G clipPath="url(#clip0_456_3539)">
        <Path
          d="M8.25 6a.562.562 0 01-.242.463L5.57 8.15a.563.563 0 01-.883-.463V4.313a.563.563 0 01.883-.462l2.438 1.687A.563.563 0 018.25 6zm2.813 0A5.062 5.062 0 11.938 6a5.062 5.062 0 0110.124 0zM9.937 6a3.938 3.938 0 10-7.875 0 3.938 3.938 0 007.876 0z"
          fill="#fff"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_456_3539">
          <Path fill="#fff" d="M0 0H12V12H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default VideoPlay;
