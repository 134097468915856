import { useEffect, useState, useCallback } from "react";
import { usePostHog } from "posthog-js/react";
import { actions, useAppState } from "../contexts/AppStateContext";
import { useFocusEffect } from "@react-navigation/native";
import useResponsiveScreen from "../hooks/useResponsiveScreen";
import { StyleSheet, View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import DefaultLayout from "../layouts/DefaultLayout";
import CustomText from "../components/common/general/CustomText/CustomText";
import CustomButton from "../components/common/general/CustomButton/CustomButton";
import CustomSpacing from "../components/common/layout/CustomSpacing/CustomSpacing";
import ActiveCardListContainer from "../containers/dashboard/ActiveCardListContainer/ActiveCardListContainer";
import SuggestionsListContainer from "../containers/dashboard/SuggestionsListContainer/SuggestionsListContainer";
import TaskListContainer from "../containers/tasks/TaskListContainer/TaskListContainer";
import TaskFormModalContainer from "../containers/tasks/TaskFormModalContainer/TaskFormModalContainer";
import { useMeQuery, useRecentTodosQuery, useSchoolsQuery } from "../graphql/generated/graphql";
import Loader from "../components/common/status/Loader/Loader";
import ErrorLoader from "../components/common/status/ErrorLoader/ErrorLoader";
import { MotiView } from "moti";
import { useAppConfig } from "../AppConfigProvider";
import { useAuth } from "../hooks/useAuth";

import { useNavigation } from "@react-navigation/native";
import AssignmentCardListContainer from "../containers/dashboard/AssignmentCardListContainer/AssignmentCardListContainer";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { listAllAssignments } from "../services";
import OnboardModalContainer from "../containers/onboarding/OnboardModalContainer/OnboardModalContainer";

function DashboardView() {
  const posthog = usePostHog();
  const { dispatch } = useAppState();
  const { isMedium } = useResponsiveScreen();
  const appConfig = useAppConfig();
  const { isAuthenticated } = useAuth();
  const navigation = useNavigation();

  const [taskFormModalVisible, setTaskFormModalVisible] = useState(false);
  const [welcomeModalVisible, setWelcomeModalVisible] = useState(false);

  const { data, loading, error } = useRecentTodosQuery({});
  const { data: schoolsData, loading: schoolsLoading } = useSchoolsQuery();

  const { data: meData, loading: meLoading, refetch } = useMeQuery({});

  const showActiveList = true;
  const isLMS = appConfig.isLMS || false;

  const [allAssignments, setAllAssignments] = useState([]);
  const [assignmentsLoading, setAssignmentsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setAssignmentsLoading(true);
      if (!schoolsLoading && schoolsData.getSchools?.length > 0) {
        let assignments = [];
        for (let i = 0; i < schoolsData.getSchools?.length; i++) {
          const integrationId = schoolsData.getSchools[i].id;
          const token = await AsyncStorage.getItem("token");
          const data = await listAllAssignments(token, integrationId);
          const datamap = data.map((assignment) => ({
            ...assignment,
            type: "assignment",
            integrationId,
          }));

          assignments.push(...datamap);
        }

        assignments = assignments.filter((assignment) => assignment.due_date);
        const currentDate = new Date();
        const next7Days = new Date(currentDate.getTime() + 7 * 24 * 60 * 60 * 1000);
        assignments = assignments.filter((assignment) => {
          const dueDate = new Date(assignment.due_date);
          return dueDate >= currentDate && dueDate <= next7Days;
        });
        assignments.sort((a, b) => {
          if (!a.due_date && !b.due_date) {
            return 0;
          } else if (!a.due_date) {
            return 1;
          } else if (!b.due_date) {
            return -1;
          } else {
            return new Date(a.due_date) - new Date(b.due_date);
          }
        });
        setAllAssignments(assignments);
      }
      setAssignmentsLoading(false);
    };
    fetchData();
  }, [schoolsData, schoolsLoading]);

  useFocusEffect(
    useCallback(() => {
      refetch();
      if (meData?.me?.is_onboarded && isLMS) {
        if (schoolsData?.getSchools?.length === 0) {
          setWelcomeModalVisible(true);
        }
      } else if (!isLMS && isAuthenticated && meData?.me?.isEmailVerified && meData?.me?.is_onboarded) {
        const onboardingModalsArray = meData?.me?.modal_onboarding?.split(",");
        const isWelcomeModalShown = onboardingModalsArray?.includes("welcome");
        if (!isWelcomeModalShown) {
          setWelcomeModalVisible(true);
        }
      }
    }, [
      isLMS,
      schoolsData?.getSchools,
      isAuthenticated,
      meData?.me?.is_onboarded,
      meData?.me?.isEmailVerified,
      meData?.me?.modal_onboarding,
    ])
  );

  useEffect(() => {
    posthog?.capture("Home Page Visited");
  }, []);

  const handleTaskFormModalOpen = () => {
    dispatch({
      type: actions.SET_META,
      payload: { keypressIsListening: false },
    });
    setTaskFormModalVisible(true);
  };

  const handleTaskFormModalClose = () => {
    dispatch({
      type: actions.SET_META,
      payload: { keypressIsListening: true },
    });
    setTaskFormModalVisible(false);
  };

  const handleViewAllAssignments = () => {
    navigation.navigate("Coursework");
  };

  const handleViewAllTasksPress = () => {
    navigation.navigate("Productivity");
  };

  const handleWelcomeModalClose = () => {
    setWelcomeModalVisible(false);
  };

  if (loading) {
    return (
      <DefaultLayout>
        <View style={styles.loaderContainer}>
          <Loader />
        </View>
      </DefaultLayout>
    );
  }
  if (error) {
    return (
      <DefaultLayout>
        <View style={styles.loaderContainer}>
          <ErrorLoader message="Something went wrong! Try reloading the page." />
        </View>
      </DefaultLayout>
    );
  }

  return (
    <DefaultLayout style={{ maxWidth: 790, margin: "auto" }}>
      {isMedium && (
        <MotiView
          from={{
            opacity: 0,
            translateY: 20,
            scale: 1,
          }}
          animate={{
            opacity: 1,
            translateY: 0,
            scale: 1,
          }}
          delay={10}
          transition={{ type: "timing", duration: 500 }}>
          <View style={styles.headerContainer}>
            <CustomText
              text={`Hi ${meData?.me.name}`}
              textType="display"
              size="m"
              weight={isMedium ? "bold" : "ultraBold"}
            />
            <CustomText
              text="Here is what you have going on"
              textType="text"
              size={isMedium ? "xxl" : "xl"}
              style={{ color: "#8F8F8F" }}
            />
          </View>
        </MotiView>
      )}
      {isMedium && <CustomSpacing type="vertical" size="xl" />}

      <ScrollView style={[styles.scrollView, isMedium ? {} : {}]} showsVerticalScrollIndicator={false}>
        {isMedium && <CustomSpacing type="vertical" size="xl" />}

        <SuggestionsListContainer />

        {isMedium && <CustomSpacing type="vertical" size="xl" />}
        {isMedium && <CustomSpacing type="vertical" size="xl" />}

        {!schoolsLoading && schoolsData.getSchools?.length > 0 && (
          <>
            <MotiView
              from={{
                opacity: 0,
                translateY: 20,
                scale: 1,
              }}
              animate={{
                opacity: 1,
                translateY: 0,
                scale: 1,
              }}
              delay={1000}
              transition={{ type: "timing", duration: 500 }}>
              <View style={styles.taskHeaderContainer}>
                <CustomText text="Assignments Due Soon" textType="display" size="xs" weight="bold" />
                <CustomButton
                  text="View all"
                  styleType="primaryTransparent"
                  onPress={handleViewAllAssignments}
                  textStyle={styles.viewAllButton}
                  bold
                />
              </View>
            </MotiView>
            <CustomSpacing type="vertical" size={isMedium ? "xl" : "s"} />
            {assignmentsLoading ? (
              <View style={styles.assignmentLoaderContainer}>
                <Loader />
              </View>
            ) : (
              <AssignmentCardListContainer Assignments={allAssignments} />
            )}

            {isMedium && <CustomSpacing type="vertical" size="xl" />}
            <CustomSpacing type="vertical" size={isMedium ? "xl" : "s"} />
          </>
        )}
        <MotiView
          from={{
            opacity: 0,
            translateY: 20,
            scale: 1,
          }}
          animate={{
            opacity: 1,
            translateY: 0,
            scale: 1,
          }}
          delay={1000}
          transition={{ type: "timing", duration: 500 }}>
          <View style={styles.taskHeaderContainer}>
            <CustomText text="Upcoming Tasks" textType="display" size="xs" weight="bold" />
            <CustomButton
              text="View all"
              styleType="primaryTransparent"
              onPress={handleViewAllTasksPress}
              textStyle={styles.viewAllButton}
              bold
            />
          </View>
        </MotiView>
        <CustomSpacing type="vertical" size="xl" />
        <TaskListContainer isDashboard tasks={data.recentTodos} onAddTask={handleTaskFormModalOpen} />
        <CustomSpacing type="vertical" size="xl" />
        <CustomSpacing type="vertical" size="xl" />

        {showActiveList && (
          <>
            <ActiveCardListContainer />
            <CustomSpacing type="vertical" size="xl" />
          </>
        )}
      </ScrollView>

      <TaskFormModalContainer visible={taskFormModalVisible} onClose={handleTaskFormModalClose} />
      {!meLoading && <OnboardModalContainer visible={welcomeModalVisible} onClose={handleWelcomeModalClose} />}
    </DefaultLayout>
  );
}

const styles = StyleSheet.create({
  loaderContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  assignmentLoaderContainer: {
    height: 260,
    justifyContent: "center",
    alignItems: "center",
  },
  scrollView: {
    height: 20,
  },
  headerContainer: {
    flexDirection: "column",
  },
  taskHeaderContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  viewAllButton: {
    fontFamily: "Inter",
    fontSize: 16,
    color: "#667085",
  },
});

export default DashboardView;
