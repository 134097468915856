import { StyleSheet, View } from "react-native";

import SourceCard from "../SourceCard/SourceCard";

function SourcesList({ sources = [] }) {
  return (
    <View horizontal={false} height={200} showsHorizontalScrollIndicator={false} style={styles.scrollView}>
      {sources.map((source, index) => (
        <SourceCard key={index} title={source?.title} description={source?.description} />
      ))}
    </View>
  );
}

const styles = StyleSheet.create({});

export default SourcesList;
