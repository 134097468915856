import React, { useState, useEffect } from "react";
import { View, StyleSheet } from "react-native";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import CustomText from "../../common/general/CustomText/CustomText";
import CustomTextInput from "../../common/general/CustomTextInput/CustomTextInput";
import PriorityIndicator from "../PriorityIndicator/PriorityIndicator";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";
import SelectableList from "../../common/form/SelectableList/SelectableList";
import DatePickerButton from "../../general/DatePickerButton/DatePickerButton";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import { Checked, UnChecked } from "../../svgs/common";
import RepeatTaskForm from "../RepeatTaskForm/RepeatTaskForm";

function TaskForm({
  title,
  description,
  priority,
  startDate,
  onChangeTitle,
  onChangeDescription,
  onSelectPriority,
  onDatePick,
  onSubmit,
  isEdit,
  repeat,
  setRepeat,
  timePeriod,
  onSelectedTimePeriodChange,
  repeatEvery,
  onDropdownChange,
  selectedDay,
  onSelectedDayChange,
  dayOfMonth,
  onDayOfMonthChange,
  timeValue,
  setTimeValue,
  dayOfMonthError,
}) {
  const { isMedium } = useResponsiveScreen();
  const inputLabelTextType = "text";
  const inputLabelTextSize = "s";
  const inputLabelTextColor = isMedium ? {} : { color: "#182230" };
  const priorities = ["low", "medium", "high"].map((item) => ({
    value: item,
    component: (
      <View style={[styles.priority, { paddingHorizontal: isMedium ? 10 : 5 }]}>
        <PriorityIndicator priority={item} />
        {isMedium && (
          <CustomText
            text={capitalizeFirstLetter(item)}
            size={inputLabelTextSize}
            textType={inputLabelTextType}
            style={inputLabelTextColor}
            weight="ultraBold"
          />
        )}
      </View>
    ),
  }));

  const [showRepeatTaskForm, setShowRepeatTaskForm] = useState(repeat);

  useEffect(() => {
    setShowRepeatTaskForm(repeat);
  }, [repeat]);

  const toggleRepeatTaskForm = () => {
    setShowRepeatTaskForm(!showRepeatTaskForm);
    setRepeat(!repeat);
  };

  return (
    <View style={isMedium ? styles.container : {}}>
      <CustomText
        text="Task Title"
        size={inputLabelTextSize}
        textType={inputLabelTextType}
        style={inputLabelTextColor}
        weight="ultraBold"
      />
      <CustomSpacing type="vertical" size="s" />
      <CustomTextInput
        placeholder={"Give your task a name..."}
        autoFocus={true}
        required
        value={title}
        onChangeText={(text) => onChangeTitle(text)}
      />
      <CustomSpacing type="vertical" size="m" />
      <CustomText
        text="Description"
        size={inputLabelTextSize}
        textType={inputLabelTextType}
        style={inputLabelTextColor}
        weight="ultraBold"
      />
      <CustomSpacing type="vertical" size="s" />
      <CustomTextInput
        placeholder={"(Optional) Add a description for your task..."}
        multiline
        maxLength={90}
        numberOfLines={4}
        value={description}
        onChangeText={(text) => onChangeDescription(text)}
      />
      <CustomSpacing type="vertical" size="m" />
      <CustomText
        text="Select a Priority"
        size={inputLabelTextSize}
        textType={inputLabelTextType}
        style={inputLabelTextColor}
        weight="ultraBold"
      />
      <CustomSpacing type="vertical" size="xs" />
      <SelectableList items={priorities} selectedItem={priority} onSelect={(priority) => onSelectPriority(priority)} />
      <CustomSpacing type="vertical" size="m" />
      <CustomText
        text="Select a Due Date"
        size={inputLabelTextSize}
        textType={inputLabelTextType}
        style={inputLabelTextColor}
        weight="ultraBold"
      />
      <CustomSpacing type="vertical" size="xs" />
      <View style={isMedium ? styles.buttonWrapper : { width: 140 }}>
        <DatePickerButton date={startDate.toLocaleDateString()} onPress={onDatePick} />
      </View>
      <CustomSpacing type="vertical" size="xs" />

      <View style={styles.repeatRow}>
        <CustomText
          text="Repeat Task?"
          size={inputLabelTextSize}
          textType={inputLabelTextType}
          style={inputLabelTextColor}
          weight="ultraBold"
        />
        <CustomButton
          styleType="transparent"
          leftIcon={repeat ? <Checked /> : <UnChecked />}
          onPress={toggleRepeatTaskForm}
        />
      </View>

      <CustomSpacing type="vertical" size="xs" />

      {showRepeatTaskForm && (
        <RepeatTaskForm
          timePeriod={timePeriod}
          onSelectedTimePeriodChange={onSelectedTimePeriodChange}
          repeatEvery={repeatEvery}
          onDropdownChange={onDropdownChange}
          selectedDay={selectedDay}
          onSelectedDayChange={onSelectedDayChange}
          showTimeInput={false}
          dayOfMonth={dayOfMonth}
          onDayOfMonthChange={onDayOfMonthChange}
          timeValue={timeValue}
          setTimeValue={setTimeValue}
          dayOfMonthError={dayOfMonthError}
        />
      )}

      <CustomSpacing type="vertical" size="l" />
      <View style={isMedium ? styles.buttonWrapper : {}}>
        <CustomButton
          text={isEdit ? "Update Task" : "Add Task"}
          styleType="primary"
          onPress={onSubmit}
          textStyle={isMedium ? {} : { fontSize: 14 }}
          bold={!isMedium}
        />
      </View>
    </View>
  );
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const styles = StyleSheet.create({
  container: {
    marginVertical: 15,
    paddingVertical: 15,
    marginHorizontal: 3,
    paddingHorizontal: 3,
  },
  priority: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    shadowColor: "#000",
    paddingVertical: 10,
    borderRadius: 8,
    shadowOffset: { width: 0, height: 8 },
    shadowOpacity: 0.08,
    shadowRadius: 16,
    elevation: 5,
    gap: 15,
  },
  buttonWrapper: {
    width: 190,
  },
  repeatRow: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 6,
  },
});

export default TaskForm;
