import { useState, useCallback } from "react";
import { View, StyleSheet } from "react-native";
import useResponsiveScreen from "../hooks/useResponsiveScreen";
import DefaultLayout from "../layouts/DefaultLayout";
import JournalChatContainer from "../containers/journal/JournalChatContainer/JournalChatContainer";
import CustomText from "../components/common/general/CustomText/CustomText";
import CustomSpacing from "../components/common/layout/CustomSpacing/CustomSpacing";
import DatePickerButton from "../components/general/DatePickerButton/DatePickerButton";
import { DatePickerModal } from "react-native-paper-dates";
import { MotiView } from "moti";

function JournalView() {
  const { isMedium } = useResponsiveScreen();

  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);
  const [pickerDate, setPickerDate] = useState(undefined);

  const now = new Date();
  const localDate = new Date(now.getTime() - now.getTimezoneOffset() * 60000);
  const [date, setDate] = useState(localDate.toISOString().split("T")[0]);

  const handleDatePick = () => {
    setIsDatePickerVisible(true);
  };

  const handleDatePickerDismiss = useCallback(() => {
    setIsDatePickerVisible(false);
  }, [setIsDatePickerVisible]);

  const handleDatePickerConfirm = useCallback(
    (params) => {
      selectedDateHandler(params.date);
      setIsDatePickerVisible(false);
      setPickerDate(params.date);
    },
    [isDatePickerVisible, setIsDatePickerVisible]
  );

  const selectedDateHandler = (d) => {
    const selectedDate = new Date(d.getTime() - d.getTimezoneOffset() * 60000);
    const parsedDate = selectedDate.toISOString().split("T")[0];
    setDate(parsedDate);
  };

  return (
    <DefaultLayout>
      <MotiView
        from={{
          opacity: 0,
          translateY: 20,
          scale: 1,
        }}
        animate={{
          opacity: 1,
          translateY: 0,
          scale: 1,
        }}
        delay={10}
        transition={{ type: "timing", duration: 500 }}>
        <View style={styles.headerContainer}>
          <CustomText text="Journal" size={isMedium ? "l" : "xs"} textType="display" weight="ultraBold" />
          <DatePickerButton date={date} onPress={handleDatePick} />
        </View>
        <CustomSpacing type="vertical" size="m" />
      </MotiView>
      <MotiView
        from={{
          opacity: 0,
          translateY: 20,
          scale: 1,
        }}
        animate={{
          opacity: 1,
          translateY: 0,
          scale: 1,
        }}
        style={{ flex: 1 }}
        delay={500}
        transition={{ type: "timing", duration: 600 }}>
        <JournalChatContainer date={date} />
      </MotiView>
      <DatePickerModal
        locale="en"
        mode="single"
        label="Select date"
        visible={isDatePickerVisible}
        onDismiss={handleDatePickerDismiss}
        date={pickerDate}
        onConfirm={handleDatePickerConfirm}
      />
    </DefaultLayout>
  );
}

const styles = StyleSheet.create({
  headerContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
});

export default JournalView;
