import { useAppState, actions } from "../../../contexts/AppStateContext";
import { useAuth } from "../../../hooks/useAuth";
import { usePostHog } from "posthog-js/react";
import { useNavigation } from "@react-navigation/native";
import { useSetOnboardingStatusMutation } from "../../../graphql/generated/graphql";
import OnboardMenu from "../../../components/onboarding/OnboardMenu/OnboardMenu";

function OnboardMenuContainer() {
  const { dispatch } = useAppState();
  const { logout } = useAuth();
  const posthog = usePostHog();
  const navigation = useNavigation();

  const [setOnboardingStatus] = useSetOnboardingStatusMutation();

  const handleLogoutPress = () => {
    logout();
  };

  const handleSkipPress = async () => {
    posthog?.capture("Onboarding Skipped");
    await setOnboardingStatus({
      variables: {
        isOnboarded: true,
      },
    });

    fbq("track", "StartTrial", {
      value: "0.00",
      currency: "USD",
      predicted_ltv: "100.00",
    });
    navigation.navigate("My Day");

    dispatch({
      type: actions.SET_META,
      payload: { isOnboarded: true },
    });
  };

  return <OnboardMenu onLogout={handleLogoutPress} onSkip={handleSkipPress} />;
}

export default OnboardMenuContainer;
