import React, { useState, useCallback, useEffect } from "react";
import { StyleSheet } from "react-native";
import { DatePickerModal, TimePickerModal } from "react-native-paper-dates";
import EventForm from "../../../components/calendar/EventForm/EventForm";
import { createCalendarEvent } from "../../../services";
import AsyncStorage from "@react-native-async-storage/async-storage";
import CustomText from "../../../components/common/general/CustomText/CustomText";

const EventFormContainer = ({ onComplete, onEventCreated }) => {
  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);
  const [isTimePickerVisible, setIsTimePickerVisible] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [location, setLocation] = useState("");
  const [dateType, setDateType] = useState(""); // "start" or "end"
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date(Date.now() + 30 * 60000));
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [allDay, setAllDay] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [startDateLabel, setStartDateLabel] = useState("Date");
  const [showEndDateButton, setShowEndDateButton] = useState(false);

  const handleTitleChange = (title) => setTitle(title);
  const handleDescriptionChange = (description) => setDescription(description);
  const handleLocationChange = (location) => setLocation(location);

  const handleDatePick = (type) => {
    setDateType(type);
    setIsDatePickerVisible(true);
  };

  const handleTimePick = (type) => {
    setDateType(type);
    setIsTimePickerVisible(true);
  };

  const handleToggleAllDay = () => setAllDay(!allDay);

  const handleDatePickerConfirm = useCallback(
    (params) => {
      setIsDatePickerVisible(false);
      if (dateType === "start") {
        setStartDate(params.date);
        if (params.date > endDate) {
          setEndDate(params.date);
        }
      } else {
        setEndDate(params.date);
      }
    },
    [dateType, endDate]
  );

  const handleTimePickerConfirm = useCallback(
    (params) => {
      setIsTimePickerVisible(false);
      const newTime = new Date(startDate); // Use startDate as the base to set the time
      newTime.setHours(params.hours, params.minutes);

      if (dateType === "start") {
        setStartTime(newTime);
        let newEndTime = new Date(newTime.getTime() + 30 * 60000); // Adds 30 minutes to start time

        if (newEndTime.getDate() > newTime.getDate()) {
          // If end time rolls over to the next day
          setEndDate(new Date(newTime.getFullYear(), newTime.getMonth(), newTime.getDate() + 1));
          setShowEndDateButton(true);
          setStartDateLabel("Start Date");
        } else {
          // If time is adjusted such that end time no longer crosses into the next day
          setEndDate(startDate); // Revert the end date to the start date
          setShowEndDateButton(false);
          setStartDateLabel("Date");
        }

        setEndTime(newEndTime);
      } else {
        setEndTime(newTime);
      }
    },
    [dateType, startDate, setShowEndDateButton, setStartDateLabel]
  );

  useEffect(() => {
    if (allDay) {
      setShowEndDateButton(true); // Always show EndDate when AllDay is checked
      setStartDateLabel("Start Date"); // Label as "Start Date"
    } else {
      // This checks if startTime and endTime indicate the need to show the endDate button
      // Adjust logic here as necessary based on your app's specific needs
      const showEndDate = endDate > startDate || (startTime.getHours() === 23 && startTime.getMinutes() >= 30);
      setShowEndDateButton(showEndDate);
      setStartDateLabel(showEndDate ? "Start Date" : "Date"); // Adjust label based on endDate visibility
    }
  }, [allDay, startDate, endDate, startTime]);

  const handleSubmit = async () => {
    setErrorMessage("");
    if (!title || !startDate || (!allDay && !startTime)) {
      setErrorMessage("Please fill in all required fields.");
      return;
    }

    let startDateTime = new Date(startDate);
    let endDateTime = new Date(endDate);

    if (!allDay) {
      startDateTime.setHours(startTime.getHours(), startTime.getMinutes());
      endDateTime.setHours(endTime.getHours(), endTime.getMinutes());
    } else {
      startDateTime.setHours(0, 0, 0, 0);
      endDateTime.setHours(23, 59, 59, 999); // Set to the end of the day
    }

    if (endDateTime < startDateTime) {
      setErrorMessage("The end date/time cannot be before the start date/time.");
      return;
    }

    let eventDetails;
    if (allDay) {
      const formattedStartDate = startDateTime.toISOString().split("T")[0];
      const formattedAdjustedEndDate = endDateTime.toISOString().split("T")[0];

      eventDetails = {
        summary: title,
        description,
        location,
        start: { date: formattedStartDate },
        end: { date: formattedAdjustedEndDate },
      };
    } else {
      const startDateTimeISO = startDateTime.toISOString();
      const endDateTimeISO = endDateTime.toISOString();
      eventDetails = {
        summary: title,
        description,
        location,
        start: {
          dateTime: startDateTimeISO,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        end: {
          dateTime: endDateTimeISO,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      };
    }

    try {
      const token = await AsyncStorage.getItem("token");
      await createCalendarEvent(token, eventDetails);
      onEventCreated();
      onComplete();
    } catch (error) {
      console.error("Error creating event:", error);
      setErrorMessage("Failed to create event. Please try again.");
    }
  };

  return (
    <>
      <EventForm
        title={title}
        description={description}
        startDate={startDate}
        endDate={endDate}
        startTime={startTime}
        endTime={endTime}
        allDay={allDay}
        onChangeTitle={handleTitleChange}
        onChangeDescription={handleDescriptionChange}
        onChangeLocation={handleLocationChange}
        onToggleAllDay={handleToggleAllDay}
        onDatePick={handleDatePick}
        onTimePick={handleTimePick}
        onSubmit={handleSubmit}
        showEndDateButton={showEndDateButton}
        startDateLabel={startDateLabel}
      />
      {isDatePickerVisible && (
        <DatePickerModal
          mode="single"
          visible={isDatePickerVisible}
          onDismiss={() => setIsDatePickerVisible(false)}
          date={dateType === "start" ? startDate : endDate}
          onConfirm={handleDatePickerConfirm}
        />
      )}
      {isTimePickerVisible && (
        <TimePickerModal
          visible={isTimePickerVisible}
          onDismiss={() => setIsTimePickerVisible(false)}
          onConfirm={handleTimePickerConfirm}
          hours={dateType === "start" ? startTime.getHours() : endTime.getHours()}
          minutes={dateType === "start" ? startTime.getMinutes() : endTime.getMinutes()}
          label="Select Time"
        />
      )}
      {errorMessage ? <CustomText style={styles.errorText} text={errorMessage} /> : null}
    </>
  );
};

const styles = StyleSheet.create({
  errorText: {
    color: "red",
    marginTop: 10,
    textAlign: "center",
  },
});

export default EventFormContainer;
