import React from "react";
import { StyleSheet, View } from "react-native";
import CodeHighlighter from "react-native-code-highlighter";
import { atomOneDarkReasonable } from "react-syntax-highlighter/dist/esm/styles/hljs";

const CustomSyntaxHighlighter = ({ content, language }) => {
  return (
    <View style={styles.container}>
      <CodeHighlighter
        hljsStyle={atomOneDarkReasonable}
        containerStyle={styles.codeContainer}
        textStyle={styles.text}
        language={language}>
        {content}
      </CodeHighlighter>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderTopEndRadius: 0,
    borderTopStartRadius: 0,
    borderBottomEndRadius: 4,
    borderBottomStartRadius: 4,
    overflow: "hidden",
    backgroundColor: "#000B33",
  },
  codeContainer: {
    padding: 16,
    minWidth: "100%",
  },
  text: {
    fontSize: 16,
  },
});

export default CustomSyntaxHighlighter;
