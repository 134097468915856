import { useEffect, useState } from "react";
import { useAppConfig } from "../../../AppConfigProvider";
import { usePostHog } from "posthog-js/react";
import { useNavigation } from "@react-navigation/native";
import { useAppState, actions } from "../../../contexts/AppStateContext";
import { useLoginMutation, MeDocument } from "../../../graphql/generated/graphql";
import { useAuth } from "../../../hooks/useAuth";
import LoginForm from "../../../components/auth/LoginForm/LoginForm";

function LoginFormContainer() {
  const posthog = usePostHog();
  const { login } = useAuth();
  const appConfig = useAppConfig();
  const navigation = useNavigation();
  const { dispatch } = useAppState();
  const [loginMutation] = useLoginMutation({
    refetchQueries: [{ query: MeDocument }],
  });

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const domainKey = appConfig.key;

  useEffect(() => {
    posthog?.capture("Login Page Visited");
  }, []);

  const handleEmailChangeText = (text) => {
    setEmail(text);
    validateEmail(text);
  };

  const handlePasswordChangeText = (text) => {
    setPassword(text);
  };

  const handleSubmit = async () => {
    if (!isEmailValid) {
      setErrorMessage("Invalid email");
      return;
    }

    try {
      const result = await loginMutation({
        variables: { email: email.toLowerCase(), password },
      });

      if (result.data) {
        const token = result.data.login.token;
        login(token);

        setErrorMessage("");

        if (result.data.login.user.isEmailVerified) {
          window.location.href = "/";
        } else {
          dispatch({
            type: actions.SET_META,
            payload: { email },
          });
          navigation.navigate("Verify Email");
        }
      }
    } catch (error) {
      console.error("Authentication failed", error);
      setErrorMessage(error.message);
    }
  };

  const handleResetPasswordPress = () => {
    navigation.navigate("Reset Password");
  };

  const handleSignupPress = () => {
    navigation.navigate("Signup");
  };

  const validateEmail = (email) => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValid(pattern.test(email));
  };

  return (
    <LoginForm
      email={email}
      password={password}
      isValidEmail={isEmailValid}
      errorMessage={errorMessage}
      companionName={domainKey}
      onEmailChangeText={handleEmailChangeText}
      onPasswordChangeText={handlePasswordChangeText}
      onSubmit={handleSubmit}
      onResetPasswordPress={handleResetPasswordPress}
      onSignupPress={handleSignupPress}
    />
  );
}

export default LoginFormContainer;
