import { useState } from "react";
import { Platform } from "react-native";
import { useAppConfig } from "../../../AppConfigProvider";
import { useAppState, actions } from "../../../contexts/AppStateContext";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { getEdlinkLoginUrl } from "../../../services";
import handleOpenLink from "../../../utils/handleOpenLink";
import IntegrationCardList from "../../../components/profile/IntegrationCardList/IntegrationCardList";
import GoogleConcentModalContainer from "../GoogleConcentModalContainer/GoogleConcentModalContainer";
import SMSOTPFormContainer from "../../auth/SMSOTPFormContainer/SMSOTPFormContainer";
import CustomModal from "../../../components/common/general/CustomModal/CustomModal";
import { CalendarConnectIcon, SMSConnectionIcon, SchoolConnectionIcon } from "../../../components/svgIcons";
import { useSchoolsQuery } from "../../../graphql/generated/graphql";

function IntegrationCardListContainer({ userData, refetchUserData }) {
  const appConfig = useAppConfig();
  const { dispatch } = useAppState();
  const { isMedium } = useResponsiveScreen();

  const [googleConsentModalOpen, setGoogleConsentModalOpen] = useState(false);
  const [smsModalOpen, setSmsModalOpen] = useState(false);

  const { data: schoolsData, loading: schoolsLoading } = useSchoolsQuery();

  const companionName = appConfig.companionName;

  const isConnectedGoogleCal = !!userData?.me?.googleCalendarAuthToken;
  const isConnectedSMS = !!userData?.me?.phoneNumber;
  const isConnectedSchool = !schoolsLoading && schoolsData?.getSchools.length > 0;

  const cards = [
    {
      id: 1,
      Icon: CalendarConnectIcon,
      header: "Google Calendar",
      body: `Allow ${companionName} to schedule and remind you of appointments.`,
      isConnected: isConnectedGoogleCal,
    },
    {
      id: 2,
      Icon: SMSConnectionIcon,
      header: "SMS",
      body: `Allow ${companionName} to stay in touch through periodic text messages.`,
      isConnected: isConnectedSMS,
    },
    {
      id: 3,
      Icon: SchoolConnectionIcon,
      header: "School",
      body: `Allow ${companionName} to connect with your school and assist learning.`,
      isConnected: isConnectedSchool,
    },
  ];

  const handleConnect = (id) => {
    switch (id) {
      case 1:
        handleConnectGoogleCal();
        break;
      case 2:
        handleConnectSMS();
        break;
      case 3:
        handleConnectSchool();
        break;
      default:
        break;
    }
  };

  const handleConnectGoogleCal = () => {
    setGoogleConsentModalOpen(true);
  };

  const handleCloseGoogleConsentModal = () => {
    setGoogleConsentModalOpen(false);

    refetchUserData();
  };

  const handleConnectSMS = () => {
    dispatch({
      type: actions.SET_META,
      payload: { keypressIsListening: false },
    });
    setSmsModalOpen(true);
  };

  const handleCloseSMSModal = () => {
    dispatch({
      type: actions.SET_META,
      payload: { keypressIsListening: true },
    });
    setSmsModalOpen(false);

    refetchUserData();
  };

  const handleConnectSchool = async () => {
    let newWindow;
    if (Platform.OS === "web") {
      newWindow = window.open("", "_blank");
    }

    const token = await AsyncStorage.getItem("token");

    getEdlinkLoginUrl(token).then(async (response) => {
      const url = response.loginUrl;
      await handleOpenLink(url, newWindow);
    });
  };

  return (
    <>
      <IntegrationCardList cards={cards} onConnect={handleConnect} />

      <GoogleConcentModalContainer visible={googleConsentModalOpen} onClose={handleCloseGoogleConsentModal} />

      <CustomModal
        visible={smsModalOpen}
        onRequestClose={handleCloseSMSModal}
        showCloseButton={true}
        style={{ width: isMedium ? "50%" : "90%" }}>
        <SMSOTPFormContainer onSuccess={handleCloseSMSModal} />
      </CustomModal>
    </>
  );
}

export default IntegrationCardListContainer;
