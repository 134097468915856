import React, { createContext, useContext } from "react";

// Create a Context
const AppConfigContext = createContext();

export const useAppConfig = () => useContext(AppConfigContext);

export const AppConfigProvider = ({ children, currentDomain, appConfig }) => {
  // No need for useState for appConfig or isLoading here

  const value = {
    ...appConfig,
    currentDomain,
  };

  return <AppConfigContext.Provider value={value}>{children}</AppConfigContext.Provider>;
};
