import React, { createContext, useState, useEffect } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import jwtDecode from "jwt-decode";
import { actions, useAppState } from "./AppStateContext";
import { useSessionQuery, useLogoutMutation } from "../graphql/generated/graphql";
import { usePostHog } from "posthog-js/react";

export const AuthContext = createContext({
  isAuthenticated: true,
  login: () => {},
  logout: () => {},
});

export const AuthProvider = ({ children, apolloClient }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const { dispatch } = useAppState();
  const posthog = usePostHog();

  const { data, loading, startPolling, stopPolling } = useSessionQuery({
    notifyOnNetworkStatusChange: false,
    fetchPolicy: "network-only",
    skip: !isAuthenticated,
  });

  const [logoutMutation] = useLogoutMutation();

  useEffect(() => {
    const checkToken = async () => {
      const token = await AsyncStorage.getItem("token");
      if (token) {
        const decoded = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
          // Token has expired
          posthog?.reset(true);
          logout();
        } else {
          posthog?.identify(decoded.userId);
          setIsAuthenticated(true);
        }
      } else {
        posthog?.reset(true);
        setIsAuthenticated(false);
      }
    };

    checkToken();
  }, []);

  useEffect(() => {
    if (!loading && data && !data.validateSession.isValid) {
      posthog?.reset(true);
      logout();
    }
  }, [data, loading]);

  useEffect(() => {
    const pollInterval = 60000;
    startPolling(pollInterval);

    return () => {
      stopPolling();
    };
  }, []);

  const login = async (token) => {
    try {
      await AsyncStorage.setItem("token", token);
      const decoded = jwtDecode(token);
      posthog?.identify(decoded.userId);
      setIsAuthenticated(true);
    } catch (error) {
      console.error("Error during login:", error);
    }
  };

  const logout = async () => {
    try {
      const result = await logoutMutation();

      if (!result?.data?.logout?.success) {
        throw new Error("Failed to logout");
      }

      await AsyncStorage.removeItem("token");
      posthog?.reset(true);
      setIsAuthenticated(false);

      if (apolloClient) {
        await apolloClient.clearStore();
      }

      dispatch({
        type: actions.RESET_STATE,
      });
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  return <AuthContext.Provider value={{ isAuthenticated, login, logout }}>{children}</AuthContext.Provider>;
};
