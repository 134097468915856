import React from "react";
import { View, Text, Pressable, StyleSheet } from "react-native";

import CustomText from "../../common/general/CustomText/CustomText";
import { formatDate } from "../../../utils/date";

function ItemCard({ title, dueDate, isHover, label, onPress, className }) {
  return (
    <View
      style={[
        styles.card,
        isHover && {
          backgroundColor: "#EAEFFE",
        },
      ]}>
      <Pressable style={styles.infoContainer} onPress={onPress}>
        <View style={styles.rightColContainer}>
          <View style={styles.tagContainer}>
            <View style={styles.tag}>
              <Text style={styles.tagText}>{label}</Text>
            </View>
            {className && (
              <View style={[styles.tag, { marginLeft: 5 }]}>
                <Text style={styles.tagText}>{className}</Text>
              </View>
            )}
          </View>
          <CustomText text={title} size="m" />
        </View>

        <CustomText text={formatDate(dueDate)} size="s" />
      </Pressable>
    </View>
  );
}

const styles = StyleSheet.create({
  card: {
    flex: 1,
    flexDirection: "column",
    padding: 20,
    backgroundColor: "#fff",
    borderTopColor: "#D9D9D9",
    borderTopWidth: 1,
    borderBottomColor: "#D9D9D9",
    borderBottomWidth: 1,
  },
  rightColContainer: {
    flexDirection: "column",
  },
  infoContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tagContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignContent: "center",
    marginVertical: 4,
  },
  tag: {
    justifyContent: "center",
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 4,
    paddingBottom: 4,
    backgroundColor: "#E6EBFF",
    borderRadius: 100,
    alignContent: "center",
  },
  tagText: {
    color: "#022CC4",
    fontSize: 10,
    fontWeight: "700",
    wordWrap: "break-word",
  },
});

export default ItemCard;
