import { Platform } from "react-native";
import { useAppConfig } from "../../../AppConfigProvider";
import AsyncStorage from "@react-native-async-storage/async-storage";
import UpgradeModal from "../../../components/general/UpgradeModal/UpgradeModal";
import { createCheckoutSession } from "../../../services";
import handleOpenLink from "../../../utils/handleOpenLink";

function UpgradeModalContainer({ visible, onClose }) {
  const appConfig = useAppConfig();
  const upgradeModalBody = appConfig.upgradeModalBody;
  const hasTrial = appConfig.externalIntegrations.stripe.hasTrial;
  const companionName = appConfig.companionName;
  const domainKey = appConfig.key;

  const handleUpgrade = async () => {
    let newWindow;
    if (Platform.OS === "web") {
      newWindow = window.open("", "_blank");
    }

    const token = await AsyncStorage.getItem("token");
    const data = await createCheckoutSession(token);

    const { url } = data;

    await handleOpenLink(url, newWindow);

    onClose();
  };

  return (
    <UpgradeModal
      visible={visible}
      companionName={companionName}
      domainKey={domainKey}
      message={upgradeModalBody}
      onUpgrade={handleUpgrade}
      onClose={onClose}
      buttonText={hasTrial ? "Start Your Free Trial" : "Upgrade to Pro"}
    />
  );
}

export default UpgradeModalContainer;
