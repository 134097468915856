import { StyleSheet, Pressable, View } from "react-native";
import React from "react";
import CustomText from "../../common/general/CustomText/CustomText";
import { SmallGreenCircle } from "../../svgs/common";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";

const ChatTitle = ({ title = "", isGeneralChatActive = true, onGeneralChatPress, onChatPress }) => {
  const { isMedium } = useResponsiveScreen();

  return (
    <View style={styles.floatingContainer}>
      {!!title && (
        <>
          {!isGeneralChatActive ? (
            <View
              style={[
                styles.floatingContentBase,
                isMedium ? styles.floatingContentDesktop : styles.floatingContentMobile,
              ]}>
              <View style={isMedium ? { marginRight: 8 } : { marginRight: 4 }}>
                <SmallGreenCircle />
              </View>
              <CustomText size="s" weight="bold" text={title} numberOfLines={1} ellipsizeMode="tail" />
            </View>
          ) : (
            <Pressable
              onPress={onChatPress}
              style={[styles.inactive, isMedium ? { marginRight: 22 } : { marginRight: 12 }]}>
              <CustomText
                text={title}
                size={isMedium ? "m" : "s"}
                textType="text"
                weight="medium"
                style={{ color: "#667085" }}
                numberOfLines={1}
                ellipsizeMode="tail"
              />
            </Pressable>
          )}
        </>
      )}
      {isGeneralChatActive ? (
        <View
          style={[styles.floatingContentBase, isMedium ? styles.floatingContentDesktop : styles.floatingContentMobile]}>
          <View style={isMedium ? { marginRight: 8 } : { marginRight: 4 }}>
            <SmallGreenCircle />
          </View>
          <CustomText size="s" weight="bold" text={"General Chat"} numberOfLines={1} ellipsizeMode="tail" />
        </View>
      ) : (
        <Pressable onPress={onGeneralChatPress} style={styles.inactive}>
          <CustomText
            text={"General Chat"}
            size={isMedium ? "m" : "s"}
            textType="text"
            weight="medium"
            style={{ color: "#667085" }}
            numberOfLines={1}
            ellipsizeMode="tail"
          />
        </Pressable>
      )}
    </View>
  );
};

export default ChatTitle;

const styles = StyleSheet.create({
  floatingContainer: {
    flexDirection: "row",
    alignItems: "center",
    flexShrink: 1,
    paddingHorizontal: 8,
  },
  floatingContentBase: {
    flexDirection: "row",
    alignItems: "center",
    maxWidth: 313,
    backgroundColor: "white",
    shadowColor: "#000",
    flexShrink: 1,
    shadowOffset: { width: 0, height: 8 },
    shadowOpacity: 0.08,
    shadowRadius: 16,
    elevation: 5,
    borderRadius: 360,
  },
  floatingContentDesktop: {
    marginRight: 22,
    paddingVertical: 12,
    paddingHorizontal: 20,
  },
  floatingContentMobile: {
    paddingVertical: 6,
    paddingHorizontal: 10,
  },
  inactive: {
    alignItems: "center",
    maxWidth: 313,
    flexShrink: 1,
    zIndex: 10000,
  },
});
