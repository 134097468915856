import * as ProLogos from "../../../svgs/proLogos";

function ProLogo({ name, ...props }) {
  switch (name) {
    case "channelview":
      return <ProLogos.ChannelViewProLogo {...props} />;
    case "houstoncc":
      return <ProLogos.HoustonCCProLogo {...props} />;
    case "mike":
      return <ProLogos.DailyDoseProLogo {...props} />;
    case "primer":
      return <ProLogos.PrimerProLogo {...props} />;
    case "capella":
      return <ProLogos.CapellaProLogo {...props} />;
    case "asu":
      return <ProLogos.ASUProLogo {...props} />;
    case "azcyber":
      return <ProLogos.AzCyberProLogo {...props} />;
    case "chemonics":
      return <ProLogos.ChemonicsProLogo {...props} />;
    case "axio":
      return <ProLogos.AxioProLogo {...props} />;
    case "academica":
      return <ProLogos.AcademicaProLogo {...props} />;
    case "imentor":
      return <ProLogos.IMentorProLogo {...props} />;
    case "cuesta":
      return <ProLogos.CuestaProLogo {...props} />;
    case "blocktraining":
      return <ProLogos.BlockProLogo {...props} />;
    case "umich":
      return <ProLogos.UMichProLogo {...props} />;
    case "belhaven":
      return <ProLogos.BelhavenProLogo {...props} />;
    case "quitumbe":
      return <ProLogos.QuitumbeProLogo {...props} />;
    default:
      return null;
  }
}

export default ProLogo;
