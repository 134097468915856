import apiClient from "./ApiService";

const createCheckoutSession = async (token) => {
  try {
    const response = await apiClient.post(
      `/create-checkout-session`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error creating checkout session:", error);
    throw error;
  }
};

const stripeManage = async (token) => {
  try {
    const response = await apiClient.post(
      `/stripe-manage`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error creating checkout session:", error);
    throw error;
  }
};

export { createCheckoutSession, stripeManage };
