import { View, StyleSheet, Image } from "react-native";
import CustomText from "../../../common/general/CustomText/CustomText";
import CustomButton from "../../../common/general/CustomButton/CustomButton";
import CustomSpacing from "../../../common/layout/CustomSpacing/CustomSpacing";
import { Box } from "../../../svgs/common";
import useResponsiveScreen from "../../../../hooks/useResponsiveScreen";
import CustomProgressBar from "../../../common/general/CustomProgressBar/CustomProgressBar";

const LearnTrackCard = ({
  name,
  description,
  onCardPress,
  completedTracks,
  totalTracks,
  isUserEnrolled,
  mastery,
  image,
}) => {
  const { isMedium } = useResponsiveScreen();
  const hasProgressBar = (completedTracks === 0 || completedTracks) && totalTracks;
  return (
    <View style={[styles.mainContainer, isMedium ? styles.mediumDimensions : styles.mobileDimensions]}>
      <View style={{ width: "100%" }}>
        <Image
          style={styles.image}
          source={image ? image : require("../../../../assets/onboardingModals/Experience.png")}
          resizeMode="cover"
        />
        {(totalTracks === 0 || totalTracks) && isMedium && (
          <View style={styles.unitsInfoBox}>
            <Box />
            <CustomText
              text={`${totalTracks} Lesson${totalTracks > 1 ? "s" : ""}`}
              size="xs"
              weight="ultraBold"
              styleType="primary"
            />
          </View>
        )}
        {!!mastery && (
          <View style={isMedium ? styles.progressBar : styles.mobileProgressBar}>
            {isMedium && hasProgressBar && (
              <>
                <CustomText
                  text={`${completedTracks} of ${totalTracks} Unit${totalTracks > 1 ? "s" : ""} Complete`}
                  size="xs"
                  weight="bold"
                  styleType="light"
                />
                <CustomSpacing type="vertical" size="xs" />
                <CustomSpacing type="vertical" size="xs" />
              </>
            )}
            <CustomProgressBar progress={mastery} />
          </View>
        )}
      </View>
      <View style={styles.bottomContainer}>
        <CustomText text={name} weight="bold" size="m" numberOfLines={2} ellipsizeMode="tail" />
        <CustomText text={description} size="s" style={{ color: "#667085" }} numberOfLines={3} ellipsizeMode="tail" />
        {isUserEnrolled || hasProgressBar ? (
          <CustomButton
            text="Resume Track"
            bold
            style={[styles.trackButton, { border: "2px solid #3E68FE" }]}
            textStyle={{ fontSize: 12 }}
            styleType="primaryLight"
            onPress={onCardPress}
          />
        ) : (
          <CustomButton
            text="View Track"
            styleType="primary"
            bold
            style={styles.trackButton}
            textStyle={{ fontSize: 12 }}
            onPress={onCardPress}
          />
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: "#fff",
    borderRadius: 10,
    shadowColor: "#000",
    shadowOffset: { width: 2, height: 6 },
    shadowOpacity: 0.06,
    shadowRadius: 15,
    elevation: 5,
    margin: 8,
  },
  mobileDimensions: {
    width: "96%",
    margin: 6,
  },
  mediumDimensions: {
    width: 360,
    margin: 8,
  },
  bottomContainer: {
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    paddingVertical: 14,
    paddingHorizontal: 20,
    textAlign: "flex-start",
    gap: 12,
    width: "100%",
  },
  image: {
    height: 250,
    width: "100%",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  trackButton: {
    padding: 8,
  },
  progressBar: {
    position: "absolute",
    left: 4,
    bottom: 7,
    elevation: 10,
    backgroundColor: "#000B33B2",
    width: "97%",
    paddingHorizontal: 6,
    paddingVertical: 8,
    borderRadius: 10,
  },
  mobileProgressBar: {
    position: "absolute",
    left: 0,
    bottom: 0,
    elevation: 10,
    backgroundColor: "#000B33B2",
    width: "100%",
    paddingHorizontal: 6,
    paddingVertical: 12,
  },
  unitsInfoBox: {
    position: "absolute",
    top: 9,
    left: 11,
    backgroundColor: "white",
    padding: 6,
    borderRadius: 100,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: 4,
  },
});

export default LearnTrackCard;
