import React from "react";
import { StyleSheet, View } from "react-native";
import ImageCard from "../ImageCard/ImageCard";

const ImageCardList = ({ imageCardData }) => {
  return (
    <View>
      <View style={styles.container}>
        {imageCardData.map((card, index) => (
          <ImageCard key={index} imageUrl={card.imageUrl} url={card.url} />
        ))}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
  },
});

export default ImageCardList;
