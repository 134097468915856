import { View } from "react-native";
import IntegrationCard from "../IntegrationCard/IntegrationCard";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";

function IntegrationCardList({ cards, onConnect }) {
  return (
    <>
      {cards.map(({ id, Icon, header, body, isConnected }) => (
        <View key={id}>
          <IntegrationCard
            key={id}
            Icon={Icon}
            header={header}
            body={body}
            isConnected={isConnected}
            onConnect={() => onConnect(id)}
          />
          <CustomSpacing type="vertical" size="m" />
        </View>
      ))}
      <CustomSpacing type="vertical" size="xxl" />
    </>
  );
}

export default IntegrationCardList;
