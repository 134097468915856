import { useState } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import SMSOTPForm from "../../../components/auth/SMSOTPForm/SMSOTPForm";
import { sendSMSOTP, verifySMSOTP } from "../../../services";

function SMSOTPFormContainer({ onSuccess }) {
  const [countryCode, setCountryCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [lastSentTime, setLastSentTime] = useState(null);

  const handleCountryCodeChange = (text) => {
    setCountryCode(text);
  };

  const handlePhoneNumberChange = (text) => {
    setPhoneNumber(text);
  };

  const handleSendOTP = async () => {
    if (countryCode === "" || phoneNumber === "") {
      setErrorMessage("Both fields must be filled out");
      return;
    }

    // Check if phoneNumber is valid
    const phoneRegex = /^\d{10}$/;
    if (!phoneRegex.test(phoneNumber)) {
      setErrorMessage("Phone number must be 10 digits");
      return;
    }

    try {
      const token = await AsyncStorage.getItem("token");
      await sendSMSOTP(countryCode, phoneNumber, token);
      setOtpSent(true);
      setErrorMessage("");
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setErrorMessage(error.response.data.error);
      } else {
        setErrorMessage("Error sending code. Please try again.");
      }
    }
  };

  const handleOTPChange = (text) => {
    setOtp(text);
  };

  const handleResendOTP = async () => {
    const canResendOTP = !lastSentTime || Date.now() - lastSentTime > 60000;

    if (!canResendOTP) {
      setErrorMessage("Please wait before requesting a new code.");
      return;
    }

    setErrorMessage("");
    await handleSendOTP();
    setLastSentTime(Date.now());
  };

  const handleVerifyOTP = async () => {
    if (otp === "") {
      setErrorMessage("Field must be filled out");
      return;
    }

    // Check if code is valid
    const otpRegex = /^\d{6}$/;
    if (!otpRegex.test(otp)) {
      setErrorMessage("Code must be 6 digits");
      return;
    }

    try {
      const token = await AsyncStorage.getItem("token");
      await verifySMSOTP(countryCode, phoneNumber, otp, token);

      setErrorMessage("");
      onSuccess();
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setErrorMessage(error.response.data.error);
      } else {
        setErrorMessage("Error sending code. Please try again.");
      }
    }
  };

  return (
    <SMSOTPForm
      isOTPSent={otpSent}
      errorMessage={errorMessage}
      countryCode={countryCode}
      phoneNumber={phoneNumber}
      onCountryCodeChange={handleCountryCodeChange}
      onPhoneNumberChange={handlePhoneNumberChange}
      onSendOTP={handleSendOTP}
      otp={otp}
      onOTPChange={handleOTPChange}
      onVerifyOTP={handleVerifyOTP}
      onResentOTP={handleResendOTP}
    />
  );
}

export default SMSOTPFormContainer;
