import React from "react";
import { useState, useEffect } from "react";
import { View, StyleSheet, Pressable } from "react-native";
import ChatList from "../ChatList/ChatList";
import CustomText from "../../common/general/CustomText/CustomText";
import { ChatIcon } from "../../svgs/common";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import { NoJourneyIcon } from "../../svgs/common";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import { ScrollView } from "react-native-gesture-handler";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";

const DarkChatMenu = ({
  assignmentChats,
  journeyChats,
  activeObjectId,
  isConnectedSchool,
  onViewJournal,
  onViewClasses,
  onViewChatPress,
  onChatPress,
  onViewJourneys,
}) => {
  const { isMedium } = useResponsiveScreen();
  const [viewedChat, setViewedChat] = useState(null);

  useEffect(() => {
    setViewedChat(null);
  }, [activeObjectId]);

  const handleActiveViewPress = (id) => {
    setViewedChat((prev) => (prev === id ? null : id));
  };

  return (
    <View style={[styles.containerBase, isMedium ? styles.containerDesktop : styles.containerMobile]}>
      <View style={styles.view}>
        <CustomText styleType="light" text={"Chat History"} size="m" textType="text" weight="ultraBold" />
      </View>

      <ScrollView
        style={[styles.scrollContainer, { width: viewedChat ? 430 : 270 }]}
        showsVerticalScrollIndicator={false}
        onScroll={() => setViewedChat(null)}>
        {isConnectedSchool && assignmentChats && assignmentChats.length > 0 ? (
          <ChatList
            chatData={assignmentChats}
            activeObjectId={activeObjectId}
            viewedChat={viewedChat}
            type="assignment"
            light={false}
            onActivateViewPress={handleActiveViewPress}
            onViewChatPress={onViewChatPress}
            onChatPress={onChatPress}
          />
        ) : (
          isConnectedSchool && (
            <View style={styles.noAssignmentContainer}>
              <View style={styles.icon}>
                <ChatIcon />
              </View>

              <CustomText
                text={"No assignment chats yet"}
                textType="text"
                size="s"
                styleType="light"
                weight="bold"
                style={styles.title}
              />

              <CustomText
                text={"Start engaging with Axio on your assignment pages to see your chats here!"}
                styleType="light"
                textType="text"
                size="xs"
                style={styles.subtitle}
              />

              <CustomButton
                text="View classes"
                size="s"
                bold={true}
                styleType="primary"
                style={styles.button}
                onPress={onViewClasses}
              />
            </View>
          )
        )}

        <CustomSpacing type="vertical" size="l" />

        {journeyChats && journeyChats.length > 0 ? (
          <ChatList
            chatData={journeyChats}
            activeObjectId={activeObjectId}
            viewedChat={viewedChat}
            type="journey"
            light={false}
            onActivateViewPress={handleActiveViewPress}
            onViewChatPress={onViewChatPress}
            onChatPress={onChatPress}
          />
        ) : (
          <View style={styles.journey}>
            <View style={styles.noAssignmentContainer}>
              <View style={styles.icon}>
                <NoJourneyIcon />
              </View>

              <CustomText
                text={"No Journey chats yet"}
                textType="text"
                size="s"
                styleType="light"
                weight="ultraBold"
                style={styles.title}
              />

              <CustomText
                text={"Start engaging with Axio on your Journey pages to see your chats here!"}
                styleType="light"
                textType="text"
                size="xs"
                weight="normal"
                style={styles.subtitle}
              />

              <CustomButton
                text="View Journeys"
                size="s"
                bold={true}
                styleType="primary"
                style={[styles.button, { marginBottom: 10 }]} // Add margin bottom to prevent overlap
                onPress={onViewJourneys}
              />
            </View>
          </View>
        )}
      </ScrollView>

      <Pressable style={styles.journalButton} onPress={onViewJournal}>
        <CustomText text={"View Journal"} styleType="light" size="m" weight="normal" />
      </Pressable>
    </View>
  );
};

const styles = StyleSheet.create({
  containerBase: {
    flex: 1,
    alignSelf: "flex-start",
    width: 270,
    backgroundColor: "#06113C",
    paddingVertical: 32,
    paddingHorizontal: 8,
  },
  containerDesktop: {
    borderRadius: 20,
  },
  containerMobile: {
    top: 0,
    left: 0,
    borderRadius: 0,
  },
  view: {
    paddingHorizontal: 8,
    justifyContent: "flex-start",
    paddingBottom: 20,
  },
  journey: {
    flex: 1,
    marginBottom: 30,
    paddingBottom: 10,
  },
  journalButton: {
    backgroundColor: "#152351",
    paddingVertical: 10,
    paddingHorizontal: 16,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 10,
    marginHorizontal: 8,
    marginTop: 10,
  },
  noAssignmentContainer: {
    flex: 1,
    width: 250,
    marginBottom: 10,
    paddingVertical: 20,
    paddingHorizontal: 10,
    borderRadius: 10,
    backgroundColor: "#152351",
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    width: 36,
    height: 36,
    marginBottom: 10,
  },
  title: {
    marginBottom: 10,
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  subtitle: {
    maxWidth: 230,
    height: "auto",
    marginBottom: 10,
    paddingHorizontal: 20,
    justifyContent: "center",
    textAlign: "center",
  },
  button: {
    justifyContent: "center",
  },
  scrollContainer: {
    flex: 1,
  },
});

export default DarkChatMenu;
