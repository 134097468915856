import React from "react";
import { View, StyleSheet, Text } from "react-native";
import CustomText from "../../../common/general/CustomText/CustomText";
import CustomSpacing from "../../../common/layout/CustomSpacing/CustomSpacing";
import useResponsiveScreen from "../../../../hooks/useResponsiveScreen";
import { CheckCircle } from "../../../svgs/common";

const LessonObjectives = ({ objectives }) => {
  const { isMedium } = useResponsiveScreen();

  return (
    <View style={styles.container}>
      <CustomText text="Learning Objectives" weight="bold" size={isMedium ? "l" : "s"} style={{ color: "#182230" }} />
      <CustomSpacing type="vertical" size="m" />
      <View style={styles.list}>
        {objectives.map((objective) => (
          <View key={objective.title} style={styles.objective}>
            <CheckCircle style={{ minWidth: 30 }} />
            <Text>
              <CustomText text={objective.title} size={isMedium ? "m" : "s"} style={{ color: "#182230" }} />
              <CustomText
                text={`: ${objective.description}`}
                size={isMedium ? "m" : "s"}
                style={{ color: "#667085" }}
              />
            </Text>
          </View>
        ))}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "left",
    textAlign: "left",
  },
  objective: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: 10,
  },
  list: {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: 14,
  },
});

export default LessonObjectives;
