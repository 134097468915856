import { Svg, Path } from "react-native-svg";

const ThumbDown = () => (
  <Svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M19.0453 12.2289L18.1078 4.72891C18.0417 4.19986 17.7847 3.71316 17.3851 3.36026C16.9854 3.00737 16.4707 2.81258 15.9375 2.8125H2.5C2.0856 2.8125 1.68817 2.97712 1.39515 3.27015C1.10212 3.56317 0.9375 3.9606 0.9375 4.375V11.25C0.9375 11.4552 0.977915 11.6584 1.05644 11.8479C1.13496 12.0375 1.25005 12.2098 1.39515 12.3549C1.54024 12.4999 1.71249 12.615 1.90206 12.6936C2.09163 12.7721 2.29481 12.8125 2.5 12.8125H5.67031L8.53672 18.5445C8.6146 18.7002 8.73427 18.8311 8.88232 18.9225C9.03037 19.014 9.20096 19.0625 9.375 19.0625C10.2864 19.0615 11.1601 18.699 11.8045 18.0545C12.449 17.4101 12.8115 16.5364 12.8125 15.625V14.6875H16.875C17.1851 14.6875 17.4917 14.6215 17.7744 14.4939C18.0571 14.3664 18.3094 14.1802 18.5146 13.9477C18.7199 13.7152 18.8733 13.4417 18.9648 13.1454C19.0563 12.849 19.0837 12.5366 19.0453 12.2289ZM5.3125 10.9375H2.8125V4.6875H5.3125V10.9375ZM17.1094 12.707C17.08 12.7402 17.0439 12.7668 17.0035 12.785C16.9631 12.8032 16.9193 12.8125 16.875 12.8125H11.875C11.6264 12.8125 11.3879 12.9113 11.2121 13.0871C11.0363 13.2629 10.9375 13.5014 10.9375 13.75V15.625C10.9375 15.9459 10.8386 16.2591 10.6544 16.5218C10.4702 16.7846 10.2095 16.9843 9.90781 17.0938L7.1875 11.6539V4.6875H15.9375C16.014 4.6869 16.0881 4.71441 16.1457 4.76481C16.2033 4.81521 16.2404 4.885 16.25 4.96094L17.1875 12.4609C17.1928 12.5052 17.1885 12.55 17.175 12.5924C17.1616 12.6349 17.1392 12.6739 17.1094 12.707Z"
      fill="#667085"
    />
  </Svg>
);

export default ThumbDown;
