import { View, StyleSheet } from "react-native";
import BlankLayout from "../layouts/BlankLayout";
import SignupFormContainer from "../containers/auth/SignupFormContainer/SignupFormContainer";
import MainCarousel from "../components/informational/carousel/carousel";
import useResponsiveScreen from "../hooks/useResponsiveScreen";

function SignupView(isLMS = false) {
  const { isLarge } = useResponsiveScreen();

  return isLarge ? (
    <BlankLayout>
      <View style={[styles.webContainer, { width: !isLMS ? "40%" : "100%" }]}>
        <SignupFormContainer />
      </View>

      {!isLMS && <MainCarousel />}
    </BlankLayout>
  ) : (
    <BlankLayout>
      <View style={styles.mobileContainer}>
        {/* <TopBanner /> */}
        <View style={{ padding: 15 }}>
          <SignupFormContainer />
        </View>
      </View>
    </BlankLayout>
  );
}

const styles = StyleSheet.create({
  webContainer: {
    justifyContent: "center",
    alignItems: "center",
    padding: 30,
  },
  mobileContainer: {
    flex: 1,
    justifyContent: "flex-start",
    alignItems: "center",

    width: "100%",
  },
});

export default SignupView;
