import { View, StyleSheet } from "react-native";
import CustomSpacing from "../../../common/layout/CustomSpacing/CustomSpacing";
import CustomText from "../../../common/general/CustomText/CustomText";
import useResponsiveScreen from "../../../../hooks/useResponsiveScreen";
import CustomButton from "../../../common/general/CustomButton/CustomButton";
import { RightArrowSlim } from "../../../svgs/common";

function LessonBreadcrumbs({ onPress, firstItem, secondItem }) {
  const { isMedium } = useResponsiveScreen();

  return (
    <View style={styles.container}>
      <View style={styles.row}>
        <CustomButton
          text={firstItem}
          size="xs"
          style={{ padding: 0 }}
          styleType="primaryTransparent"
          onPress={onPress}
          textStyle={[styles.linkButton, isMedium ? { fontSize: 12 } : { fontSize: 10 }]}
        />
        <RightArrowSlim />
        <CustomText text={secondItem} size="xs" style={[styles.linkText, isMedium ? {} : { fontSize: 10 }]} />
      </View>
      <CustomSpacing size="l" />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  row: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: 4,
  },
  linkButton: {
    color: "#667085",
    textDecorationLine: "underline",
    fontFamily: "Inter",
    fontWeight: "400",
    lineHeight: 24,
  },
  linkText: { color: "#667085", lineHeight: 24, marginTop: 1 },
});
export default LessonBreadcrumbs;
