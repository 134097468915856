import React from "react";
import { Pressable, View, Text, Image, StyleSheet } from "react-native";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import CustomText from "../../common/general/CustomText/CustomText";
import CustomProgressBar from "../../common/general/CustomProgressBar/CustomProgressBar";

const ActiveCard = ({ data, stackTitle, onPress, courseTitle = "Basics of Digital Painting", color = "#5BE068" }) => {
  const { isMedium } = useResponsiveScreen();

  const progress =
    stackTitle === "Journeys" ? ((data.completedTasks || 0) / (data.totalTasks || 1)) * 100 : data.mastery;

  const unitsText =
    stackTitle === "Journeys"
      ? `${data.completedTasks} of ${data.totalTasks} Tasks Complete`
      : `${data.completedUnits} of ${data.totalUnits} Units Complete`;

  const imageUrl = stackTitle === "Journeys" ? data?.url : data?.imageUrl;

  return (
    <View style={isMedium ? styles.container : styles.containerMobile}>
      <View style={isMedium ? styles.header : styles.headerMobile}>
        <Image source={imageUrl || require("../../../assets/cardImages/story.jpg")} style={styles.image} />
      </View>

      <View style={isMedium ? styles.progressBarContainer : styles.progressBarContainerMobile}>
        <CustomText
          text={data.name || courseTitle}
          size="m"
          weight="bold"
          numberOfLines={1}
          ellipsizeMode="tail"
          style={styles.courseTitle}
        />
        <View style={{ marginTop: 7 }}>
          <Text style={styles.unitsText}>{unitsText}</Text>
          <CustomProgressBar progress={progress} color={color} />
        </View>
      </View>

      <Pressable
        onPress={() => {
          if (stackTitle === "Journeys") {
            onPress(stackTitle, { id: data.id });
          } else if (stackTitle === "Tracks") {
            onPress(stackTitle, {
              field: data.parentFields ? data.parentFields[0] : undefined,
              subfield: data.parentSubfield,
              topic: data.name,
            });
          }
        }}
        style={isMedium ? styles.button : styles.buttonMobile}>
        <Text style={styles.buttonText}>{stackTitle === "Journeys" ? "View Journey" : "Resume Course"}</Text>
      </Pressable>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    margin: 5,
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#FFF",
    borderRadius: 10,
    padding: 16,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.04,
    shadowRadius: 10,
    elevation: 5,
  },
  containerMobile: {
    marginBottom: 10,
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#FFF",
    borderRadius: 10,
    padding: 16,
    marginHorizontal: 12,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.04,
    shadowRadius: 10,
    elevation: 5,
    width: 280,
  },
  header: {
    flexShrink: 1,
  },
  headerMobile: {
    width: "100%",
    marginBottom: 4,
  },
  image: {
    width: 94,
    height: 56,
    borderRadius: 5,
  },
  progressBarContainer: {
    flex: 2,
    justifyContent: "center",
    width: "100%",
    marginRight: 10,
    marginLeft: 10,
  },
  progressBarContainerMobile: {
    flex: 1,
    width: "100%",
    justifyContent: "center",
  },
  courseTitle: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#333",
  },
  unitsText: {
    fontSize: 14,
    paddingBottom: 5,
    color: "#666",
  },
  button: {
    // flex: 1,
    backgroundColor: "#3E68FE",
    borderRadius: 5,
    paddingVertical: 8,
    paddingHorizontal: 10,
    justifyContent: "center",
    alignItems: "center",
  },
  buttonMobile: {
    marginTop: 10,
    backgroundColor: "#3E68FE",
    borderRadius: 5,
    paddingVertical: 8,
    paddingHorizontal: 12,
    alignSelf: "flex-start",
  },
  buttonText: {
    color: "#FFF",
    fontSize: 12,
    fontWeight: "bold",
  },
});

export default ActiveCard;
