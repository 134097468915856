import { Text, StyleSheet, View } from "react-native";
import CustomButton from "../../common/general/CustomButton/CustomButton.js";
import { Check, Copy } from "../../svgs/common";
import useCopyContent from "../../../hooks/useCopyContent.js";

function CopyCodeButton({ content, languageType }) {
  const [showCheckMark, setShowCheckMark] = useCopyContent();

  const handleCopyCodePress = () => {
    navigator.clipboard.writeText(content);
    setShowCheckMark(true);
  };

  return (
    <View style={styles.copyCodeBlock}>
      <Text style={styles.languageText}>{languageType}</Text>
      <View>
        {!showCheckMark && (
          <CustomButton
            text="Copy code"
            styleType="transparent"
            size="s"
            onPress={handleCopyCodePress}
            style={styles.codeCopyButton}
            leftIcon={<Copy style={{ marginRight: 2, marginBottom: 1 }} />}
            textStyle={{ color: "#000B33" }}
          />
        )}
        {showCheckMark && (
          <View style={styles.copySuccess}>
            <Check style={{ marginBottom: 1 }} />
            <Text style={styles.copySuccessText}>Copied!</Text>
          </View>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  copyCodeBlock: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#E6EBFF",
    border: "none",
    borderTopEndRadius: 4,
    borderTopStartRadius: 4,
    borderBottomEndRadius: 0,
    borderBottomStartRadius: 0,
  },
  codeCopyButton: {
    padding: 8,
    marginRight: 10,
  },
  copySuccess: {
    flexDirection: "row",
    alignItems: "center",
    gap: 5,
    padding: 8,
    marginRight: 10,
  },
  copySuccessText: {
    fontSize: 13,
    fontFamily: "MontrealMedium",
  },
  languageText: {
    fontFamily: "MontrealMedium",
    fontSize: 13,
    marginLeft: 10,
    color: "#000B33",
  },
});

export default CopyCodeButton;
