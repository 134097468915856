import { useState, useEffect } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { listAnnouncements } from "../../../services";
import { useNavigation } from "@react-navigation/native";
import { View } from "react-native";
import Loader from "../../../components/common/status/Loader/Loader";
import CustomSpacing from "../../../components/common/layout/CustomSpacing/CustomSpacing";
import CustomText from "../../../components/common/general/CustomText/CustomText";
import AnnouncementCardList from "../../../components/lms/AnnouncementCardList/AnnouncementCardList";

function AnnouncementsCardListContainer({ integrationId, courseId }) {
  const [announcements, setAnnouncements] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigation = useNavigation();

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const token = await AsyncStorage.getItem("token");
      const data = await listAnnouncements(token, integrationId, courseId);
      setAnnouncements(data);
      setIsLoading(false);
    };

    fetchData();
  }, [integrationId]);

  const handlePress = (id) => {
    const announcement = announcements.filter((announcement) => announcement.id === id)[0];

    navigation.navigate("Resource", {
      resource_type: "announcement",
      resource_id: announcement.id,
      course_id: courseId,
      integration_id: integrationId,
    });
  };

  return (
    <View>
      {isLoading ? (
        <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
          <Loader />
          <CustomSpacing type="vertical" size="s" />
          <CustomText text="Loading..." size="m" />
        </View>
      ) : (
        <AnnouncementCardList items={announcements} onPress={handlePress} />
      )}
    </View>
  );
}

export default AnnouncementsCardListContainer;
