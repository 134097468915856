import { useState, useRef } from "react";
import { View, StyleSheet } from "react-native";
import CustomModal from "../../../../common/general/CustomModal/CustomModal";
import CustomText from "../../../../common/general/CustomText/CustomText";
import CustomSpacing from "../../../../common/layout/CustomSpacing/CustomSpacing";
import CustomTextInput from "../../../../common/general/CustomTextInput/CustomTextInput";
import CustomButton from "../../../../common/general/CustomButton/CustomButton";
import Loader from "../../../../common/status/Loader/Loader";
import useResponsiveScreen from "../../../../../hooks/useResponsiveScreen";
import { Sparkle } from "../../../../svgs/common";

function LessonRegenerateModal({ visible, onClose, onSubmit, errorMessage, isLoading }) {
  const { isMedium } = useResponsiveScreen();
  const [responseInput, setResponseInput] = useState("");

  const handleTextChange = (text) => {
    setResponseInput(text);
  };

  const inputRef = useRef(null);

  const handleShow = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleSubmit = () => {
    onSubmit(responseInput);
  };

  return (
    <CustomModal
      visible={visible}
      onRequestClose={onClose}
      showCloseButton={true}
      exitFill="#BBB"
      onShow={handleShow}
      width={isMedium ? "50%" : "90%"}>
      <View
        style={{
          ...styles.bodyContainer,
          paddingHorizontal: isMedium ? 12 : 0,

          paddingBottom: isMedium ? 12 : 0,
        }}>
        <CustomText text="Regenerate content" size="l" weight="ultraBold" style={{ color: "#182230" }} />
        <CustomSpacing type="vertical" size="s" />
        <CustomText
          text="Regenerate the content according to your requirements."
          size="s"
          style={{ color: "#667085" }}
        />
        <CustomSpacing type="vertical" size="s" />
        {errorMessage ? (
          <>
            <CustomText text={errorMessage} size="xs" style={styles.failedText} />
            <CustomSpacing type="vertical" size="s" />
          </>
        ) : (
          <CustomSpacing type="vertical" size="m" />
        )}
        {isLoading ? (
          <View style={styles.loadingContainer}>
            <Loader />
          </View>
        ) : (
          <CustomTextInput
            placeholder="Enter your instructions or requirements here...For example: These tasks are too simple. I wante more advanced tasks about Python functions"
            multiline
            numberOfLines={6}
            onChangeText={handleTextChange}
            value={responseInput}
            autoFocus={true}
            inputRef={inputRef}
          />
        )}
        <CustomSpacing type="vertical" size="m" />
        <CustomButton
          text={isLoading ? "Sending..." : "Regenerate"}
          styleType="primary"
          bold
          onPress={handleSubmit}
          disabled={isLoading}
          leftIcon={<Sparkle fill="white" />}
          style={{ justifyContent: "center", alignItems: "center", gap: 4 }}
        />
      </View>
    </CustomModal>
  );
}

const styles = StyleSheet.create({
  bodyContainer: {
    flex: 1,
    paddingTop: 0,
  },
  loadingContainer: {
    alignItems: "center",
    minHeight: 118,
  },
  failedText: {
    color: "red",
  },
});

export default LessonRegenerateModal;
