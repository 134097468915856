import React from "react";
import { View } from "react-native";

const CustomSpacing = ({ size = 16, type = "vertical" }) => {
  // Ensure the size is a multiple of 4
  const sizeMap = {
    xs: 4,
    s: 8,
    m: 12,
    l: 16,
    xl: 20,
    xxl: 24,
  };

  const isVertical = type === "vertical";

  const style = {
    [isVertical ? "height" : "width"]: sizeMap[size] || sizeMap.m,
  };

  return <View style={style} />;
};

export default CustomSpacing;
