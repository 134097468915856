import { View, StyleSheet } from "react-native";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import CustomModal from "../../common/general/CustomModal/CustomModal";
import ProLogo from "../logos/ProLogo/ProLogo";
import CustomText from "../../common/general/CustomText/CustomText";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";
import CustomButton from "../../common/general/CustomButton/CustomButton";

function UpgradeModal({ visible, companionName, domainKey, message, onUpgrade, onClose, buttonText }) {
  const { isMedium } = useResponsiveScreen();

  return (
    <CustomModal
      visible={visible}
      onRequestClose={onClose}
      showCloseButton={true}
      style={{ width: isMedium ? "40%" : "80%" }}>
      <View style={styles.container}>
        <ProLogo name={domainKey} height={90} width={250} />
        <CustomSpacing type="vertical" size="s" />

        <CustomText
          text={`${companionName} wants to talk to you!`}
          size="xxl"
          weight="bold"
          style={styles.centeredText}
        />
        <CustomSpacing type="vertical" size="m" />

        <CustomText text={message} size="l" style={styles.centeredText} />
        <CustomSpacing type="vertical" size="l" />

        <CustomButton text={buttonText} size="l" styleType="primary" onPress={onUpgrade} />
      </View>
    </CustomModal>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    paddingHorizontal: 20,
    paddingBottom: 20,
  },
  centeredText: {
    textAlign: "center",
  },
});

export default UpgradeModal;
