import { useState, useEffect } from "react";
import useWebSocket from "./useWebSocket";
import { Audio } from "expo-av";
import AsyncStorage from "@react-native-async-storage/async-storage";
import * as FileSystem from "expo-file-system";
import { Alert, Platform } from "react-native";
import { useChatBar } from "../contexts/ChatBarContext";
import { viewsWithoutChatWindow } from "../containers/navigation/ChatBarContainer/ChatBarContainer";
import { useRoute } from "@react-navigation/native";

const useAudioRecorder = (onSubmit) => {
  const { sendMessage } = useWebSocket();
  const { state: chatBarState, setShowChat, setMetaIsLoading } = useChatBar();
  const route = useRoute();

  const [recording, setRecording] = useState(null);

  const showChatWindow = !viewsWithoutChatWindow.includes(route.name);

  useEffect(() => {
    (async () => {
      const { status } = await Audio.requestPermissionsAsync();
      if (status !== "granted") {
        Alert.alert("Permission Denied", "Audio recording permissions were denied.");
      }
    })();
  }, []);

  const startRecording = async () => {
    try {
      await Audio.setAudioModeAsync({
        allowsRecordingIOS: true,
        playsInSilentModeIOS: true,
      });

      const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      const { recording: expoAvRecording } = await Audio.Recording.createAsync({
        ...Audio.RecordingOptionsPresets.HIGH_QUALITY,
        web: {
          mimeType: isSafari ? "video/mp4" : "audio/webm",
          bitsPerSecond: 128000,
        },
      });
      setRecording(expoAvRecording);
    } catch (err) {
      // trigger sentry
      console.error("Failed to start recording", err);
    }
  };

  const stopRecording = async () => {
    if (!recording) {
      return;
    }

    await recording.stopAndUnloadAsync();
    const uri = recording.getURI();
    setRecording(null);

    try {
      let fileString;

      if (Platform.OS === "web") {
        // Web-specific implementation
        const response = await fetch(uri);
        const blob = await response.blob();

        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = async () => {
          fileString = (reader.result as string).split(",")[1]; // Get the Base64 content

          sendAudioFileToServer(fileString);

          onSubmit();
        };
      } else {
        // Native platform implementation
        fileString = await FileSystem.readAsStringAsync(uri, {
          encoding: FileSystem.EncodingType.Base64,
        });

        sendAudioFileToServer(fileString);
      }

      if (showChatWindow) {
        setShowChat(true);
      }
    } catch (error) {
      console.error("Error reading audio file", error);
    }
  };

  const sendAudioFileToServer = async (fileString) => {
    // Send the base64 audio file to the server
    const token = await AsyncStorage.getItem("token");
    setMetaIsLoading(true);
    sendMessage({
      meta: { ...chatBarState.llmMessage.meta, token, isLoading: true, executionInfo: {
        agentName: "AudioAgent",
        status: "running",
        message: `Processing Audio...`,
      }, },
      state: {
      ...chatBarState.llmMessage.state,
    
      },
      audio: { type: "HUMAN", data: fileString },
    });
  };

  const toggleRecording = async () => {
    if (recording) {
      stopRecording();
    } else {
      const token = await AsyncStorage.getItem("token");
      sendMessage({
        meta: {
          token: token,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          abort: true,
        },
        audio: {
          type: "AI_ABORT",
          data: null,
        },
      });

      startRecording();
    }
  };

  return { recording, toggleRecording };
};

export default useAudioRecorder;
