import { View, StyleSheet, Dimensions } from "react-native";
import { useEffect, useState } from "react";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import { LearningMenuCheck, LeftArrow } from "../../svgIcons";
import CustomText from "../../common/general/CustomText/CustomText";
import LearnProgressCircle from "../../learning/general/LearnProgressCircle/LearnProgressCircle";
import { ScrollView } from "react-native-gesture-handler";
import ErrorLoader from "../../common/status/ErrorLoader/ErrorLoader";
import { ListItem } from "@rneui/themed";
import { Assessment, EyeIcon, EyeOffIcon } from "../../svgs/common";
import { CheckMark } from "../../svgs/common";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";
import CustomProgressBar from "../../common/general/CustomProgressBar/CustomProgressBar";

function LearnMenu({
  data,
  topicName,
  isError,
  onExitPress,
  onShowHidePress,
  expandedChapterState,
  expandedSubchapterState,
  activeUnit,
  hoveredElementState,
  onSubchapterPress,
  onLearningObjectivePress,
}) {
  const { isXLarge, isMedium, hp } = useResponsiveScreen();

  const [expandedChapter, setExpandedChapter] = expandedChapterState;
  const [expandedSubchapter, setExpandedSubchapter] = expandedSubchapterState;
  const [hoveredElement, setHoveredElement] = hoveredElementState;
  const [skeletonCount, setSkeletonCount] = useState(0);

  useEffect(() => {
    const calculateSkeletonCount = () => {
      const containerHeight = Dimensions.get("window").height;
      const updatedHeight = containerHeight * 0.7;
      const skeletonHeight = 34;
      const count = Math.ceil(updatedHeight / skeletonHeight);
      setSkeletonCount(count);
    };

    calculateSkeletonCount();
    Dimensions.addEventListener("change", calculateSkeletonCount);

    return () => {
      Dimensions.removeEventListener("change", calculateSkeletonCount);
    };
  }, []);

  if (isError) {
    return (
      <View style={[styles.container, { maxWidth: isXLarge ? 400 : isMedium ? 240 : hp(100) }]}>
        <View style={styles.loaderContainer}>
          <ErrorLoader message="Something went wrong! Try reloading the page." />
        </View>
      </View>
    );
  }

  const hasChapters = data?.chapters && data.chapters?.length > 0;

  return (
    <View style={[styles.container, { maxWidth: isXLarge ? 400 : isMedium ? 240 : hp(100) }]}>
      <View style={[styles.topContainer, { borderBottomWidth: isMedium ? 1 : 0 }]}>
        <CustomButton
          text="Exit Track"
          leftIcon={<LeftArrow />}
          styleType="primaryLight"
          size="s"
          style={[styles.exitButton, { marginLeft: isMedium ? "0" : "auto" }]}
          textStyle={styles.exitText}
          bold={true}
          onPress={onExitPress}
        />
      </View>
      <View style={styles.menuContainer}>
        <CustomText text={topicName} size="xs" weight="bold" textType="display" />
        <View style={styles.progressBarContainer}>
          {hasChapters ? (
            <CustomText
              text={`${data.completedChapters} of ${
                data.chapters?.length
              } Lesson${data.chapters?.length > 1 ? "s" : ""} Complete`}
              size="xs"
              weight="bold"
              textType="text"
            />
          ) : (
            <Skeleton height={18} width="60%" />
          )}
          <CustomProgressBar progress={data?.mastery || 0} />
        </View>
        <CustomText text="Lessons" size="m" weight="ultraBold" textType="text" style={{ marginLeft: 20 }} />
      </View>
      {hasChapters ? (
        <ScrollView showsVerticalScrollIndicator={false} style={styles.scrollView}>
          {data.chapters?.map((chapter, index) => {
            const existSubchaptersData = data.subchapters && data.subchapters.length > 0;

            const relevantSubchapters =
              data.subchapters && data.subchapters?.filter((subchapter) => subchapter.parentName === chapter.name);

            return (
              <ListItem.Accordion
                pad={8}
                containerStyle={styles.chapterAccordion}
                key={chapter.id}
                leftRotate={true}
                content={
                  <ListItem.Content
                    style={styles.chapterNameContainer}
                    onMouseEnter={() => setHoveredElement(chapter.name)}
                    onMouseLeave={() => setHoveredElement(null)}>
                    <CustomText
                      text={chapter.name}
                      size="m"
                      weight="medium"
                      textType="text"
                      numberOfLines={1}
                      ellipsizeMode="tail"
                      style={{
                        textDecorationLine: chapter.isRelevant ? "none" : "line-through",
                        color: chapter.isRelevant ? "#000" : "#98A2B3",
                      }}
                    />
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 8,
                        alignItems: "center",
                      }}>
                      <CustomButton
                        text=""
                        styleType="light"
                        size="xs"
                        onPress={() =>
                          onShowHidePress({
                            nodeName: chapter.name,
                            nodeLabel: "Chapter",
                            isRelevant: chapter.isRelevant,
                          })
                        }
                        style={[
                          styles.hideIcon,
                          {
                            display: isMedium && hoveredElement !== chapter.name ? "none" : "block",
                          },
                        ]}
                        leftIcon={
                          chapter.isRelevant ? (
                            <EyeIcon fill={"#98A2B3"} width={27} height={27} />
                          ) : (
                            <EyeOffIcon fill={"#98A2B3"} width={27} height={27} />
                          )
                        }
                      />
                      {chapter.mastery >= 100 ? (
                        <LearningMenuCheck />
                      ) : (
                        <LearnProgressCircle value={Math.round(chapter.mastery)} size={34} />
                      )}
                    </View>
                  </ListItem.Content>
                }
                isExpanded={chapter.name === expandedChapter}
                onPress={() => {
                  setExpandedChapter((prev) => (prev === chapter.name ? 0 : chapter.name));
                  if (expandedChapter !== chapter.name) {
                    setExpandedSubchapter(0);
                  }
                }}
                topDivider={index !== 0}>
                {existSubchaptersData ? (
                  relevantSubchapters.map((subchapter) => {
                    const existObjectivesData = data.objectives && data.objectives.length > 0;

                    const relevantObjectives = data.objectives
                      ?.filter((objective) => objective.parentName === subchapter.name)
                      .sort((a, b) => a.index - b.index);

                    return (
                      <ListItem.Accordion
                        pad={8}
                        containerStyle={[
                          styles.subchapterAccordion,
                          {
                            backgroundColor: expandedSubchapter === subchapter.name ? "#F2F5FF" : "transparent",
                          },
                        ]}
                        key={subchapter.id}
                        leftRotate={true}
                        content={
                          <ListItem.Content
                            style={styles.subchapterNameContainer}
                            onMouseEnter={() => setHoveredElement(subchapter.name)}
                            onMouseLeave={() => setHoveredElement(null)}>
                            <CustomText
                              text={subchapter.name}
                              size="m"
                              weight="normal"
                              textType="text"
                              numberOfLines={1}
                              ellipsizeMode="tail"
                              style={{
                                textDecorationLine: subchapter.isRelevant ? "none" : "line-through",
                                color: subchapter.isRelevant ? "#000" : "#98A2B3",
                              }}
                            />
                            <CustomButton
                              text=""
                              styleType="light"
                              size="xs"
                              onPress={() =>
                                onShowHidePress({
                                  nodeName: subchapter.name,
                                  nodeLabel: "Subchapter",
                                  isRelevant: subchapter.isRelevant,
                                })
                              }
                              style={[
                                styles.hideIcon,
                                {
                                  display: isMedium && hoveredElement !== subchapter.name ? "none" : "block",
                                },
                              ]}
                              leftIcon={
                                subchapter.isRelevant ? (
                                  <EyeIcon fill={"#98A2B3"} width={27} height={27} />
                                ) : (
                                  <EyeOffIcon fill={"#98A2B3"} width={27} height={27} />
                                )
                              }
                            />
                          </ListItem.Content>
                        }
                        isExpanded={subchapter.name === expandedSubchapter}
                        onPress={() => {
                          onSubchapterPress(subchapter.name, chapter.name);
                        }}>
                        {existObjectivesData ? (
                          relevantObjectives.map((objective) => {
                            return (
                              <View
                                key={objective.id}
                                style={[
                                  styles.learningObjectiveContainer,
                                  {
                                    backgroundColor:
                                      activeUnit === objective.id ||
                                      (objective.name === "Assessment Quiz" && activeUnit === "assessment")
                                        ? "#F2F5FF"
                                        : "transparent",
                                  },
                                ]}>
                                {objective.mastery === 100 ? (
                                  <View style={styles.checkboxContainer}>
                                    <CheckMark width={16} height={18} />
                                  </View>
                                ) : (
                                  <View style={styles.checkboxContainer}></View>
                                )}
                                <CustomText
                                  onPress={() => {
                                    onLearningObjectivePress(
                                      subchapter.name,
                                      chapter.name,
                                      objective.id,
                                      objective.name,
                                      objective.name === "Assessment Quiz"
                                    );
                                  }}
                                  text={objective.name}
                                  numberOfLines={2}
                                  ellipsizeMode="tail"
                                  size="s"
                                  weight="normal"
                                  style={styles.learningObjectiveText}
                                />
                                {objective.name === "Assessment Quiz" && (
                                  <>
                                    <CustomSpacing size="xs" type="h" />
                                    <Assessment width={20} height={20} />
                                  </>
                                )}
                              </View>
                            );
                          })
                        ) : (
                          <View style={{ paddingBottom: 15 }}>
                            <Skeleton height={34} width="98%" style={{ marginVertical: 10 }} count={4} />
                          </View>
                        )}
                      </ListItem.Accordion>
                    );
                  })
                ) : (
                  <View style={{ paddingBottom: 15 }}>
                    <Skeleton height={34} width="98%" style={{ marginVertical: 10 }} count={4} />
                  </View>
                )}
              </ListItem.Accordion>
            );
          })}
        </ScrollView>
      ) : (
        <View>
          <Skeleton height={34} width="98%" count={skeletonCount} style={{ marginVertical: 10 }} />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  scrollView: {
    height: 1,
    paddingHorizontal: 20,
  },
  loaderContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  container: {
    top: 0,
    left: 0,
    bottom: 0,
    flex: 1,
    borderRightWidth: 1, //"1px solid #EAECF0",
    borderRightColor: "#EAECF0",
    display: "flex",
    flexDirection: "column",
    gap: 20,
    backgroundColor: "#fff",
    zIndex: 20,
    shadowColor: "#000",
    shadowOffset: {
      width: 8,
      height: 0,
    },
    shadowOpacity: 0.02,
    shadowRadius: 8,
    elevation: 5,
  },
  topContainer: {
    paddingVertical: 12,
    paddingHorizontal: 20,
    borderBottomColor: "#D0D5DD",
    width: "100%",
  },
  menuContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
    paddingHorizontal: 20,
  },
  progressBarContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
  },
  chapterAccordion: {
    flexDirection: "row-reverse",
    paddingRight: 16,
    paddingLeft: 0,
    paddingVertical: 12,
  },
  chapterNameContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: 34,
  },
  subchapterAccordion: {
    flexDirection: "row-reverse",
    paddingLeft: 20,
    paddingRight: 16,
    paddingVertical: 4,
  },
  subchapterNameContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: 34,
  },
  hideIcon: {
    backgroundColor: "transparent",
    shadowColor: "transparent",
    elevation: 0,
  },
  exitButton: {
    width: "fit-content",
    shadowColor: "transparent",
    elevation: 0,
    justifyContent: "flex-start",
    padding: "8px",
    gap: "4px",
    border: "2px solid #3E68FE",
  },
  exitText: {
    fontSize: 12,
  },
  learningObjectiveContainer: {
    paddingLeft: 35,
    paddingTop: 5,
    paddingBottom: 5,
    flexDirection: "row",
    alignItems: "center",
  },
  learningObjectiveText: {
    color: "#333",
    fontSize: 14,
    paddingLeft: 7,
  },
  checkboxContainer: {
    width: 16,
    height: 18,
  },
});

export default LearnMenu;
