import { useEffect } from "react";
import { View, StyleSheet } from "react-native";
import { usePostHog } from "posthog-js/react";
import useResponsiveScreen from "../hooks/useResponsiveScreen";
import {
  useUserProgressQuery,
  useNodeGraphQuery,
  useCreateEducationChildrenMutation,
  NodeGraphDocument,
} from "../graphql/generated/graphql";
import DefaultLayout from "../layouts/DefaultLayout.web";
import CustomSpacing from "../components/common/layout/CustomSpacing/CustomSpacing";
import LearnTrackCardListContainer from "../containers/learning/LearnTrackCardListContainer/LearnTrackCardListContainer";
import { ScrollView } from "react-native-gesture-handler";
import getLearningRoute from "../utils/getLearningRoute";
import ErrorLoader from "../components/common/status/ErrorLoader/ErrorLoader";
import { MotiView } from "moti";
import LearnHeader from "../components/learning/home/LearnHeader/LearnHeader";
import LearningBreadcrumbs from "../components/learning/general/LearningBreadcrumbs/LearningBreadcrumbs";
import CustomText from "../components/common/general/CustomText/CustomText";
import TrackLoader from "../components/common/status/TrackLoader/TrackLoader";
import CustomProgressBar from "../components/common/general/CustomProgressBar/CustomProgressBar";

function TracksView({ route }) {
  const { isMedium } = useResponsiveScreen();
  const posthog = usePostHog();

  useEffect(() => {
    posthog?.capture("Learning Tracks Page Visited");
  }, []);

  const params = route.params;
  const { name, label } = getLearningRoute(params);

  // TODO display a placeholder with a button to reroute to the learn home page on error

  const {
    data: progressData,
    loading: progressLoading,
    error: progressError,
  } = useUserProgressQuery({
    variables: { nodeName: name, nodeLabel: label },
  });

  const {
    data: subfieldProgressData,
    loading: subfieldProgressLoading,
    error: subfieldProgressError,
  } = useUserProgressQuery({
    variables: { nodeName: params.field, nodeLabel: "Field" },
  });

  const {
    data: graphData,
    loading: graphLoading,
    error: graphError,
  } = useNodeGraphQuery({
    variables: { nodeLabel: label, nodeName: name },
  });

  const [createEducationChildren, { loading: loadingMutation }] = useCreateEducationChildrenMutation({
    refetchQueries: [
      {
        query: NodeGraphDocument,
        variables: { nodeLabel: label, nodeName: name },
      },
      {
        query: NodeGraphDocument,
        variables: { nodeLabel: "Field", nodeName: params.field },
      },
    ],
  });

  useEffect(() => {
    if (graphData?.getNodeChildren && graphData.getNodeChildren.length === 0) {
      createEducationChildren({
        variables: { name: name, label: label },
      });
    }
  }, [graphData]);

  const loading = progressLoading || graphLoading || loadingMutation || subfieldProgressLoading;
  const error = progressError || graphError || subfieldProgressError;

  if (loading) {
    return (
      <DefaultLayout>
        <View style={styles.loaderContainer}>
          <TrackLoader isGenerating={loadingMutation} />
        </View>
      </DefaultLayout>
    );
  }
  if (error) {
    return (
      <DefaultLayout>
        <View style={styles.loaderContainer}>
          <ErrorLoader message="Something went wrong! Try reloading the page." />
        </View>
      </DefaultLayout>
    );
  }

  const field = graphData?.getNodeParents[0]?.name;
  const subfield = graphData?.getNode?.name;
  const description = graphData?.getNode?.description;
  const tracksCount = graphData?.getNodeChildren?.length;
  const completedTrack = progressData?.getUserProgress?.filter((track) => track.totalMastery === 100).length;
  const subfieldMastery = subfieldProgressData?.getUserProgress.find(
    (progress) => progress.child.name === params.subfield
  )?.totalMastery;

  const completedData = graphData?.getNodeChildren.map((node) => {
    const progress = progressData?.getUserProgress.find((progress) => progress.child.name === node.name);
    return {
      ...node,
      totalTracks: progress?.child.totalTracks ?? node.unitsCount,
      completedTracks: progress?.child.completedTracks,
      mastery: progress?.totalMastery,
    };
  });

  return (
    <DefaultLayout>
      <ScrollView style={styles.scrollView} showsVerticalScrollIndicator={false}>
        <MotiView
          from={{
            opacity: 0,
            translateY: 20,
            scale: 1,
          }}
          animate={{
            opacity: 1,
            translateY: 0,
            scale: 1,
          }}
          delay={10}
          transition={{ type: "timing", duration: 500 }}>
          <LearningBreadcrumbs links={[field, subfield]} />
          <LearnHeader title={name} description={description} hasBreadcrumbs />
          <CustomSpacing type="vertical" size={isMedium ? "xl" : "m"} />
        </MotiView>
        <MotiView
          from={{
            opacity: 0,
            translateY: 20,
            scale: 1,
          }}
          animate={{
            opacity: 1,
            translateY: 0,
            scale: 1,
          }}
          delay={300}
          transition={{ type: "timing", duration: 500 }}>
          <CustomText
            text={`${completedTrack} of ${tracksCount} Track${tracksCount > 1 ? "s" : ""} Complete`}
            size={isMedium ? "m" : "xs"}
          />
          <CustomSpacing type="vertical" size="xs" />
          <CustomProgressBar progress={subfieldMastery ?? 0} />

          <CustomSpacing type="vertical" size={isMedium ? "xl" : "s"} />
          <CustomSpacing type="vertical" size="m" />
        </MotiView>

        <MotiView
          from={{
            opacity: 0,
            translateY: 20,
            scale: 1,
          }}
          animate={{
            opacity: 1,
            translateY: 0,
            scale: 1,
          }}
          delay={600}
          transition={{ type: "timing", duration: 600 }}>
          <View style={styles.listContainer}>
            <LearnTrackCardListContainer data={completedData} />
          </View>
        </MotiView>
        <CustomSpacing type="vertical" size={isMedium ? "l" : "xl"} />
      </ScrollView>
    </DefaultLayout>
  );
}

const styles = StyleSheet.create({
  loaderContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  scrollView: {
    height: 20,
  },
  listContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
  },
});

export default TracksView;
