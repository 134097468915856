import { View, StyleSheet } from "react-native";
import { useAppConfig } from "../../../AppConfigProvider";
import { MenuCircle, ExitArrow, BookCircle } from "../../svgs/common";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import { MotiView, AnimatePresence } from "moti";

function MobileHeader({ isMenuOpen, isShowLearn, isLearnOpen, onMenuOpenPress, onMenuClosePress, onLearnOpenPress }) {
  const appConfig = useAppConfig();

  const primaryColor = appConfig.primaryColor;

  return (
    <View style={styles.container}>
      <View style={styles.row}>
        {isMenuOpen || isLearnOpen ? (
          <CustomButton
            leftIcon={<ExitArrow fill={primaryColor} style={styles.menuCircle} />}
            styleType="transparent"
            style={styles.menuButton}
            onPress={onMenuClosePress}
          />
        ) : (
          <CustomButton
            leftIcon={<MenuCircle fill={primaryColor} style={styles.menuCircle} />}
            styleType="transparent"
            style={styles.menuButton}
            onPress={onMenuOpenPress}
          />
        )}
        <AnimatePresence>
          {isShowLearn && !isMenuOpen && (
            <>
              <CustomSpacing type="horizontal" size="xl" />
              <MotiView
                key="levelProgress"
                transition={{ delay: 600 }}
                from={{
                  opacity: 0,
                  scale: 0.5,
                }}
                animate={{
                  opacity: 1,
                  scale: 1,
                }}
                exit={{
                  opacity: 0,
                  scale: 0.9,
                }}>
                {!isLearnOpen && (
                  <CustomButton
                    leftIcon={<BookCircle style={styles.menuCircle} />}
                    styleType="transparent"
                    style={styles.menuButton}
                    onPress={onLearnOpenPress}
                  />
                )}
              </MotiView>
            </>
          )}
        </AnimatePresence>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
  },
  menuCircle: {
    width: 45,
    height: 45,
  },
  menuButton: {
    padding: 0,
  },
});

export default MobileHeader;
