import { Pressable, View, Text, StyleSheet } from "react-native";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";
import QuestImageBackground from "../../gamification/QuestImageBackground/QuestImageBackground";
import { QuestSnack } from "../../svgIcons";

function QuestMessage({ type, onPress }) {
  return (
    <Pressable onPress={onPress} style={styles.container}>
      <QuestImageBackground questTitle={type} style={styles.background} />

      <View style={styles.textContainer}>
        <QuestSnack />
        <CustomSpacing type="horizontal" size="m" />
        <Text style={styles.mainText}>
          Congrats! You completed the {type} quest and earned <Text style={styles.xpText}>250xp</Text>!
        </Text>
      </View>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  container: {
    borderRadius: 20,
    backgroundColor: "#F4F6FD",
    alignSelf: "flex-start",
  },
  background: {
    width: 529,
    height: 209,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    overflow: "hidden",
  },
  textContainer: {
    flexDirection: "row",
    alignItems: "center",
    width: 529,
    padding: 20,
  },
  mainText: {
    fontSize: 16,
    color: "#474747",
    flexWrap: "wrap",
    fontStyle: "normal",
    lineHeight: 22.4,
  },
  xpText: {
    color: "#3E68FE",
    fontSize: 18,
    fontStyle: "normal",
    fontWeight: "bold",
    lineHeight: 22.4,
  },
});

export default QuestMessage;
