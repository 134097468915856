import { View, StyleSheet } from "react-native";
import { useAppConfig } from "../../../AppConfigProvider";
import CustomText from "../../common/general/CustomText/CustomText";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import { Lightning } from "../../svgIcons";
import CustomProgressBar from "../../common/general/CustomProgressBar/CustomProgressBar";

function DailyChatProgress({ messageCount, isPro = false, onUpgradePress }) {
  const { primaryColor } = useAppConfig();

  return (
    !isPro && (
      <View style={styles.container}>
        <View style={styles.upgradeContainer}>
          <CustomText textType="display" size="xs" text="Free Plan" weight="bold" style={styles.freeText} />
          <CustomButton
            onPress={onUpgradePress}
            text=" Upgrade"
            styleType="primary"
            leftIcon={<Lightning />}
            size="s"
            style={styles.noShadowButton}
          />
        </View>

        <CustomSpacing type="vertical" size="l" />

        <CustomText
          style={{ fontFamily: "MontrealBook" }}
          text={isPro ? "Daily Chats for XP Points" : "Daily Chat Limit"}
        />
        <CustomSpacing type="vertical" size="s" />

        <CustomProgressBar progress={((messageCount ?? 0) / 10) * 100} color={primaryColor} />
        <CustomSpacing type="vertical" size="s" />

        <CustomText text={`${messageCount || 0}/10`} style={styles.text} />
      </View>
    )
  );
}

const styles = StyleSheet.create({
  container: {
    borderRadius: 10,
    padding: 12,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 8 },
    shadowOpacity: 0.08,
    shadowRadius: 16,
    elevation: 5,
  },
  upgradeContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  noShadowButton: {
    shadowColor: "transparent",
    elevation: 0,
  },
  text: {
    color: "#ADADAD",
  },
  freeText: {
    fontSize: 18,
  },
});

export default DailyChatProgress;
