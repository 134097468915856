import { useEffect } from "react";
import { usePostHog } from "posthog-js/react";
import { View, StyleSheet } from "react-native";
import CustomText from "../components/common/general/CustomText/CustomText";
import { ScrollView } from "react-native-gesture-handler";
import CustomSpacing from "../components/common/layout/CustomSpacing/CustomSpacing";
import ClassCardListContainer from "../containers/school/ClassCardListContainer/ClassCardListContainer";
import DefaultLayout from "../layouts/DefaultLayout.web";
import AssignmentsCardListContainer from "../containers/school/AssignmentsCardListContainer/AssignmentsCardListContainer";
import { useRoute } from "@react-navigation/native";
import useResponsiveScreen from "../hooks/useResponsiveScreen";
import { MotiView } from "moti";
import { useSchoolsQuery } from "../graphql/generated/graphql";

function SchoolView() {
  const posthog = usePostHog();
  const { isMedium } = useResponsiveScreen();
  const route = useRoute();

  const params = route.params;
  const integrationId = params.integration_id;
  const { data } = useSchoolsQuery();

  const schoolsData = data?.getSchools;

  const schoolName = schoolsData?.filter((school) => school.id === integrationId).map((school) => school.teamName);

  useEffect(() => {
    posthog?.capture("School Page Visited");
  }, []);

  return (
    <DefaultLayout>
      <MotiView
        from={{
          opacity: 0,
          translateY: 20,
          scale: 1,
        }}
        animate={{
          opacity: 1,
          translateY: 0,
          scale: 1,
        }}
        delay={10}
        transition={{ type: "timing", duration: 500 }}>
        <View style={styles.subheaderContainer}>
          <CustomText text={schoolName} size={isMedium ? "l" : "xs"} textType="display" weight="ultraBold" />
        </View>
      </MotiView>
      <MotiView
        from={{
          opacity: 0,
          translateY: 20,
          scale: 1,
        }}
        animate={{
          opacity: 1,
          translateY: 0,
          scale: 1,
        }}
        delay={500}
        transition={{ type: "timing", duration: 600 }}>
        <CustomSpacing type="vertical" size="m" />
        <CustomText text="My Classes" size="xs" textType="display" />
        <CustomSpacing type="vertical" size="m" />
      </MotiView>
      <ScrollView style={styles.scrollView} horizontal={true} showsHorizontalScrollIndicator={true}>
        <MotiView
          from={{
            opacity: 0,
            translateY: 20,
            scale: 1,
          }}
          animate={{
            opacity: 1,
            translateY: 0,
            scale: 1,
          }}
          delay={800}
          transition={{ type: "timing", duration: 600 }}>
          <ClassCardListContainer integrationId={integrationId} />
        </MotiView>
      </ScrollView>
      <MotiView
        from={{
          opacity: 0,
          translateY: 20,
          scale: 1,
        }}
        animate={{
          opacity: 1,
          translateY: 0,
          scale: 1,
        }}
        delay={1300}
        transition={{ type: "timing", duration: 600 }}>
        <CustomSpacing type="vertical" size="l" />
        <CustomText text="Upcoming Assignments" size="xs" textType="display" />
        <CustomSpacing type="vertical" size="s" />
      </MotiView>
      <ScrollView style={styles.verticalScrollView} showsVerticalScrollIndicator={false}>
        <MotiView
          from={{
            opacity: 0,
            translateY: 20,
            scale: 1,
          }}
          animate={{
            opacity: 1,
            translateY: 0,
            scale: 1,
          }}
          delay={1500}
          transition={{ type: "timing", duration: 600 }}>
          <AssignmentsCardListContainer integrationIds={[integrationId]} />
        </MotiView>
      </ScrollView>
    </DefaultLayout>
  );
}

const styles = StyleSheet.create({
  scrollView: {
    maxHeight: 240,
    paddingHorizontal: 10,
  },
  verticalScrollView: {
    height: 20,
    paddingHorizontal: 10,
  },
  subheaderContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
});

export default SchoolView;
