import apiClient from "./ApiService";

const sendSMSOTP = async (countryCode, phoneNumber, token) => {
  try {
    const response = await apiClient.post(
      "/otp/sms/send",
      {
        countryCode,
        phoneNumber,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error sending OTP SMS:", error);
    throw error;
  }
};

const verifySMSOTP = async (countryCode, phoneNumber, otp, token) => {
  try {
    const response = await apiClient.post(
      "/otp/sms/verify",
      {
        countryCode,
        phoneNumber,
        otp,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error verifying OTP SMS:", error);
    throw error;
  }
};

const sendEmailOTP = async (email, token) => {
  try {
    const response = await apiClient.post(
      "/otp/email/send",
      {
        email,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error sending OTP email:", error);
    throw error;
  }
};

const verifyEmailOTP = async (email, otp, token) => {
  try {
    const response = await apiClient.post(
      "/otp/email/verify",
      {
        email,
        otp,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error verifying OTP email:", error);
    throw error;
  }
};

const sendPasswordResetEmailOTP = async (email) => {
  try {
    const response = await apiClient.post(
      "/otp/email/password-reset/send",
      {
        email,
      },
      {
        headers: {},
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error sending OTP email:", error);
    throw error;
  }
};

const verifyPasswordResetEmailOTP = async (email, otp) => {
  try {
    const response = await apiClient.post(
      "/otp/email/password-reset/verify",
      {
        email,
        otp,
      },
      {
        headers: {},
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error verifying OTP email:", error);
    throw error;
  }
};

export {
  sendSMSOTP,
  verifySMSOTP,
  sendEmailOTP,
  verifyEmailOTP,
  sendPasswordResetEmailOTP,
  verifyPasswordResetEmailOTP,
};
