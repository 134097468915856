import { StyleSheet } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import CourseCard from "../CourseCard/CourseCard";

function CourseCardList({ courses = [], onPress }) {
  return (
    <ScrollView horizontal={true} height={234} showsHorizontalScrollIndicator={true} style={styles.scrollView}>
      {courses.map((course) => {
        return (
          <CourseCard
            key={course.id}
            title={course?.title}
            description={course?.description}
            fillPercentage={course?.fillPercentage}
            label={course?.label}
            onPress={() => onPress(course.id)}
          />
        );
      })}
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  scrollView: {
    flex: 1,
  },
});

export default CourseCardList;
