import { Svg, Path } from "react-native-svg";

const ThumbUp = () => (
  <Svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M0.954687 7.77109L1.89219 15.2711C1.95826 15.8001 2.21527 16.2868 2.61492 16.6397C3.01457 16.9926 3.52934 17.1874 4.0625 17.1875H17.5C17.9144 17.1875 18.3118 17.0229 18.6049 16.7299C18.8979 16.4368 19.0625 16.0394 19.0625 15.625V8.75C19.0625 8.54481 19.0221 8.34163 18.9436 8.15206C18.865 7.96249 18.7499 7.79024 18.6049 7.64515C18.4598 7.50005 18.2875 7.38496 18.0979 7.30644C17.9084 7.22792 17.7052 7.1875 17.5 7.1875H14.3297L11.4633 1.45547C11.3854 1.29983 11.2657 1.16895 11.1177 1.07747C10.9696 0.985985 10.799 0.937521 10.625 0.9375C9.71364 0.938534 8.83989 1.30103 8.19546 1.94546C7.55103 2.58989 7.18853 3.46364 7.1875 4.375V5.3125H3.125C2.81487 5.31255 2.5083 5.37853 2.22562 5.50609C1.94294 5.63364 1.69061 5.81984 1.48537 6.05234C1.28014 6.28484 1.12668 6.55832 1.03519 6.85464C0.943693 7.15097 0.916254 7.46336 0.954687 7.77109ZM14.6875 9.0625H17.1875V15.3125H14.6875V9.0625ZM2.89062 7.29297C2.91998 7.25978 2.95606 7.23321 2.99647 7.21502C3.03688 7.19684 3.08069 7.18746 3.125 7.1875H8.125C8.37364 7.1875 8.6121 7.08873 8.78791 6.91291C8.96373 6.7371 9.0625 6.49864 9.0625 6.25V4.375C9.06252 4.05407 9.16136 3.74093 9.34559 3.47815C9.52982 3.21537 9.79049 3.01569 10.0922 2.90625L12.8125 8.34609V15.3125H4.0625C3.98596 15.3131 3.91187 15.2856 3.85427 15.2352C3.79667 15.1848 3.75957 15.115 3.75 15.0391L2.8125 7.53906C2.80724 7.49484 2.81149 7.45001 2.82496 7.40757C2.83843 7.36513 2.86082 7.32605 2.89062 7.29297Z"
      fill="#667085"
    />
  </Svg>
);

export default ThumbUp;
