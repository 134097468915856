import React from "react";
import { View, StyleSheet } from "react-native";
import LottieView from "lottie-react-native";
import CustomText from "../../common/general/CustomText/CustomText";

function HydrationAnimation({ value, goal }) {
  const percentageTowardsGoal = goal ? value / goal : 0;

  return (
    <View>
      {percentageTowardsGoal >= 1 && (
        <LottieView
          autoPlay
          loop
          source={{
            ...require("../../../assets/lottie/hydration/hydration100.json"),
          }}
        />
      )}
      {percentageTowardsGoal < 1 && percentageTowardsGoal >= 0.9 && (
        <LottieView
          autoPlay
          loop
          source={{
            ...require("../../../assets/lottie/hydration/hydration90.json"),
          }}
        />
      )}
      {percentageTowardsGoal < 0.9 && percentageTowardsGoal >= 0.8 && (
        <LottieView
          autoPlay
          loop
          source={{
            ...require("../../../assets/lottie/hydration/hydration80.json"),
          }}
        />
      )}
      {percentageTowardsGoal < 0.8 && percentageTowardsGoal >= 0.7 && (
        <LottieView
          autoPlay
          loop
          source={{
            ...require("../../../assets/lottie/hydration/hydration70.json"),
          }}
        />
      )}
      {percentageTowardsGoal < 0.7 && percentageTowardsGoal >= 0.6 && (
        <LottieView
          autoPlay
          loop
          source={{
            ...require("../../../assets/lottie/hydration/hydration60.json"),
          }}
        />
      )}
      {percentageTowardsGoal < 0.6 && percentageTowardsGoal >= 0.5 && (
        <LottieView
          autoPlay
          loop
          source={{
            ...require("../../../assets/lottie/hydration/hydration50.json"),
          }}
        />
      )}
      {percentageTowardsGoal < 0.5 && percentageTowardsGoal >= 0.4 && (
        <LottieView
          autoPlay
          loop
          source={{
            ...require("../../../assets/lottie/hydration/hydration40.json"),
          }}
        />
      )}
      {percentageTowardsGoal < 0.4 && percentageTowardsGoal >= 0.3 && (
        <LottieView
          autoPlay
          loop
          source={{
            ...require("../../../assets/lottie/hydration/hydration30.json"),
          }}
        />
      )}
      {percentageTowardsGoal < 0.3 && percentageTowardsGoal >= 0.2 && (
        <LottieView
          autoPlay
          loop
          source={{
            ...require("../../../assets/lottie/hydration/hydration20.json"),
          }}
        />
      )}
      {percentageTowardsGoal < 0.2 && percentageTowardsGoal >= 0.1 && (
        <LottieView
          autoPlay
          loop
          source={{
            ...require("../../../assets/lottie/hydration/hydration10.json"),
          }}
        />
      )}
      {percentageTowardsGoal < 0.1 && percentageTowardsGoal >= 0 && (
        <LottieView
          autoPlay
          loop
          source={{
            ...require("../../../assets/lottie/hydration/hydration0.json"),
          }}
        />
      )}

      <View style={styles.labelOuterContainer}>
        <View style={styles.labelContainer}>
          <CustomText text={`${value || 0} of ${goal || 0}`} size="xxl" weight="bold" />
          <CustomText text="oz" size="m" />
        </View>
      </View>
    </View>
  );
}
const styles = StyleSheet.create({
  labelOuterContainer: {
    position: "absolute",
    bottom: 50,
    left: 0,
    right: 0,
    alignItems: "center",
  },
  labelContainer: {
    paddingVertical: 20,
    paddingHorizontal: 40,
    borderRadius: 1000,
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.08,
    shadowRadius: 16,
    elevation: 5,
  },
});
export default HydrationAnimation;
