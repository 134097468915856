import React from "react";
import { View, StyleSheet } from "react-native";
import CustomTextInput from "../../common/general/CustomTextInput/CustomTextInput";
import CustomText from "../../common/general/CustomText/CustomText";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import DatePickerButton from "../../general/DatePickerButton/DatePickerButton";
import { Checked, UnChecked } from "../../svgs/common";

function EventForm({
  title,
  description,
  location,
  startDate,
  endDate,
  startTime,
  endTime,
  allDay,
  onChangeTitle,
  onChangeDescription,
  onChangeLocation,
  onDatePick,
  onTimePick,
  onSubmit,
  onToggleAllDay,
  showEndDateButton,
  startDateLabel,
}) {
  const formatTime = (date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours || 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${hours}:${minutes} ${ampm}`;
  };

  return (
    <View style={styles.container}>
      <CustomText text="Event Title" size="l" weight="bold" />
      <CustomSpacing type="vertical" size="s" />
      <CustomTextInput
        placeholder="Give your event a name..."
        autoFocus={true}
        value={title}
        onChangeText={onChangeTitle}
      />
      <CustomSpacing type="vertical" size="m" />
      <CustomText text="Description" size="l" weight="bold" />
      <CustomSpacing type="vertical" size="s" />
      <CustomTextInput
        placeholder="(Optional) Add a description for your event..."
        multiline
        maxLength={90}
        numberOfLines={4}
        value={description}
        onChangeText={onChangeDescription}
      />
      <CustomSpacing type="vertical" size="m" />
      <CustomText text="Location" size="l" weight="bold" />
      <CustomSpacing type="vertical" size="s" />
      <CustomTextInput
        placeholder="(Optional) Add a location or meeting link for your event..."
        value={location}
        onChangeText={onChangeLocation}
      />
      <CustomSpacing type="vertical" size="s" />
      <View style={styles.allDayRow}>
        <CustomText text="All Day" size="l" weight="bold" />
        <CustomButton
          styleType="transparent"
          style={styles.check}
          leftIcon={allDay ? <Checked /> : <UnChecked />}
          onPress={onToggleAllDay}
        />
      </View>
      <View style={styles.dateTimeLayout}>
        <View style={styles.dateTimeBlock}>
          <CustomText text={startDateLabel} size="l" weight="bold" />
          <DatePickerButton date={startDate.toLocaleDateString()} onPress={() => onDatePick("start")} />
        </View>
        {!allDay && (
          <>
            <View style={styles.dateTimeBlock}>
              <CustomText text="Start Time" size="l" weight="bold" />
              <DatePickerButton date={formatTime(startTime)} onPress={() => onTimePick("start")} />
            </View>
            <View style={styles.dateTimeBlock}>
              <CustomText text="End Time" size="l" weight="bold" />
              <DatePickerButton date={formatTime(endTime)} onPress={() => onTimePick("end")} />
            </View>
          </>
        )}
        {showEndDateButton && (
          <View style={styles.dateTimeBlock}>
            <CustomText text="End Date" size="l" weight="bold" />
            <DatePickerButton date={endDate.toLocaleDateString()} onPress={() => onDatePick("end")} />
          </View>
        )}
      </View>
      <CustomSpacing type="vertical" size="m" />
      <View style={styles.buttonWrapper}>
        <CustomButton text="Done" styleType="primary" onPress={onSubmit} />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 6,
  },
  allDayRow: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 6,
  },
  dateTimeLayout: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    marginBottom: 20,
    flexWrap: "wrap",
  },
  dateTimeBlock: {
    minWidth: "24%",
    margin: 5,
  },
  buttonWrapper: {
    width: "20%",
  },
  check: {
    marginLeft: 10,
  },
});

export default EventForm;
