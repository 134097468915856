import { useAppConfig } from "../AppConfigProvider";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import withAuth from "../hocs/withAuth";
import WebSocketNavigator from "./WebSocketNavigator";
import DashboardView from "../views/DashboardView";
import TasksView from "../views/TasksView";
import JournalView from "../views/JournalView";
import SchoolView from "../views/SchoolView";
import SchoolsView from "../views/SchoolsView";
import CategoriesView from "../views/CategoriesView";
import TracksView from "../views/TracksView";
import LearnView from "../views/LearnView";
import LessonLearningView from "../views/LessonLearningView";
import LessonChatView from "../views/LessonChatView";
import LessonVideoView from "../views/LessonVideoView";
import HealthView from "../views/HealthView";
import ProfileView from "../views/ProfileView";
import InviteView from "../views/InviteView";
import LoginView from "../views/LoginView";
import LoginLTIView from "../views/LoginLTIView";
import SignupView from "../views/SignupView";
import ResetPasswordView from "../views/ResetPasswordView";
import VerifyEmailView from "../views/VerifyEmailView";
import CourseView from "../views/CourseView";
import OnboardingView from "../views/OnboardingView";
import CareerView from "../views/CareerView";
import CareerDetailView from "../views/CareerDetailView";
import ResourceView from "../views/ResourceView";
import JourneyView from "../views/JourneyView";
import ThankYouView from "../views/ThankYouView";
import TrackOverviewView from "../views/TrackOverviewView";
import LessonAssessmentView from "../views/LessonAssessmentView";
import LessonSubchapterView from "../views/LessonSubchapterView";
import EdlinkSuccessView from "../views/EdlinkSuccessView";

// Some views have been disabled for production

// Add this type definition
export type RootStackParamList = {
  "My Day": undefined;
  Productivity: undefined;
  Journal: undefined;
  Coursework: undefined;
  School: { integration_id: string };
  Course: undefined;
  Career: undefined;
  Journey: { id: string };
  CareerDetails: undefined;
  Resource: {
    resource_type: string;
    resource_id: string;
    course_id: string;
    integration_id: string;
  };
  Learn: undefined;
  Categories: undefined;
  Tracks: undefined;
  "Track Overview": undefined;
  Lesson: undefined;
  "Lesson Activities": undefined;
  "Lesson Assessment": undefined;
  "Lesson Chat": { loId: string };
  "Lesson Video": undefined;
  Health: undefined;
  Onboarding: undefined;
  Profile: undefined;
  Invite: undefined;
  Login: { isLMS: boolean };
  LoginLTI: undefined;
  Signup: { isLMS: boolean };
  "Reset Password": undefined;
  "Verify Email": undefined;
  ThankYou: undefined;
  EdlinkSuccess: undefined;
};

const Stack = createStackNavigator<RootStackParamList>();

function AppNavigator() {
  const appConfig = useAppConfig();
  const currentDomain = appConfig.currentDomain;
  const isLMS = appConfig.isLMS;

  // WebSocketNavigator();

  const linking = {
    prefixes: [`https://${currentDomain}`, "primer://"],
    config: {
      screens: {
        "My Day": "my-day",
        Productivity: "productivity",
        Journal: "journal",
        Education: "education",
        School: "school",
        Course: "school/course",
        Resource: "school/course/resource",
        Learn: "learn",
        Tracks: "tracks",
        "Track Overview": "track/overview",
        Categories: "categories",
        Lesson: "learn/lesson",
        "Lesson Activities": "learn/lesson/activities",
        "Lesson Assessment": "learn/lesson/assessment",
        "Lesson Chat": "learn/lesson/chat",
        "Lesson Video": "learn/lesson/video",
        Health: "health",
        Onboarding: "onboarding",
        Profile: "profile",
        Invite: "invite",
        Login: "login",
        LoginLTI: "login/lti",
        Signup: "signup",
        "Reset Password": "reset-password",
        "Verify Email": "verify-email",
        Storybook: "storybook",
        Career: "career",
        Journey: "journey",
        CareerDetails: "career/details",
        ThankYou: "thank-you",
        EdlinkSuccess: "edlink-success",
      },
    },
  };

  return (
    <NavigationContainer linking={linking}>
      <Stack.Navigator initialRouteName="My Day" screenOptions={{ headerShown: false }}>
        <Stack.Screen name="My Day" component={withAuth(DashboardView)} />
        <Stack.Screen name="Productivity" component={withAuth(TasksView)} />
        <Stack.Screen name="Journal" component={withAuth(JournalView)} />
        <Stack.Screen name="Coursework" component={withAuth(SchoolsView)} />
        <Stack.Screen name="School" component={withAuth(SchoolView)} />
        <Stack.Screen name="Course" component={withAuth(CourseView)} />
        <Stack.Screen name="Career" component={withAuth(CareerView)} />
        <Stack.Screen name="Journey" component={withAuth(JourneyView)} />
        <Stack.Screen name="CareerDetails" component={withAuth(CareerDetailView)} />
        <Stack.Screen name="Resource" component={withAuth(ResourceView)} />
        <Stack.Screen name="Learn" component={withAuth(LearnView)} />
        <Stack.Screen name="Categories" component={withAuth(CategoriesView)} />
        <Stack.Screen name="Tracks" component={withAuth(TracksView)} />
        <Stack.Screen name="Track Overview" component={withAuth(TrackOverviewView)} />
        <Stack.Screen name="Lesson" component={withAuth(LessonSubchapterView)} />
        <Stack.Screen name="Lesson Activities" component={withAuth(LessonLearningView)} />
        <Stack.Screen name="Lesson Assessment" component={withAuth(LessonAssessmentView)} />
        <Stack.Screen name="Lesson Chat" component={withAuth(LessonChatView)} />
        <Stack.Screen name="Lesson Video" component={withAuth(LessonVideoView)} />
        <Stack.Screen name="Health" component={withAuth(HealthView)} />
        <Stack.Screen name="Onboarding" component={withAuth(OnboardingView)} />
        <Stack.Screen name="Profile" component={withAuth(ProfileView)} />
        <Stack.Screen name="Invite" component={withAuth(InviteView)} />
        <Stack.Screen name="Login" component={LoginView} initialParams={{ isLMS }} />
        <Stack.Screen name="LoginLTI" component={LoginLTIView} />
        <Stack.Screen name="Signup" component={SignupView} initialParams={{ isLMS }} />
        <Stack.Screen name="Reset Password" component={ResetPasswordView} />
        <Stack.Screen name="Verify Email" component={VerifyEmailView} />
        <Stack.Screen name="ThankYou" component={withAuth(ThankYouView)} />
        <Stack.Screen name="EdlinkSuccess" component={EdlinkSuccessView} />
      </Stack.Navigator>
    </NavigationContainer>
  );
}

export default AppNavigator;
