import { useState } from "react";
import { useNavigation } from "@react-navigation/native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import useWebSocket from "../../../hooks/useWebSocket";
import AIMessage from "../../../components/chat/AIMessage/AIMessage";
import UserMessage from "../../../components/chat/UserMessage/UserMessage";
import LearningMessage from "../../../components/chat/LearningMessage/LearningMessage";
import AspectMessage from "../../../components/chat/AspectMessage/AspectMessage";
import AspectModal from "../../../components/chat/AspectModal/AspectModal";
import AspectFormContainer from "../AspectFormContainer/AspectFormContainer";
import QuestMessage from "../../../components/chat/QuestMessage/QuestMessage";
import QuestModal from "../../../components/gamification/QuestModal/QuestModal";
import CustomSpacing from "../../../components/common/layout/CustomSpacing/CustomSpacing";
import { MotiView } from "moti";
import {
  useMeQuery,
  useGetLearningTreeQuery,
  useEnrollUserInTrackMutation,
  MeDocument,
} from "../../../graphql/generated/graphql";
import { useChatBar } from "../../../contexts/ChatBarContext";

function MessageContainer({ message, index }) {
  const { sendMessage } = useWebSocket();
  const { setShowChat } = useChatBar();
  const navigation = useNavigation();
  const { data: meData } = useMeQuery({});
  const level = message.parameters?.lastGeneratedLabel;
  const name = message.parameters?.lastGeneratedTopic;
  const [enrollUserInTrack] = useEnrollUserInTrackMutation({
    refetchQueries: [
      {
        query: MeDocument,
      },
    ],
  });

  const [aspectModalVisible, setAspectModalVisible] = useState(false);
  const [questModalVisible, setQuestModalVisible] = useState(false);

  let type = message.type || message.sender || null;
  if (index === 0 && message.sender === "ai") {
    type = "first-ai";
  }
  if (index === 0 && message.sender === "human") {
    type = "first-human";
  }

  const handleOpenAspectModal = () => {
    setAspectModalVisible(true);
  };

  const handleCloseAspectModal = () => {
    setAspectModalVisible(false);
  };

  const handleOpenQuestModal = () => {
    setQuestModalVisible(true);
  };

  const handleCloseQuestModal = () => {
    setQuestModalVisible(false);
  };

  const {
    data: learningTreeData,
    loading,
    error,
  } = useGetLearningTreeQuery({
    variables: { level, name },
    skip: !level || !name,
  });

  const enrolledTracks = meData?.me?.enrolledTracks || [];

  const handleLearningPress = async () => {
    const token = await AsyncStorage.getItem("token");
    sendMessage({
      meta: {
        token: token,
        abort: true,
      },
      state: {},
      content: {},
    });

    if (loading) return;
    if (error) {
      console.error("Error fetching learning tree data:", error);
      return;
    }

    const tree = learningTreeData?.getLearningTree;

    if (!tree) {
      console.log("No learning tree data available");
      return;
    }

    setShowChat(false);

    switch (level) {
      case "Field":
        navigation.navigate("Categories", { field: tree.FieldName });
        break;
      case "Subfield":
        navigation.navigate("Tracks", {
          field: tree.FieldName,
          subfield: tree.SubfieldName,
        });
        break;
      case "Topic":
        if (enrolledTracks.includes(name)) {
          navigation.navigate("Lesson", {
            field: tree.FieldName,
            subfield: tree.SubfieldName,
            topic: tree.TopicName,
          });
        } else {
          navigation.navigate("Track Overview", {
            field: tree.FieldName,
            subfield: tree.SubfieldName,
            topic: tree.TopicName,
          });
        }
        break;
      case "Chapter": {
        const isUserAlreadyEnrolled = meData.me?.enrolledTracks?.includes(tree.TopicName);

        if (!isUserAlreadyEnrolled) {
          enrollUserInTrack({
            variables: {
              topicName: tree.TopicName,
            },
          });
        }

        navigation.navigate("Lesson", {
          field: tree.FieldName,
          subfield: tree.SubfieldName,
          topic: tree.TopicName,
          chapter: tree.ChapterName,
        });
        break;
      }
      case "Subchapter": {
        const isUserAlreadyEnrolled = meData.me?.enrolledTracks?.includes(tree.TopicName);

        if (!isUserAlreadyEnrolled) {
          enrollUserInTrack({
            variables: {
              topicName: tree.TopicName,
            },
          });
        }

        navigation.navigate("Lesson", {
          field: tree.FieldName,
          subfield: tree.SubfieldName,
          topic: tree.TopicName,
          chapter: tree.ChapterName,
          subchapter: tree.SubchapterName,
        });
        break;
      }
      default:
        console.log("Unhandled learning type:", level);
    }
  };

  switch (type) {
    case "ai":
      return (
        <>
          <CustomSpacing type="vertical" size="xs" />
          <AIMessage text={message.content} />
          <CustomSpacing type="vertical" size="xs" />
        </>
      );
    case "human":
      return (
        <>
          <CustomSpacing type="vertical" size="m" />
          <UserMessage text={message.content} />
          <CustomSpacing type="vertical" size="m" />
        </>
      );
    case "first-ai":
      return (
        <>
          <CustomSpacing type="vertical" size="xs" />
          <AIMessage text={message.content} isFirst={true} />
          <CustomSpacing type="vertical" size="xs" />
        </>
      );
    case "first-human":
      return (
        <>
          <CustomSpacing type="vertical" size="m" />
          <MotiView
            from={{ opacity: 0, translateY: 20 }}
            animate={{ opacity: 1, translateY: 0 }}
            transition={{ type: "timing", duration: 600 }}>
            <UserMessage text={message.content} />
          </MotiView>
          <CustomSpacing type="vertical" size="m" />
        </>
      );
    case "aspect":
      return (
        <>
          <CustomSpacing type="vertical" size="m" />
          <AspectMessage message={message} onPress={handleOpenAspectModal} />
          <CustomSpacing type="vertical" size="m" />

          <AspectModal message={message} visible={aspectModalVisible} onClose={handleCloseAspectModal}>
            <CustomSpacing type="vertical" size="m" />
            <AspectFormContainer message={message} onClose={handleCloseAspectModal} />
          </AspectModal>
        </>
      );
    case "quest":
      return (
        <>
          <CustomSpacing type="vertical" size="m" />
          <QuestMessage type={message.snackType} onPress={handleOpenQuestModal} />
          <CustomSpacing type="vertical" size="m" />

          <QuestModal
            type={message.snackType}
            userResponse={message.parameters.userResponse}
            visible={questModalVisible}
            onClose={handleCloseQuestModal}
          />
        </>
      );
    case "snack":
      return (
        <>
          <CustomSpacing type="vertical" size="m" />
          <LearningMessage
            topic={message.parameters.lastGeneratedTopic}
            label={message.parameters.lastGeneratedLabel}
            onPress={handleLearningPress}
          />
          <CustomSpacing type="vertical" size="m" />
        </>
      );
    default:
      return <></>;
  }
}

export default MessageContainer;
