import { View, StyleSheet } from "react-native";
import CustomText from "../../../common/general/CustomText/CustomText";
import CustomSpacing from "../../../common/layout/CustomSpacing/CustomSpacing";
import useResponsiveScreen from "../../../../hooks/useResponsiveScreen";

function LearnHeader({ title, description }) {
  const { isMedium } = useResponsiveScreen();

  const descComponent =
    description && isMedium ? (
      <>
        <CustomSpacing type="vertical" size="s" />
        <CustomText text={description} size="m" style={{ color: "#667085" }} numberOfLines={6} ellipsizeMode="tail" />
      </>
    ) : null;

  return (
    <View style={[styles.container, isMedium && { width: "100%" }]}>
      <CustomText text={title} weight="ultraBold" textType="display" size={isMedium ? "l" : "xs"} />
      {descComponent}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "left",
    textAlign: "left",
  },
});

export default LearnHeader;
