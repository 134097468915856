import React from "react";
import QuickOptionList from "../../../components/chat/QuickOptionList/QuickOptionList";
import { useChatBar, Context } from "../../../contexts/ChatBarContext";

const QuickOptionListContainer = ({ onQuickOptionPress }) => {
  const { state: chatBarState } = useChatBar();

  let primaryOptions = [];

  switch (chatBarState.activeContext) {
    case Context.GENERAL:
      primaryOptions = [
        { title: "News Briefing", message: "Give me today’s news briefing" },
        { title: "Task Support", message: "Help me with my current tasks" },
        { title: "Learn Something New", message: "Help me learn something new" },
        { title: "Plan my day", message: "Help me setup a plan for my day" },
      ];
      break;
    case Context.TRACK:
    case Context.ASSIGNMENT:
      primaryOptions = [
        { title: "Get Started", message: "Help me get started with this topic" },
        { title: "Hands-On Activity", message: "Let's do a hands-on activity." },
        { title: "Game", message: "Let's play a text-based game to learn this." },
        { title: "Career Alignment", message: "Help me align this my career goals" },
        { title: "Quiz Me", message: "Quiz me this topic" },
      ];
      break;
    case Context.JOURNEY:
      primaryOptions = [
        { title: "Next Steps", message: "What is the next step I should focus on in my journey?" },
        { title: "Task Support", message: "Help me with my outstanding tasks" },
        { title: "Assess Progress", message: "Assess me on my progress on this journey" },
      ];
      break;
    case Context.ONBOARDING:
      return null;
    default:
      primaryOptions = [];
  }

  const handleOptionPress = (option) => {
    onQuickOptionPress(option.message);
  };

  return <QuickOptionList options={primaryOptions} onPress={handleOptionPress} />;
};

export default QuickOptionListContainer;
